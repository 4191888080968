import React from 'react';

// components
import BrightnessAndContrast from './BrightnessAndContrast';

function NinteenSeventy({ id, brightness, contrastSlope, contrastIntercept }) {
  return (
    <defs>
      {/* INSTAGRAM EARLYBIRD FILTER */}
      <radialGradient id="gradient_earlybird">
        <stop offset="20%" stopColor="#d0ba8e" stopOpacity="100%" />
        <stop offset="85%" stopColor="#360309" stopOpacity="100%" />
        <stop offset="100%" stopColor="#1d0210" stopOpacity="100%" />
      </radialGradient>
      {/* Create a rectangle and apply the gradient as its fill */}
      <rect
        id="earlybirdsc"
        x="0"
        y="0"
        width="150%"
        height="150%"
        fill="url(#gradient_earlybird)"
        fillOpacity=".3"
      />
      <filter id={`filter-image-${id}`}>
        {/* contrast */}
        <feComponentTransfer>
          <feFuncR type="linear" slope="0.9" intercept="0.05" />
          <feFuncG type="linear" slope="0.9" intercept="0.05" />
          <feFuncB type="linear" slope="0.9" intercept="0.05" />
        </feComponentTransfer>
        {/* sepia 0.2 */}
        <feColorMatrix
          result="cs"
          type="matrix"
          values="
                0.8786 0.1538 0.0378 0 0
                0.0698 0.9372 0.0336 0 0
                0.0544 0.1068 0.8262 0 0
                0 0 0 1 0"
        />
        <feImage xlinkHref="#earlybirdsc" result="grad" x="0" y="0" />
        <feBlend mode="overlay" in="cs" />
        <BrightnessAndContrast
          brightness={brightness}
          contrastSlope={contrastSlope}
          contrastIntercept={contrastIntercept}
        />
      </filter>
    </defs>
  );
}

export default NinteenSeventy;
