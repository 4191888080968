import React from 'react';
import styled from 'styled-components';

// components
import { Scrollbars } from 'react-custom-scrollbars';

function ColorLists(props) {
  const { colorWrapRef, colors, selectedColor, onClickColor } = props;
  return (
    <ColorWrap ref={colorWrapRef}>
      <Scrollbars
        autoHide={true}
        autoHideTimeout={1000}
        renderView={(props) => (
          <div {...props} style={{ ...props.style, overflowY: 'hidden' }} />
        )}
      >
        <ListItems>
          {colors.map((color) => {
            return (
              <BtnItem
                isActive={color === selectedColor}
                bgColor={color}
                key={color}
                onClick={onClickColor}
                data-color={color}
              />
            );
          })}
        </ListItems>
      </Scrollbars>
    </ColorWrap>
  );
}

const ColorWrap = styled.div`
  position: relative;
  z-index: 1;
  height: 40px;
  margin-right: -30px;
`;
const ListItems = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  margin-top: 5px;
`;
const BtnItem = styled.button`
  position: relative;
  width: 10%;
  min-width: 32px;
  max-width: 75px;
  padding: 0;
  :after {
    content: '';
    display: block;
    padding-top: 100%;
  }
  :before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    width: 25px;
    height: 25px;
    background-image: ${({ bgColor }) =>
      bgColor === '#000000'
        ? "url('./img/icon-check-white.svg')"
        : "url('./img/icon-check.svg')"};
    transform: translate(-50%, -50%);
  }
  background-color: ${({ bgColor }) => bgColor};
`;

export default ColorLists;
