import React from 'react';
import styled from 'styled-components';

// components
import Portal from '../Portal/Portal';

function Confirm({ message, subText, onClickOk, onClose, language }) {
  //language 설정
  let mention1 = '아니요';
  let mention2 = '예';
  if (language === 'en') {
    mention1 = 'No';
    mention2 = 'Yes';
  }
  if (language === 'vi') {
    mention1 = 'Không';
    mention2 = 'Đúng';
  }
  if (language === 'ja') {
    mention1 = '番号';
    mention2 = 'はい';
  }
  if (language === 'ch') {
    mention1 = '不';
    mention2 = '是的';
  }
  if (language === 'ch') {
    mention1 = 'No';
    mention2 = 'sí';
  }

  return (
    <Portal elementId="confirm-root">
      <ConfirmWrap>
        <BackDrop onClick={onClose} />
        <PopupDialog>
          <Message>
            {message.split('\n').map((line, idx) => {
              return (
                <span key={idx}>
                  {line}
                  <br />
                </span>
              );
            })}
          </Message>
          {subText && (
            <SubText>
              {subText.split('\n').map((line, idx) => {
                return (
                  <span key={idx}>
                    {line}
                    <br />
                  </span>
                );
              })}
            </SubText>
          )}
          <BtnGroup>
            <Btn bgcolor={'#6f6f6f'} onClick={onClose}>
              {mention1}
            </Btn>
            <Btn
              bgcolor={'#e89b7f'}
              onClick={() => {
                onClickOk();
                onClose();
              }}
            >
              {mention2}
            </Btn>
          </BtnGroup>
        </PopupDialog>
      </ConfirmWrap>
    </Portal>
  );
}

const ConfirmWrap = styled.div`
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 320px;
  z-index: 20000;
`;

const PopupDialog = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 277px;
  padding: 41px 5px;
  background-color: ${({ theme }) => theme.colors['black']};
  border-radius: 14px;
`;

const Message = styled.p`
  font-size: 15px;
  font-weight: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.colors['white']};
`;

const SubText = styled.p`
  margin-top: 9px;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.colors['white']};
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const Btn = styled.button`
  width: 68px;
  margin: 0 5px;
  padding: 6px 0;
  background-color: ${({ bgcolor }) => bgcolor};
  border-radius: 12px;
  text-align: center;
  font-size: 12px;
  color: ${({ theme }) => theme.colors['white']};
`;

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;

export default Confirm;
