import React from 'react';

// components
import BrightnessAndContrast from './BrightnessAndContrast';

const Yellow = ({ id, brightness, contrastSlope, contrastIntercept }) => {
  return (
    <defs>
      {/* <!-- INSTAGRAM Vesper FILTER --> */}
      <filter
        id={`filter-image-${id}`}
        colorInterpolationFilters="sRGB"
        filterUnits="objectBoundingBox"
        primitiveUnits="objectBoundingBox"
        x="0"
        y="0"
        width="100%"
        height="100%"
      >
        {/* <!--hue-rotate --> */}
        <feColorMatrix type="hueRotate" values="-10" />
        {/* <!-- contrast --> */}
        <feComponentTransfer>
          <feFuncR type="linear" slope="0.9" intercept="0.05" />
          <feFuncG type="linear" slope="0.9" intercept="0.05" />
          <feFuncB type="linear" slope="0.9" intercept="0.05" />
        </feComponentTransfer>
        {/* <!-- saturate --> */}
        <feColorMatrix type="saturate" values=".9" />
        {/* <!-- brightness --> */}
        <feComponentTransfer>
          <feFuncR type="linear" slope="1.2" />
          <feFuncG type="linear" slope="1.2" />
          <feFuncB type="linear" slope="1.2" />
        </feComponentTransfer>
        {/* <!-- sepia 0.1 --> */}
        <feColorMatrix
          result="hcsbs"
          type="matrix"
          values="
                0.9393 0.0769 0.0189 0 0
                0.0349 0.9686 0.0168 0 0
                0.0272 0.0534 0.9131 0 0
                0 0 0 1 0"
        />
        <feFlood floodColor="rgba(220, 250, 40, .1)" floodOpacity="1" />
        <feBlend mode="darken" in2="hcsbs" />
        <BrightnessAndContrast
          brightness={brightness}
          contrastSlope={contrastSlope}
          contrastIntercept={contrastIntercept}
        />
      </filter>
    </defs>
  );
};

export default Yellow;
