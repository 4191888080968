import React from 'react';
import styled from 'styled-components';

function Header(props) {
  const { title, onClickBtnBack, hideBack, onClickBtnCancel } = props;

  return (
    <HeaderWrap hideBack={hideBack}>
      <BackBtnWrap>
        {!hideBack && (
          <BtnBack type="button" onClick={onClickBtnBack}>
            <BtnIcon src="/img/iconGoback.svg" onClick={onClickBtnBack} />
          </BtnBack>
        )}
      </BackBtnWrap>
      <Title>{title}</Title>
      <RightBtnWrap>
        {onClickBtnCancel && (
          <BtnBack type="button" onClick={onClickBtnCancel}>
            <BtnIcon src="/img/btn-close.svg" onClick={onClickBtnCancel} />
          </BtnBack>
        )}
      </RightBtnWrap>
    </HeaderWrap>
  );
}

const HeaderWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 12px;
  background-color: #fff;
  z-index: 15000;
`;
const BackBtnWrap = styled.div`
  width: 25px;
`;
const BtnBack = styled.button`
  height: 100%;
  padding: 0;
`;
const BtnIcon = styled.img``;
const Title = styled.h1`
  font-size: 17px;
`;
const RightBtnWrap = styled.div`
  width: 25px;
`;

export default Header;
