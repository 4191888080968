import React from 'react';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';

const SliderWithTooltip = createSliderWithTooltip(Slider);

function RangeSlider(props) {
  const { value, min, max, onChange } = props;

  return (
    <SliderWithTooltip
      value={value}
      min={min}
      max={max}
      tipFormatter={(value) => value}
      handleStyle={{
        height: 17,
        width: 17,
        borderWidth: 3,
        borderColor: 'white',
        backgroundColor: 'black',
      }}
      railStyle={{ height: 7 }}
      trackStyle={{ backgroundColor: 'white', height: 7 }}
      onChange={onChange}
    />
  );
}

export default RangeSlider;
