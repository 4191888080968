import React from 'react';

// components
import BrightnessAndContrast from './BrightnessAndContrast';

const Whisper = ({ id, brightness, contrastSlope, contrastIntercept }) => {
  return (
    <defs>
      {/* <!-- fegram INSTAGRAM GINGHAM FILTER --> */}
      <filter id={`filter-image-${id}`}>
        {/* <!-- brightness --> */}
        <feComponentTransfer>
          <feFuncR type="linear" slope="1.05" />
          <feFuncG type="linear" slope="1.05" />
          <feFuncB type="linear" slope="1.05" />
        </feComponentTransfer>
        {/* <!-- hue rotate --> */}
        <feColorMatrix type="hueRotate" values="-10" result="bh" />
        {/* <!-- blend mode --> */}
        <feFlood floodColor="#e6e6fa" floodOpacity="1" />
        <feBlend mode="soft-light" in2="bh" />
        <BrightnessAndContrast
          brightness={brightness}
          contrastSlope={contrastSlope}
          contrastIntercept={contrastIntercept}
        />
      </filter>
    </defs>
  );
};

export default Whisper;
