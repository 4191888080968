import React, { useRef } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import findIndex from 'lodash/findIndex';
import get from 'lodash/get';

// components
import RangeSlider from './RangeSlider';
import FilterLists from '../components/FilterLists';

// constants
import { photoFilterData } from '../../../shared/constants/photoFilterData';

const tabItems = [
  { name: 'crop', img: 'edit-crop', text: { ko: '자르기', en: 'Crop', vi: 'Cắt', ja: '切り取り', ch: '剪裁', sp: 'Cortar' } },
  {
    name: 'reversal',
    img: 'edit-reversal',
    text: { ko: '반전', en: 'Reversal', vi: 'Đảo ngược', ja: '反転', ch: '退回', sp: 'Inversión' },
  },
  { name: 'filter', img: 'edit-filter', text: { ko: '필터', en: 'Filter', vi: 'Bộ lọc', ja: 'フィルター', ch: '过滤', sp: 'Filtrar' } },
  {
    name: 'brightness',
    img: 'edit-brightness',
    text: { ko: '밝기', en: 'Brightness', vi: 'Độ sáng', ja: '明るさ', ch: '亮度', sp: 'Brillo' },
  },
  {
    name: 'contrast',
    img: 'edit-contrast',
    text: { ko: '대조', en: 'Contrast', vi: 'Tương phản', ja: 'コントラスト', ch: '对比', sp: 'Contraste' },
  },
];

function PhotoEditTabBar(props) {
  const {
    photoEditMode,
    photoEditTab,
    setPhotoEditTab,
    setIsEditOpen,
    photoState,
    changeReversal,
    changeFilter,
    changeBrightness,
    changeContrast,
    addHistoryStateHandler,
    setPhotoInfo,
    photoEditCancel,
    photoEditCancelHandler,
  } = props;
  const location = useLocation();
  const { language } = location.state;

  const {
    id,
    src,
    width,
    height,
    transformInfo,
    reversals,
    filter,
    brightness,
    contrast,
    cropSrc,
  } = photoState;

  const prefix = 'photo';
  const prevPhotoData = useRef();
  const keys = {
    crop: 'cropSrc',
    reversal: 'reversals',
    filter: 'filter',
    brightness: 'brightness',
    contrast: 'contrast',
  };

  const onClickBtnTabBar = (evt) => {
    const currentTabName = evt.currentTarget.dataset.name;

    prevPhotoData.current = photoState[keys[currentTabName]];

    setPhotoEditTab(currentTabName);
  };
  const onClickBtnBack = () => {
    photoEditCancelHandler();
  };
  const onClickModifyCancel = () => {
    setPhotoEditTab('default');
    setIsEditOpen(false);

    // 현재 활성화 된 탭 photo state초기화
    photoEditCancel(keys[photoEditTab], prevPhotoData.current);
  };
  const onClickModifyComplete = (evt) => {
    let srcInfo = src;
    let widthInfo = width;
    let heightInfo = height;

    // crop된 사진 데이터로 변경
    if (evt.currentTarget.dataset.type === 'crop') {
      srcInfo = cropSrc.src;
      widthInfo = cropSrc.width;
      heightInfo = cropSrc.height;
      setPhotoInfo({
        id,
        src: srcInfo,
        width: widthInfo,
        height: heightInfo,
        transformInfo,
        reversals,
        filter,
        brightness,
        contrast,
      });
    }

    addHistoryStateHandler({
      id,
      type: prefix,
      elInfo: {
        src: srcInfo,
        width: widthInfo,
        height: heightInfo,
        reversals,
        filter,
        brightness,
        contrast,
      },
      transformInfo,
      keepRatio: true,
      isEditable: true,
      canModify: true,
    });

    // 편집 완료
    setPhotoEditTab('default');
  };

  const onClickReversalBtn = (evt) => {
    changeReversal(evt.currentTarget.dataset.type);
  };

  const onChangeFilter = (evt) => {
    changeFilter(evt.currentTarget.dataset.filter);
  };

  const onChangeBrightness = (value) => {
    changeBrightness(value);
  };

  const onChangeContrast = (value) => {
    changeContrast(value);
  };

  // 페이지 정보 (번역정보)
  const pageInfo = {
    btns: {
      btnApply: {
        text: { ko: '완료', en: 'Apply' },
      },
      btnCancel: {
        text: { ko: '취소', en: 'Cancel' },
      },
      btnVertical: {
        text: { ko: '좌우반전', en: 'Vertical' },
      },
      btnHorizontal: {
        text: { ko: '수평반전', en: 'Horizontal' },
      },
    },
  };

  return (
    <PhotoEditWrap photoEditMode={photoEditMode}>
      {photoEditTab !== 'default' && (
        <PhotoEditHeader>
          <BtnHeaderText onClick={onClickModifyCancel}>
            {pageInfo.btns.btnCancel.text[language]}
          </BtnHeaderText>
          <PhotoEditHeaderTitle>
            {
              tabItems[findIndex(tabItems, ['name', photoEditTab])].text[
                language
              ]
            }
          </PhotoEditHeaderTitle>
          <BtnHeaderText
            onClick={onClickModifyComplete}
            data-type={photoEditTab}
          >
            {pageInfo.btns.btnApply.text[language]}
          </BtnHeaderText>
        </PhotoEditHeader>
      )}
      <PhotoEditControlWrap>
        {photoEditTab === 'reversal' && (
          <ReversalControlWrap>
            <Btn mr={47} onClick={onClickReversalBtn} data-type={'vertical'}>
              <BtnIcon
                src={
                  get(reversals, 'vertical')
                    ? `./img/edit-reversal_lg_active.svg`
                    : `./img/edit-reversal_lg.svg`
                }
                alt=""
              />
              <BtnText size={13} mt={8} active={reversals.vertical}>
                {pageInfo.btns.btnVertical.text[language]}
              </BtnText>
            </Btn>
            <Btn ml={47} onClick={onClickReversalBtn} data-type={'horizontal'}>
              <BtnIcon
                src={
                  get(reversals, 'horizontal')
                    ? `./img/edit-reversalh_lg_active.svg`
                    : `./img/edit-reversalh_lg.svg`
                }
                alt=""
              />
              <BtnText size={13} mt={8} active={reversals.horizontal}>
                {pageInfo.btns.btnHorizontal.text[language]}
              </BtnText>
            </Btn>
          </ReversalControlWrap>
        )}
        {photoEditTab === 'filter' && (
          <FilterControlWrap>
            <FilterLists
              filters={photoFilterData}
              src={src}
              selectedFilter={filter}
              onClickFilter={onChangeFilter}
            />
          </FilterControlWrap>
        )}
        {photoEditTab === 'brightness' && (
          <BrightnessControlWrap>
            <RangeSlider
              value={brightness}
              min={-50}
              max={50}
              onChange={onChangeBrightness}
            />
          </BrightnessControlWrap>
        )}
        {photoEditTab === 'contrast' && (
          <ContrastControlWrap>
            <RangeSlider
              value={contrast}
              min={-50}
              max={50}
              onChange={onChangeContrast}
            />
          </ContrastControlWrap>
        )}
      </PhotoEditControlWrap>
      <PhotoEditTabBarWrap>
        <BtnBack onClick={onClickBtnBack}>
          <BtnIcon src={`./img/very-top.svg`} alt="" />
        </BtnBack>
        <BtnTabWrap>
          {tabItems.map((tabItem) => {
            const { name, img, text } = tabItem;
            const imgUrl =
              photoEditTab === name
                ? `./img/${img}_active.svg`
                : `./img/${img}.svg`;

            return (
              <Btn
                onClick={onClickBtnTabBar}
                key={name}
                data-name={name}
                language={props.language}
              >
                <BtnIcon src={imgUrl} alt="" />
                <BtnText active={photoEditTab === name} size={8} mt={4}>
                  {text[language]}
                </BtnText>
              </Btn>
            );
          })}
        </BtnTabWrap>
      </PhotoEditTabBarWrap>
    </PhotoEditWrap>
  );
}

const PhotoEditWrap = styled.div`
  position: absolute;
  bottom: calc(0px - constant(safe-area-inset-top));
  bottom: calc(0px - env(safe-area-inset-top));
  left: 0;
  width: 100%;
  z-index: 20000;
  transition: transform 0.3s;
  transform: translateY(
    ${({ photoEditMode }) => (photoEditMode ? 0 : '100px')}
  );
`;

const PhotoEditTabBarWrap = styled.div`
  display: flex;
  height: 65px;
  width: 100%;
  padding: 0;
  background: ${({ theme }) => theme.colors['black']};
`;
const PhotoEditHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  background: ${({ theme }) => theme.colors['black']};
  padding: 16px;
  font-size: 13px;
`;
const PhotoEditHeaderTitle = styled.h3`
  color: ${({ theme }) => theme.colors['white']};
`;
const BtnHeaderText = styled.button`
  color: ${({ theme }) => theme.colors['pale-salmon']};
`;
const PhotoEditControlWrap = styled.div`
  background: ${({ theme }) => theme.colors['black']};
`;
const ReversalControlWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 15px 0;
`;
const FilterControlWrap = styled.div`
  background: ${({ theme }) => theme.colors['black']};
  padding: 15px 0 15px 25px;
`;
const BrightnessControlWrap = styled.div`
  background: ${({ theme }) => theme.colors['black']};
  padding: 35px 15%;
`;
const ContrastControlWrap = styled.div`
  background: ${({ theme }) => theme.colors['black']};
  padding: 35px 15%;
`;

const BtnBack = styled.button`
  width: 45px;
  height: 100%;
  background: ${({ theme }) => theme.colors['pinkish-tan']};
  height: 100%;
  flex-direction: column;
`;
const BtnTabWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
`;
const Btn = styled.button`
  height: 100%;
  flex-direction: column;
  margin-left: ${({ ml }) => `${ml}px`};
  margin-right: ${({ mr }) => `${mr}px`};
`;
const BtnIcon = styled.img``;
const BtnText = styled.p`
  margin-top: ${({ mt }) => `${mt}px`};
  font-size: ${({ size }) => `${size}px`};
  color: ${({ theme, active }) =>
    active ? theme.colors['pale-salmon'] : theme.colors['white']};
`;

export default PhotoEditTabBar;
