import React, { useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { isIOS } from 'react-device-detect';

// components
import PageLayout from '../../shared/components/PageLayout/PageLayout';
import Header from '../../shared/components/Header/Header';
import LoadingOverlay from '../Edit/components/LoadingOverlay';

// hooks
import { createFileName } from '../../shared/hooks/useScreenshot_old';

// utills
import wait from '../../shared/utills/wait';
import downloadImg from '../../shared/utills/downloadImg';

function CollectionDetail(props) {
  const history = useHistory();
  const location = useLocation();
  const { language, collection } = location.state;
  const { name, pageData: collectionData } = collection;

  // 뒤로가기
  const onClickBtnBack = () => {
    history.push({
      pathname: '/collection',
      state: {
        language,
      },
    });
  };

  // loading
  const [isLoading, setIsLoading] = useState(false);

  // 이미지 다운로드 handler
  const downloadImgHandler = () => {
    setIsLoading(true);
    collectionData.forEach((collection, idx) => {
      const { img } = collection;

      downloadImg(
        img,
        createFileName,
        {
          name: `${moment().format('YYYY-DD-MM HH-mm-ss')}_${idx}_image`,
          extension: 'png',
        },
        isIOS,
      );
    });
    wait(3000).then(() => {
      setIsLoading(false);
    });
  };

  // 페이지 정보
  const pageInfo = {
    btns: [
      {
        btnText: {
          ko: '다시 편집하기',
          en: 'Edit Again',
          vi: 'Chỉnh sửa lại',
          ja: '戻って編集する',
          ch: '重新编辑',
          sp: 'Editar de nuevo'
        },
        btnColor: 'brownish-grey',
        btnAction: useCallback(() => {
          history.push({
            pathname: '/edit',
            state: {
              language,
              collectionEditInfo: { ...collection },
            },
          });
        }, [collection, history, language]),
      },
      {
        btnText: {
          ko: '앨범에 저장하기',
          en: 'Save to Album',
          vi: 'Lưu vào album',
          ja: 'アルバムに保存する',
          ch: '保存到专辑',
          sp: 'Guardar en álbum'
        },
        btnColor: 'pinkish-tan',
        btnAction: downloadImgHandler,
      },
    ],
  };

  return (
    <PageLayout iosFullScroll={true}>
      <CollectionWrap>
        <Header title={name} onClickBtnBack={onClickBtnBack} />
        {isLoading && <LoadingOverlay />}
        <CollectionListWrap>
          <CollectionLists>
            {collectionData.map((collection, idx) => {
              const { img } = collection;

              return (
                <CollectionList key={idx}>
                  <CollectionImg src={img} />
                </CollectionList>
              );
            })}
          </CollectionLists>
        </CollectionListWrap>
        <BtnGroup>
          {pageInfo.btns.map((btn, idx) => {
            const { btnText, btnColor, btnAction } = btn;

            return (
              <Btn bgcolor={btnColor} onClick={btnAction} key={idx}>
                {btnText[language]}
              </Btn>
            );
          })}
        </BtnGroup>
      </CollectionWrap>
    </PageLayout>
  );
}

const CollectionWrap = styled.div`
  height: 100%;
  background-color: #f2f2f2;
`;
const CollectionListWrap = styled.div`
  padding: 40px 30px 100px;
  background-color: #f2f2f2;
`;
const CollectionLists = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7.5px;
`;
const CollectionList = styled.li`
  display: flex;
  align-items: center;
  width: 33.33%;
  padding: 7.5px;
`;
const CollectionImg = styled.img`
  width: 100%;
  border-radius: 15px;
`;
const BtnGroup = styled.div`
  position: fixed;
  bottom: 27px;
  left: 50%;
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  transform: translateX(-50%);
`;
const Btn = styled.button`
  max-width: 160px;
  width: 50%;
  height: 51px;
  padding: 17px 0;
  margin: 0 5px;
  border-radius: 26px;
  box-shadow: 0 3px 6px 0 rgba(100, 100, 100, 0.15);
  background-color: ${({ theme, bgcolor }) => theme.colors[bgcolor]};
  font-size: 15px;
  color: ${({ theme }) => theme.colors['white']};
`;
export default CollectionDetail;
