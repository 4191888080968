import React from 'react';
import { Link } from 'react-router-dom';
import styles from './IntroKo.module.css';

import PageLayout from '../../shared/components/PageLayout/PageLayout';

function IntroSp() {
  return (
    <PageLayout>
      <div className={styles.mainWrap}>
        <div className={styles.logo}>
          <img src="./img/wecume1.png" alt="" />
          <div className={styles.title}>Create Your Own Album</div>
        </div>
        <div className={styles.body}>
          <img src="./img/phone.png" alt="" />
        </div>
        <div className={styles.footer}>
          <Link to={{ pathname: '/edit', state: { language: 'sp' } }}>
            <div className={styles.line1}>Editar</div>
          </Link>
          <Link to={{ pathname: '/list', state: { language: 'sp' } }}>
            <div className={styles.line2}>Un ejemplo de trabajo</div>
          </Link>
          <Link to={{ pathname: '/collection', state: { language: 'sp' } }}>
            <div className={styles.line3}>Mi colección de arte</div>
          </Link>
        </div>
      </div>
    </PageLayout>
  );
}

export default IntroSp;