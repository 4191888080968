import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Signup.module.css';
import {useForm} from "react-hook-form";
import Modal from '../../shared/components/Alert/Modal';
import axios from 'axios';



function Signup() {

  const [modal, setModals] = useState(false);
  const [comments, setComments] = useState('');
  const [number, setNumbers] = useState();


  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {

    if(data.password === data.passwordRepeat) {
      axios.post(process.env.REACT_APP_BACK_URL + '/signup', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(res=> {
        console.log(res);
        if(res.data == "ok") {
          setComments('회원가입이 완료되었습니다.');
          setNumbers(1);
          setModals(true);
        }
        else {
          setComments('이미 존재하는 아이디입니다.');
          setNumbers(2);
          setModals(true);
        }
      })
      .catch(error=> {
        console.log(error);
      });

    }
    else {
      setComments('두 비밀번호 값이 다릅니다.');
      setNumbers(2);
      setModals(true);
    }
    

  }

  function closeAndRedirect() {
    setModals(false);
    window.location.href = '/';
  }
  function closeModal() {
    setModals(false);
  }




  return (
      <div>
          <div className={styles.header}>
            {/* <img src={require('../../asset/back.png').default} alt="" /> */}
            <img src="./img/back.png" />
            <div className={styles.title}>이메일로 가입</div>
          </div>
          <div className={styles.boxWrap}>
            <div className={styles.letterBox}>
              <div className={styles.comment1}>이메일 회원가입</div>
              <div className={styles.comment2}>직접 입력하는 이메일로 회원가입이 진행됩니다</div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input className={styles.detail} type="text" placeholder="이메일을 입력하세요" {...register('email', {
                  required: "이메일을 입력하세요",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "이메일 형식이 아닙니다"
                  }
                })}/>
                <input className={styles.detail} type="password" placeholder="비밀번호를 입력하세요(7자 이상)" {...register('password', { required: "비밀번호를 입력하세요", minLength: {value: 7, message: "7자 이상이어야 합니다"}})} />               
                <input className={styles.detail} type="password" placeholder="비밀번호를 재입력하세요" {...register('passwordRepeat', {})} />
                <button className={styles.btn} type="submit">회원가입하기</button>
                {/* <input className={styles.btn} type="submit" /> */}
                {errors.password && <p className={styles.alarm}>{errors.password.message}</p>}
                {errors.email && <p className={styles.alarm}>{errors.email.message}</p>}
            </form>
          </div>
          {modal && (
            <Modal justClose={closeModal} onClose={closeAndRedirect} comment={comments} number={number} />
          )}{" "}
          {/* <Modal justClose={closeModal} onClose={closeAndRedirect} comment={comments} number={number} /> */}
      </div>
  );
}


export default Signup;


