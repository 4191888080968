import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

// 스티커 데이터
const stickerItems = [];
const stickerCnt = 32;

for (let i = 1; i <= stickerCnt; i++) {
  stickerItems.push({ src: `./img/sticker/sticker${i}.svg` });
}

const StickerArea = (props) => {
  const { addHistoryStateHandler, elIdx } = props;
  const prefix = 'sticker';

  const addStickerEl = (evt) => {
    const imgEl =
      evt.target.tagName === 'IMG'
        ? evt.target
        : evt.target.querySelector('img');

    // 이미지 요소 추가
    addHistoryStateHandler({
      id: `${moment().format('YYYY-DD-MM-HH-mm-ss')}_${elIdx.current}`,
      type: prefix,
      elInfo: {
        src: './img' + imgEl.src.split('/img')[1],
        width: imgEl.width,
        height: imgEl.height,
      },
      transformInfo: {
        translate: [0, 0],
        rotate: 0,
        scale: [1, 1],
      },
      keepRatio: true,
      isEditable: true,
      canModify: false,
    });

    // index 중가
    elIdx.current += 1;
  };

  return (
    <StickerAreaWrap>
      <StickerLists>
        {stickerItems.map((item, idx) => {
          const { src } = item;

          return (
            <StickerItem key={idx} onClick={addStickerEl} data-src={src}>
              <ImgSticker src={src} alt="" />
            </StickerItem>
          );
        })}
      </StickerLists>
    </StickerAreaWrap>
  );
};

const StickerAreaWrap = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
const StickerLists = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1px;
`;
const StickerItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  @media screen and (min-width: 544px) {
    width: 20%;
  }
  @media screen and (min-width: 768px) {
    width: 16.66%;
  }
  @media screen and (min-width: 1200px) {
    width: 10%;
  }
  border: 1px solid ${({ theme }) => theme.colors['very-light-pink2']};
  :after {
    content: '';
    padding-top: 100%;
  }
`;
const ImgSticker = styled.img`
  max-width: 100%;
`;

export default StickerArea;
