import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './ExampleDetail.module.css';

import axios from 'axios';
import { URL } from '../../shared/constants/apiInfo';

// components
import PageLayout from '../../shared/components/PageLayout/PageLayout';
import LoadingOverlay from '../Edit/components/LoadingOverlay';

function ExampleDetail(props) {
  const history = useHistory();
  const location = useLocation();
  const { number, img, language } = location.state;
  const [template, setTemplate] = useState();
  const [isLoading, setIsLoading] = useState(false);

  let button = '';

  if (language === 'ko') {
    button = '사용하기';
  } else if (language === 'en') {
    button = 'Use';
  } else if (language === 'vi') {
    button = 'Sử dụng';
  } else if (language === 'ja') {
    button = '使用する';
  } else if (language === 'ch') {
    button = '使用';
  } else if (language === 'sp') {
    button = 'Usar';
  }

  const fetchData = useCallback((num) => {
    const url = `${URL}/sendFile2`;

    setIsLoading(true);
    axios
      .get(url, { params: { num } })
      .then(function (response) {
        setTemplate(JSON.parse(response.data[0].pageData));
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetchData(number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickUseTemplate = () => {
    history.push({
      pathname: '/edit',
      state: {
        language,
        exTemplate: { ...template },
      },
    });
  };

  return (
    <PageLayout iosFullScroll={true}>
      {isLoading && <LoadingOverlay />}

      <div>
        <div className={styles.title}>
          <img
            src="./img/back.png"
            alt=""
            className={styles.back}
            onClick={() => history.goBack()}
          />
          <span className={styles.btn} onClick={onClickUseTemplate}>
            {button}
          </span>
        </div>
        <img src={img} width="100%" alt="" />
      </div>
    </PageLayout>
  );
}

export default ExampleDetail;
