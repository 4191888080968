import React from 'react';

function BrightnessAndContrast({
  brightness,
  contrastSlope,
  contrastIntercept,
}) {
  return (
    <>
      {/* contrast */}
      <feComponentTransfer>
        <feFuncR
          type="linear"
          slope={contrastSlope}
          intercept={contrastIntercept}
        />
        <feFuncG
          type="linear"
          slope={contrastSlope}
          intercept={contrastIntercept}
        />
        <feFuncB
          type="linear"
          slope={contrastSlope}
          intercept={contrastIntercept}
        />
      </feComponentTransfer>
      {/* brightness */}
      <feComponentTransfer result="cb">
        <feFuncR type="linear" slope={1 + brightness * 0.01} />
        <feFuncG type="linear" slope={1 + brightness * 0.01} />
        <feFuncB type="linear" slope={1 + brightness * 0.01} />
      </feComponentTransfer>
    </>
  );
}

export default BrightnessAndContrast;
