import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import axios from 'axios';
import { URL } from '../../../shared/constants/apiInfo';

// components
import { MetroSpinner } from 'react-spinners-kit';

// utills
import caculatStackData from '../../../shared/utills/caculatStackData';

// constants
import { categoryData } from '../../../shared/constants/templateCategory';

function TempleteSelectArea(props) {
  const {
    setTempelte,
    setIsTempleteSelectOpen,
    drawMoveableObject,
    pageData,
    setSelectedTempleteData,
    bgEditState,
    language,
    setIsLoading,
    changeCapureInner,
  } = props;

  // 데이터 로딩
  const [isListLoading, setIsListLoading] = useState(false);

  // 템플릿 데이터
  const [templeteData, setTempleteData] = useState([]);

  const fetchList = useCallback(
    (category) => {
      const url = `${URL}/sendFile`;
      setIsListLoading(true);

      axios
        .get(url, { params: { category, lang: language } })
        .then(function (response) {
          let data = [];

          response.data.forEach(({ number, imgData }) => {
            data.push({ number, imgData });
          });
          setTempleteData(data);
          setIsListLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    [language],
  );

  useEffect(() => {
    fetchList(categoryData[0].name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 활성화 탭
  const [currentCateTab, setCurrentCateTab] = useState(categoryData[0].name);
  const onClickBtnTab = (evt) => {
    setCurrentCateTab(evt.currentTarget.dataset.name);
    fetchList(evt.currentTarget.dataset.name);
  };

  // 템플릿 선택
  const oldPageData = useRef();

  const onClickTemplete = (evt) => {
    const num = evt.currentTarget.dataset.num;
    const url = `${URL}/sendFile2`;
    setIsLoading(true);

    axios
      .get(url, { params: { num } })
      .then(function (response) {
        const { stackData, canvasSize, captureSize } = JSON.parse(
          response.data[0].pageData,
        );

        const { caculatedStackData } = caculatStackData(
          stackData.present,
          canvasSize,
          captureSize,
          bgEditState,
          changeCapureInner,
        );

        drawMoveableObject(caculatedStackData);
        setSelectedTempleteData(caculatedStackData);

        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // 템플릿 창 닫기
  const closeTempleteSelectArea = () => {
    setIsTempleteSelectOpen(false);
    drawMoveableObject(oldPageData.current);
  };

  // 템플릿 선택 완료
  const compeleTempleteSelectArea = () => {
    setIsTempleteSelectOpen(false);
    setTempelte();
  };

  useEffect(() => {
    oldPageData.current = pageData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 페이지 정보 (번역정보)
  const pageInfo = {
    btns: {
      btnApply: {
        text: { ko: '완료', en: 'Apply' },
      },
      btnCancel: {
        text: { ko: '취소', en: 'Cancel' },
      },
    },
  };

  return (
    <TempleteSelectAreaWrap>
      <TempleteHeader>
        <BtnHeaderText onClick={closeTempleteSelectArea}>
          {pageInfo.btns.btnCancel.text[language]}
        </BtnHeaderText>
        <BtnHeaderText onClick={compeleTempleteSelectArea}>
          {pageInfo.btns.btnApply.text[language]}
        </BtnHeaderText>
      </TempleteHeader>
      {isListLoading && (
        <SpinnerWrap>
          <MetroSpinner color="#ddd" size={20} />
        </SpinnerWrap>
      )}

      {!isListLoading && (
        <TempleteListWrap>
          {isEmpty(templeteData) ? (
            <NoneList>템플릿이 없습니다.</NoneList>
          ) : (
            templeteData.map((template, idx) => {
              const { number, imgData } = template;

              return (
                <TempleteList
                  key={idx}
                  onClick={onClickTemplete}
                  data-num={number}
                >
                  <TempleteImg src={imgData} />
                </TempleteList>
              );
            })
          )}
        </TempleteListWrap>
      )}
      <CategoryTabs>
        {categoryData.map((category, idx) => {
          const { name, text } = category;

          return (
            <CategoryTab key={name}>
              <BtnTab
                data-name={name}
                active={currentCateTab === name}
                onClick={onClickBtnTab}
              >
                {text[language]}
              </BtnTab>
            </CategoryTab>
          );
        })}
      </CategoryTabs>
    </TempleteSelectAreaWrap>
  );
}

const TempleteSelectAreaWrap = styled.div`
  position: absolute;
  bottom: 65px;
  left: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors['black']};
`;
const TempleteHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 20px;
`;
const BtnHeaderText = styled.button`
  font-size: 15px;
  color: ${({ theme }) => theme.colors['white']};
`;
const TempleteListWrap = styled.ul`
  display: flex;
  overflow-x: auto;
  padding: 5px 15px 30px;
`;
const SpinnerWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 15px;
`;
const NoneList = styled.p`
  width: 100%;
  padding: 25px 0;
  font-size: 13px;
  color: ${({ theme }) => theme.colors['white']};
  text-align: center;
`;
const TempleteList = styled.li`
  flex: 0 0 auto;
  width: 30%;
  @media (min-width: 544px) {
    width: 23%;
  }
  @media (min-width: 768px) {
    width: 17%;
  }
  @media (min-width: 1200px) {
    width: 11%;
  }
  padding-right: 10px;
  cursor: pointer;
`;
const TempleteImg = styled.img`
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
`;
const CategoryTabs = styled.ul`
  display: flex;
  height: 38px;
  background-color: ${({ theme }) => theme.colors['pinkish-tan']};
`;
const CategoryTab = styled.li`
  flex: 1 1 0;
`;
const BtnTab = styled.button`
  width: 100%;
  height: 100%;
  font-size: 13px;
  color: ${({ theme, active }) =>
    active ? theme.colors['black'] : theme.colors['white']};
`;

export default TempleteSelectArea;
