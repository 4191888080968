import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

// components
import { Scrollbars } from 'react-custom-scrollbars';
import ImageFilter from './ImgFilter/ImageFilter';

function FilterLists(props) {
  const { filters, src, selectedFilter, onClickFilter } = props;
  const location = useLocation();
  const { language } = location.state;

  return (
    <FilterWrap>
      <Scrollbars
        autoHide={true}
        autoHideTimeout={1000}
        renderView={(props) => (
          <div {...props} style={{ ...props.style, overflowY: 'hidden' }} />
        )}
        renderThumbHorizontal={(props) => (
          <div
            {...props}
            style={{
              ...props.style,
              backgroundColor: 'rgba(255, 255, 255, .2)',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          />
        )}
      >
        <ListItems>
          {filters.map((filterData) => {
            const { name, title } = filterData;

            return (
              <BtnItem key={name} onClick={onClickFilter} data-filter={name}>
                <ImgWrap isActive={name === selectedFilter}>
                  <ImgInnerWrap>
                    <ImageFilter
                      image={src}
                      filter={filterData.name}
                      preserveAspectRatio={'cover'}
                    />
                  </ImgInnerWrap>
                </ImgWrap>

                <BtnText>{title[language]}</BtnText>
              </BtnItem>
            );
          })}
        </ListItems>
      </Scrollbars>
    </FilterWrap>
  );
}

const FilterWrap = styled.div`
  position: relative;
  z-index: 1;
  height: 80px;
`;
const ListItems = styled.div`
  display: inline-flex;
  align-items: flex-start;
  cursor: pointer;
  margin-right: 17px;
`;
const BtnItem = styled.button`
  display: block;
  width: 46px;
  padding: 0;
  margin-right: 17px;
`;
const ImgWrap = styled.div`
  position: relative;
  height: 46px;
  border: 3px solid #fff;
  :before {
    content: '';
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    border: 3px solid #dd9e84;
    z-index: 10;
  }
`;
const ImgInnerWrap = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  filter: ${({ filter }) => filter};
  :after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    mix-blend-mode: ${({ blendMode }) => blendMode};
    background: ${({ background }) => background};
    opacity: ${({ opacity }) => opacity};
  }
`;
const BtnText = styled.p`
  margin-top: 4px;
  line-height: 1;
  font-size: 12px;
  word-break: keep-all;
  text-align: center;
  color: ${({ theme }) => theme.colors['white']};
`;

export default FilterLists;
