import React from 'react';
import { Link } from 'react-router-dom';
import styles from './IntroKo.module.css';

import PageLayout from '../../shared/components/PageLayout/PageLayout';

function IntroCh() {
  return (
    <PageLayout>
      <div className={styles.mainWrap}>
        <div className={styles.logo}>
          <img src="./img/wecume1.png" alt="" />
          <div className={styles.title}>Create Your Own Album</div>
        </div>
        <div className={styles.body}>
          <img src="./img/phone.png" alt="" />
        </div>
        <div className={styles.footer}>
          <Link to={{ pathname: '/edit', state: { language: 'ch' } }}>
            <div className={styles.line1}>编辑</div>
          </Link>
          <Link to={{ pathname: '/list', state: { language: 'ch' } }}>
            <div className={styles.line2}>示范作品</div>
          </Link>
          <Link to={{ pathname: '/collection', state: { language: 'ch' } }}>
            <div className={styles.line3}>我的作品收藏</div>
          </Link>
        </div>
      </div>
    </PageLayout>
  );
}

export default IntroCh;
