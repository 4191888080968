import React from 'react';

// components
import BrightnessAndContrast from './BrightnessAndContrast';

const Windy = ({ id, brightness, contrastSlope, contrastIntercept }) => {
  return (
    <defs>
      {/* <!-- INSTAGRAM BROOKLYN FILTER --> */}
      <radialGradient id="gradient_brooklyn">
        <stop offset="70%" stopColor="#A8DFC1" stopOpacity="40%" />
        <stop offset="100%" stopColor="#B7C4C8" stopOpacity="100%" />
      </radialGradient>
      {/* <!-- screen on filtered image --> */}
      <rect
        id="brooksc"
        x="0"
        y="0"
        width="100%"
        height="100%"
        fillOpacity=".3"
        fill="url(#gradient_brooklyn)"
      />
      <filter
        id={`filter-image-${id}`}
        colorInterpolationFilters="sRGB"
        filterUnits="objectBoundingBox"
        primitiveUnits="objectBoundingBox"
        x="0"
        y="0"
        width="100%"
        height="100%"
      >
        {/* filter contrast */}
        <feComponentTransfer>
          <feFuncR type="linear" slope="0.9" intercept="0.05" />
          <feFuncG type="linear" slope="0.9" intercept="0.05" />
          <feFuncB type="linear" slope="0.9" intercept="0.05" />
        </feComponentTransfer>
        {/* filter brightness */}
        <feComponentTransfer result="cb">
          <feFuncR type="linear" slope="1.1" />
          <feFuncG type="linear" slope="1.1" />
          <feFuncB type="linear" slope="1.1" />
        </feComponentTransfer>
        {/* contrast */}
        <feComponentTransfer>
          <feFuncR type="linear" slope="1" intercept="0.05" />
          <feFuncG type="linear" slope="1" intercept="0.05" />
          <feFuncB type="linear" slope="1" intercept="0.05" />
        </feComponentTransfer>
        {/* <!-- brightness --> */}
        <feComponentTransfer result="cb">
          <feFuncR type="linear" slope="1" />
          <feFuncG type="linear" slope="1" />
          <feFuncB type="linear" slope="1" />
        </feComponentTransfer>
        <feImage xlinkHref="#brooksc" result="grad" x="0" y="0" />
        <feBlend mode="overlay" in="cb" />
        <BrightnessAndContrast
          brightness={brightness}
          contrastSlope={contrastSlope}
          contrastIntercept={contrastIntercept}
        />
      </filter>
    </defs>
  );
};

export default Windy;
