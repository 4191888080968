import React from 'react';
import styled from 'styled-components';

// components
import PhotoArea from './PhotoArea';
import WriteArea from './WriteArea';
import BackgroundArea from './BackgroundArea';
import StickerArea from './StickerArea';
import PageArea from './PageArea';

function BottomControlArea(props) {
  const {
    currentTab,
    setCurrentTabDefault,
    addHistoryStateHandler,
    elIdx,
    initState,
    textState,
    changeAlign,
    changeTextColor,
    changeFontFamily,
    changeFontSize,
    setInitFontInfo,
    bgEditState,
    setBgColor,
    setBgRatio,
    setBgPhoto,
    setBgEnd,
    setBgCancel,
    completeBgChangeHandler,
    pageState,
    chagePage,
    addPage,
    removePageData,
  } = props;

  const isControlAreaHide = currentTab === 'write';

  const onClickBtnToggle = () => {
    if (currentTab === 'background') {
      completeBgChangeHandler();
    }
    setCurrentTabDefault();
  };

  return (
    <BottomControlAreaWrap>
      <BtnToggle onClick={onClickBtnToggle} isHide={isControlAreaHide}>
        <BtnIcon src="./img/icon-down.svg" />
      </BtnToggle>
      <ControlAreaWrap isHide={isControlAreaHide}>
        {currentTab === 'photo' && (
          <PhotoArea
            setCurrentTabDefault={setCurrentTabDefault}
            addHistoryStateHandler={addHistoryStateHandler}
            elIdx={elIdx}
            language={props.language}
          />
        )}
        {currentTab === 'background' && (
          <BackgroundArea
            setCurrentTabDefault={setCurrentTabDefault}
            addHistoryStateHandler={addHistoryStateHandler}
            elIdx={elIdx}
            initState={initState}
            bgEditState={bgEditState}
            setBgColor={setBgColor}
            setBgRatio={setBgRatio}
            setBgPhoto={setBgPhoto}
            setBgEnd={setBgEnd}
            setBgCancel={setBgCancel}
            language={props.language}
          />
        )}
        {currentTab === 'sticker' && (
          <StickerArea
            addHistoryStateHandler={addHistoryStateHandler}
            elIdx={elIdx}
          />
        )}
        {currentTab === 'page' && (
          <PageArea
            pageState={pageState}
            chagePage={chagePage}
            addPage={addPage}
            removePageData={removePageData}
          />
        )}
      </ControlAreaWrap>
      {currentTab === 'write' && (
        <WriteArea
          setCurrentTabDefault={setCurrentTabDefault}
          addHistoryStateHandler={addHistoryStateHandler}
          elIdx={elIdx}
          textState={textState}
          changeAlign={changeAlign}
          changeTextColor={changeTextColor}
          changeFontFamily={changeFontFamily}
          changeFontSize={changeFontSize}
          setInitFontInfo={setInitFontInfo}
          language={props.language}
        />
      )}
    </BottomControlAreaWrap>
  );
}

const BottomControlAreaWrap = styled.div`
  position: relative;
  z-index: 15000;
`;
const BtnToggle = styled.button`
  display: ${({ isHide }) => (isHide ? 'none' : 'block')};
  width: 100%;
  height: 25px;
  background-color: ${({ theme }) => theme.colors['very-light-pink']};
  vertical-align: top;
`;
const ControlAreaWrap = styled.div`
  display: ${({ isHide }) => (isHide ? 'none' : 'block')};
  height: 245px;
  background: ${({ theme }) => theme.colors['black']};
  overflow: auto;
`;
const BtnIcon = styled.img``;

export default BottomControlArea;
