import React from 'react';
import styled from 'styled-components';

function EditIntro(props) {
  //language 설정
  let mention1 = '사진추가를 눌러';
  let mention2 = '자유롭게 편집을 시작해보세요';

  if (props.language === 'en') {
    mention1 = 'The Easiest Photo Editor';
    mention2 = 'Use the options at the bottom';
  }

  return (
    <IntroWrap>
      <HorizontalBar />
      <VerticalBar />
      <ImgRibon src="./img/img-intro-ribon.png" alt="" />
      <Box>
        <ImgLogo src="./img/wecume.png" alt="" />
        <IntroText>
          {mention1}
          <br /> {mention2}
        </IntroText>
      </Box>
    </IntroWrap>
  );
}

const IntroWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors['white']};
`;

const HorizontalBar = styled.div`
  position: absolute;
  top: 73px;
  left: 0;
  width: 100%;
  height: 59.6px;
  box-shadow: 5px 5px 2px 0 rgba(232, 155, 127, 0.7);
  background-color: ${({ theme }) => theme.colors['pale-salmon']};
`;

const VerticalBar = styled.div`
  position: absolute;
  top: 0;
  left: 82px;
  width: 58.8px;
  height: 100%;
  box-shadow: 5px 5px 2px 0 rgba(232, 155, 127, 0.7);
  background-color: ${({ theme }) => theme.colors['pale-salmon']};
`;

const ImgRibon = styled.img`
  position: relative;
  margin-top: 33px;
  width: 247.3px;
  height: 205.9px;
  object-fit: contain;
`;
const Box = styled.div`
  position: absolute;
  top: 260px;
  left: 172.3px;
`;
const ImgLogo = styled.img`
  width: 150px;
`;
const IntroText = styled.p`
  margin-top: 17.4px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.colors['pinkish-brown']};
`;

export default EditIntro;
