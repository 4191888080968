import React from 'react';

// components
import BrightnessAndContrast from './BrightnessAndContrast';

const Charming = ({ id, brightness, contrastSlope, contrastIntercept }) => {
  return (
    <defs>
      {/* <!-- CSSgram Instagram Sierra filter --> */}
      <filter
        id={`filter-image-${id}`}
        colorInterpolationFilters="sRGB"
        filterUnits="objectBoundingBox"
        primitiveUnits="objectBoundingBox"
        x="0"
        y="0"
        width="100%"
        height="100%"
      >
        {/* <!-- contrast --> */}
        <feComponentTransfer>
          <feFuncR type="linear" slope="0.8" intercept="0.1" />
          <feFuncG type="linear" slope="0.8" intercept="0.1" />
          <feFuncB type="linear" slope="0.8" intercept="0.1" />
        </feComponentTransfer>
        {/* <!-- saturate --> */}
        <feColorMatrix type="saturate" values="1.2" />
        {/* <!-- sepia 0.15 --> */}
        <feColorMatrix
          result="css"
          type="matrix"
          values="0.90895 0.11535 0.02835 0 0
                            0.05235 0.9529 0.0252 0 0
                            0.0408 0.0801 0.86965 0 0
                            0 0 0 1 0"
        />
        <feFlood floodColor="rgba(0, 70, 150, 0)" floodOpacity="1" />
        <feBlend mode="normal" in2="css" />
        <BrightnessAndContrast
          brightness={brightness}
          contrastSlope={contrastSlope}
          contrastIntercept={contrastIntercept}
        />
      </filter>
    </defs>
  );
};

export default Charming;
