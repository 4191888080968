import React, { useState, useCallback, useRef } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';

// components
import PageLayout from '../../shared/components/PageLayout/PageLayout';
import LoadingOverlay from '../Edit/components/LoadingOverlay';
import Header from '../../shared/components/Header/Header';
import Confirm from '../../shared/components/Confirm/Confirm';
import TextConfirm from '../../shared/components/TextConfirm/TextConfirm';
import ReactTooltip from 'react-tooltip';
import { MetroSpinner } from 'react-spinners-kit';

// hooks
import useLocalStorage from '../../shared/hooks/useLocalStorage';

// utills
import wait from '../../shared/utills/wait';
import { lang } from 'moment';

function Collection(props) {
  const history = useHistory();
  const location = useLocation();
  const { language } = location.state;
  const [isLoading, setIsLoading] = useState(false);

  

  // 콜렉션 저장하기
  const [
    collectionData,
    {
      removeVaule: removeCollectionData,
      modifyValue: modifyCollectionName,
      isLoading: collectionLoading,
    },
  ] = useLocalStorage('collection', []);

  const selectedCollectionId = useRef(null);

  // 삭제 경고창
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  // const delMentions = {
  //   title: '컬렉션을 삭제하시겠습니까?',
  //   subText: '컬렉션을 삭제하면 되돌릴 수 없습니다',
  // };
  const delMentions = {
    title: {
      ko: '컬렉션을 삭제하시겠습니까?',
      en: 'Do you want to delete this collection?',
      vi: 'Bạn có chắc chắn muốn xóa bộ sưu tập không?',
      ja: 'コレクションを削除しますか？',
      ch: '您确定要删除收藏吗？',
      sp: '¿Quieres eliminar la colección?'
    },
    subText: {
      ko: '컬렉션을 삭제하면 되돌릴 수 없습니다',
      en: 'It cannot be restored',
      vi: 'Nếu xóa bộ sưu tập sẽ không thể trở lại',
      ja: 'コレクションを削除する場合、復元できません',
      ch: '删除收藏品后，则无法返回',
      sp: 'Eliminar una colección es irreversible'
    },
  };
  const openRemoveConfirm = (evt) => {
    setIsShowConfirm(true);
    selectedCollectionId.current = evt.currentTarget.dataset.id;
  };
  const closeRemoveConfirm = () => {
    setIsShowConfirm(false);
  };
  const removeCollection = () => {
    setIsLoading(true);
    removeCollectionData(selectedCollectionId.current);
    wait(1000).then(() => {
      setIsLoading(false);
    });
  };

  // 이름 변경 창
  const [isShowTextConfirm, setIsShowTextConfirm] = useState(false);
  const [collectionName, setCollectionName] = useState('');

  const changeMentions = {
    title: {
      ko: '수정하고 싶은\n컬렉션 이름을 설정하세요',
      en: 'Change the name\n of the collection',
      vi: 'Đặt tên bộ sưu tập \n bạn muốn chỉnh sửa',
      ja: '修正したいコレクション\nの名前を設定してください',
      ch: '请设定想要修改的收藏品名称',
      sp: 'Establezca el nombre de \n la colección que desea editar'
    }
  };
  const openChangeConfirm = (evt) => {
    setIsShowTextConfirm(true);

    const data = evt.currentTarget.dataset;
    selectedCollectionId.current = data.id;
    setCollectionName(data.name);
  };
  const closeChangeConfirm = () => {
    setIsShowTextConfirm(false);
  };
  const changeCollectionNameHandler = (text) => {
    setIsLoading(true);
    modifyCollectionName(selectedCollectionId.current, 'name', text);
    wait(1000).then(() => {
      setIsLoading(false);
    });
  };

  // 페이지 정보 (번역정보)
  const pageInfo = {
    title: {
      ko: '내 작품 컬렉션',
      en: 'My Collection',
      vi: 'Bộ sưu tập của tôi',
      ja: 'マイコレクション',
      ch: '我的作品收藏',
      sp: 'Mi colección'
    },
    comment1: {
      ko: '이름편집',
      en: 'Edit name',
      vi: 'Sửa tên',
      ja: '名前を変更する',
      ch: '编辑名称',
      sp: 'Edita el nombre'
    },
    comment2: {
      ko: '삭제하기',
      en: 'Delete',
      vi: 'Xóa bỏ',
      ja: '削除する',
      ch: '删除',
      sp: 'Borrar'
    },
    btnCancelAction: {
      ko: useCallback(() => history.push('/'), [history]),
      en: useCallback(() => history.push('/en'), [history]),
      vi: useCallback(() => history.push('/vi'), [history]),
      ja: useCallback(() => history.push('/ja'), [history]),
      ch: useCallback(() => history.push('/ch'), [history]),
      sp: useCallback(() => history.push('/sp'), [history]),

    },
    btnNewCollection: {
      text: {
        ko: '+ 새로 편집해서 추가하기',
        en: '+ Edit and add new',
        vi: '+ Chỉnh sửa mới',
        ja: '+ 編集する',
        ch: '+ 编辑',
        sp: '+ Editar'
      },
      action: useCallback(() => {
        history.push({
          pathname: '/edit',
          state: { language },
        });
      }, [history, language]),
    },
  };

  return (
    <PageLayout iosFullScroll={true}>
      <CollectionWrap>
        <Header
          hideBack={true}
          title={pageInfo.title[language]}
          onClickBtnCancel={pageInfo.btnCancelAction[language]}
        />
        {isLoading && <LoadingOverlay />}
        <CollectionListWrap>
          <BtnNewCollection onClick={pageInfo.btnNewCollection.action}>
            {pageInfo.btnNewCollection.text[language]}
          </BtnNewCollection>
          {collectionLoading && (
            <SpinnerWrap>
              <MetroSpinner color="#999" size={20} />
            </SpinnerWrap>
          )}
          {!collectionLoading && (
            <CollectionLists>
              {!isEmpty(collectionData) &&
                collectionData.map((collection, idx) => {
                  const { id, name } = collection;

                  return (
                    <CollectionList
                      onClick={(evt) => {
                        if (evt.currentTarget === evt.target) {
                          history.push({
                            pathname: `/collection_detail/${name}`,
                            state: { language, collection },
                          });
                        }
                      }}
                      key={idx}
                    >
                      <CollectionTitle
                        onClick={() => {
                          history.push({
                            pathname: `/collection_detail/${name}`,
                            state: { language, collection },
                          });
                        }}
                      >
                        {name}
                      </CollectionTitle>
                      <BtnMore
                        data-tip
                        data-for={`clickme_${idx}`}
                        data-event="click"
                      >
                        <BtnIcon src="./img/btn-dot.svg" />
                      </BtnMore>
                      <ReactTooltip
                        id={`clickme_${idx}`}
                        place="top"
                        effect="solid"
                        clickable={true}
                        backgroundColor={'transparent'}
                        className="react-tooltip"
                        globalEventOff="click"
                      >
                        <ToolTipWrap>
                          <BtnToolTip
                            onClick={openChangeConfirm}
                            data-id={id}
                            data-name={name}
                          >
                            {pageInfo.comment1[language]}
                          </BtnToolTip>
                          <BtnToolTip onClick={openRemoveConfirm} data-id={id}>
                            {pageInfo.comment2[language]}
                          </BtnToolTip>
                        </ToolTipWrap>
                      </ReactTooltip>
                    </CollectionList>
                  );
                })}
            </CollectionLists>
          )}
        </CollectionListWrap>
        {isShowConfirm && (
          <Confirm
            message={delMentions.title[language]}
            subText={delMentions.subText[language]}
            onClose={closeRemoveConfirm}
            onClickOk={removeCollection}
            language={language}
          />
        )}
        {isShowTextConfirm && (
          <TextConfirm
            message={changeMentions.title[language]}
            onClose={closeChangeConfirm}
            onClickOk={changeCollectionNameHandler}
            defaultText={collectionName}
            language={language}
          />
        )}
      </CollectionWrap>
    </PageLayout>
  );
}

const CollectionWrap = styled.div`
  height: 100%;
  background-color: #f2f2f2;
`;
const CollectionListWrap = styled.div`
  padding: 80px 23px 50px;
  background-color: #f2f2f2;
`;
const BtnNewCollection = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  margin-top: 15px;
  padding: 0 31.5px;
  border-radius: 3px;
  border: dotted 1px #6f6f70;
  font-size: 15px;
  color: ${({ theme }) => theme.colors['very-light-pink2']};
`;
const SpinnerWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 15px;
`;
const CollectionLists = styled.ul``;
const CollectionList = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  margin-top: 15px;
  padding: 0 31.5px;
  border-radius: 3px;
  border: solid 1px #6f6f70;
`;
const BtnMore = styled.button`
  width: 22px;
  height: 50px;
`;
const BtnIcon = styled.img``;
const ToolTipWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
const BtnToolTip = styled.button`
  width: 99px;
  height: 40px;
  padding: 11.5px 0;
  background-color: ${({ theme }) => theme.colors['white']};
  border: solid 1px #707070;
  :first-child {
    border-bottom: none;
  }
  font-size: 15px;
  color: ${({ theme }) => theme.colors['brownish-grey']};
`;
const CollectionTitle = styled.h3`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  color: ${({ theme }) => theme.colors['black']};
`;
export default Collection;
