import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  createRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import findIndex from 'lodash/findIndex';
import last from 'lodash/last';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import moment from 'moment';
import { isIOS } from 'react-device-detect';
import ScrollNoneGlobalStyle from '../../shared/styles/globalStyle';
import axios from 'axios';

// components
import Header from '../../shared/components/Header/Header';
import SubHeader from './components/SubHeader';
import Content from './components/Content';
import BottomTabBar from './components/BottomTabBar';
import BottomControlArea from './components/BottomControlArea';
import Confirm from '../../shared/components/Confirm/Confirm';
import TextConfirm from '../../shared/components/TextConfirm/TextConfirm';
import SelectConfirm from '../../shared/components/SelectConfirm/SelectConfirm';
import SaveComplete from './components/SaveComplete';
import PhotoEditTabBar from './components/PhotoEditTabBar';
import TempleteSelectArea from './components/TempleteSelectArea';

// hooks
import useStack from '../../shared/hooks/useStack';
import useBackground from '../../shared/hooks/useBackground';
import useTemplate from '../../shared/hooks/useTemplate';
import useWindowSize from '../../shared/hooks/useWindowSize';
import usePhotoEdit from '../../shared/hooks/usePhotoEdit';
import useTextEdit from '../../shared/hooks/useTextEdit';
import usePopup from '../../shared/hooks/usePopup';
import { useScreenshot } from '../../shared/hooks/useScreenshot_old';
import usePage from '../../shared/hooks/usePage';

// utills
import wait from '../../shared/utills/wait';

// constants
import { categoryData } from '../../shared/constants/templateCategory';
import { bgInitState } from '../../shared/constants/bgData';
import { URL } from '../../shared/constants/apiInfo';

const headerTitle1 = {
  edit: '편집하기',
  save: '저장하기',
  saveGalleryComplete: '저장하기',
};
const headerTitle2 = {
  edit: 'Edit',
  save: 'Save',
  saveGalleryComplete: 'Save',
};

function AdminEdit(props) {
  const history = useHistory();
  const language = 'ko';

  let browsermention = '저장하지 않고\n돌아가시겠습니까?';
  let savemention = '저장할 템플릿의 \n언어와 카테고리를 선택해주세요.';
  let headerTitle = headerTitle1;
  if (language === 'en') {
    headerTitle = headerTitle2;
    browsermention = 'All the works will be erased. \nWould you like to leave?';
    savemention = '저장할 템플릿의 \n언어와 카테고리를 선택해주세요.';
  }

  const [isAdmin, setIsAdmin] = useState(false);
  const [isShowAdminPopup, setIsShowAdminPopup] = useState(true);

  const requestAdminCheck = (inputPw) => {
    let url = `${URL}/permission`;

    axios
      .post(url, { password: inputPw })
      .then(function (response) {
        if (response.data === 'ok') {
          setIsAdmin(true);
          setIsShowAdminPopup(false);
        } else {
          alert('비밀번호가 일치하지 않습니다.');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // 요소들 인덱스 값
  const elIdxRef = useRef(0);

  // ===== 윈도우 높이 값 ===== //
  const { height: windowH } = useWindowSize();

  // ===== 페이지 타입 ===== //
  const [pageType, setPageType] = useState('edit');

  // ===== 인트로 여부 ===== //
  const [isIntro, setIsIntro] = useState(false);

  // ===== 편집모드 활성화에 따른 상단 기능 버튼 비활성화  ===== //
  const [isEditOpen, setIsEditOpen] = useState(false);

  // ===== 탭 ===== //
  const [currentTab, setCurrentTab] = useState('default');

  // 탭 초기화 상태로 변경
  const setCurrentTabDefault = () => {
    setCurrentTab('default');
    setIsEditOpen(false);
  };
  // ===== 템플릿 모드 ===== //
  const [isTempleteSelectOpen, setIsTempleteSelectOpen] = useState(false);
  const [selectedTempleteData, setSelectedTempleteData] = useState();
  const [templateState, { changeCapureInner }] = useTemplate();

  // 템플릿 셋팅
  const setTempelte = () => {
    if (selectedTempleteData) {
      setHistory(selectedTempleteData);
    }
  };

  // ===== 사진편집 모드 ===== //
  const [photoEditMode, setPhotoEditMode] = useState(false);
  // ===== 사진 편집 탭 ===== //
  const [photoEditTab, setPhotoEditTab] = useState('default');

  const photoEditCancelHandler = () => {
    setPhotoEditMode(false);
    setPhotoEditTab('default');
    setIsEditOpen(false);

    // photo state초기화
    setInitPhotoInfo();
  };

  // ===== 전체 편집 스택 ===== //
  const [
    historyAllState,
    historyState,
    {
      set: setHistory,
      undo,
      redo,
      change: changeHistory,
      clear,
      canUndo,
      canRedo,
    },
  ] = useStack([]);

  // 요소 편집 stack history 추가
  const addHistoryStateHandler = (item) => {
    setHistory(historyState.concat(item));
  };

  // 요소 편집 stack history 삭제
  const deleteHistoryStateHandler = (deleteIdx) => {
    setHistory(historyState.filter((data) => data.id !== deleteIdx));
  };

  // ===== 페이지 정보 ===== //
  const [
    pageState,
    { setPagedata, setPageImg, chagePage, addPage, removePageData, initPage },
  ] = usePage();

  const [defaultPageImg, setDefaultPageImg] = useState();
  const { seletedPage, pageData } = pageState;
  // 페이지 변경
  const onChagePage = (idx) => {
    if (find(pageData[idx].stackData.present, ['type', 'background'])) {
      setBgInfo(
        get(
          last(
            pageData[idx].stackData.present.filter(
              (item) => item.type === 'background',
            ),
          ),
          'elInfo',
        ),
      );
    } else {
      setBgInfo(bgInitState);
    }
    chagePage(idx);
    changeHistory(pageData[idx].stackData);
  };
  // 페이지 추가
  const onAddPage = () => {
    initBg();
    setRatioInfoState();
    addPage();
    setPageImg(defaultPageImg);
    changeHistory({ past: [], present: [], future: [] });
  };

  // 스택데이터 page데이터에 저장
  useEffect(() => {
    setPagedata(historyAllState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyAllState]);

  // 초기 이미지 셋팅
  useEffect(() => {
    if (isAdmin) {
      setIsLoading(true);
      takeScreenShot(captureAreaRef.current).then((image) => {
        setDefaultPageImg(image);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin]);

  // 페이지 탭 활성화 시 이미지 셋팅
  useEffect(() => {
    if (currentTab === 'page') {
      setIsLoading(true);
      takeScreenShot(captureAreaRef.current).then((image) => {
        setPageImg(image);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const contentDisabled = isTempleteSelectOpen;

  // ===== [Moveable 요소] ===== //
  const [moveableData, setMoveableData] = useState([]);
  const drawMoveableObject = (moveableObj) => {
    let uniqByIdMovableData = [];

    // history 데이터에서 moveable요소만 찾아서 셋팅
    moveableObj
      // historyState
      .filter((item) => item.type !== 'background')
      .forEach((item) => {
        const sameIdIdx = findIndex(uniqByIdMovableData, ['id', item.id]);

        if (sameIdIdx > -1) {
          uniqByIdMovableData[sameIdIdx] = item;
        } else {
          uniqByIdMovableData.push(item);
        }
      });

    // moveable요소 순서 정렬
    let orderedMoveableData = [];

    uniqByIdMovableData.forEach((uniqItem, uniqIdx) => {
      moveableData.forEach((moveAbleitem, moveAbleidx) => {
        if (uniqItem.id === moveAbleitem.id) {
          orderedMoveableData[moveAbleidx] = uniqItem;
        }
      });
      if (findIndex(orderedMoveableData, ['id', uniqItem.id]) === -1) {
        orderedMoveableData[uniqIdx] = uniqItem;
      }
    });

    setMoveableData(orderedMoveableData);

    // history 데이터에서 bg마지막 요소 찾아서 셋팅
    if (find(moveableObj, ['type', 'background'])) {
      if (currentTab !== 'background') {
        setBgInfo(
          get(
            last(moveableObj.filter((item) => item.type === 'background')),
            'elInfo',
          ),
        );
      }
    } else {
      initBg();
    }
  };
  // [Moveable] 데이터 셋팅
  useEffect(() => {
    drawMoveableObject(pageData[seletedPage].stackData.present);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState]);

  // [Moveable] 에디트 모드 해제
  const offEditableHandler = () => {
    setMoveableData((state) => {
      return state.map((item) => {
        item.isEditable = false;
        return item;
      });
    });
  };

  // [Moveable] 선택한 요소 젤 앞으로 및 에디트 모드로 변경
  const setEditableHandler = (id) => {
    setMoveableData((state) => {
      let orderedState;
      orderedState = isEmpty(state)
        ? []
        : [
            ...state.filter((item) => item.id !== id),
            state.find((item) => {
              return item.id === id;
            }),
          ];

      return orderedState.map((item) => {
        item.isEditable = item.id === id ? true : false;
        return item;
      });
    });
    photoEditCancelHandler();
  };

  // [Moveable] 에디트 모드 해제(추가한 요소를 제외한 다른 요소 클릭 시)
  const onClickDocument = (evt) => {
    !evt.target.classList.contains('editmode-off-none') && offEditableHandler();
    (evt.target.classList.contains('content-wrap') ||
      evt.target.classList.contains('capture-wrap') ||
      evt.target.classList.contains('subHeaderWrap')) &&
      photoEditCancelHandler();
  };

  // document에 Moveable 요소 에디트모드 해제 이벤트 등록
  useEffect(() => {
    document.addEventListener('click', onClickDocument);

    return () => {
      document.removeEventListener('click', onClickDocument);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // modify 모드 활성화
  const modifyMoveableDataHandler = (id, type) => {
    const targetData = last(
      pageData[seletedPage].stackData.present.filter((item) => item.id === id),
    );

    // 편집 모드 활성화
    setIsEditOpen(true);

    switch (type) {
      case 'photo':
        setPhotoInfo({
          id,
          ...targetData.elInfo,
          transformInfo: targetData.transformInfo,
        });
        setCurrentTab('default');
        setPhotoEditMode(true);
        break;
      case 'text':
        setCurrentTab('write');
        setFontInfo({
          id,
          ...targetData.elInfo,
          transformInfo: targetData.transformInfo,
        });
        break;
      default:
        console.log('타입이 정의되지 않았습니다.');
    }
  };

  // ===== 사진 편집 정보 ===== //
  const [
    photoState,
    {
      setPhotoInfo,
      changeCropPhoto,
      changeReversal,
      changeFilter,
      changeBrightness,
      changeContrast,
      setInitPhotoInfo,
      photoEditCancel,
    },
  ] = usePhotoEdit();

  // ===== 텍스트 편집 정보 ===== //
  const [
    textState,
    {
      changeAlign,
      changeTextColor,
      changeFontFamily,
      changeFontSize,
      setFontInfo,
      setInitFontInfo,
    },
  ] = useTextEdit();

  // ===== 배경 편집 정보 ===== //
  const [
    bgEditState,
    {
      setBgInfo,
      changeColor,
      setRatioInfo,
      changeRatio,
      changeBgPhoto,
      editBgEnd,
      editBgCancel,
      initBg,
    },
  ] = useBackground();

  // 배경 정보 셋팅
  const [presentBgData, setPresentBgData] = useState([]);

  useEffect(() => {
    const { color, ratio, src } = bgEditState;

    setPresentBgData({ color, ratio, src });
  }, [bgEditState]);

  // 배경 ratio 정보 셋팅
  const setRatioInfoState = useCallback(() => {
    const contentEl = contentAreaRef.current;

    setRatioInfo(contentEl.offsetWidth, contentEl.offsetHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 배경 ratio 기본 정보 셋팅
  useEffect(() => {
    if (pageType === 'edit' && isAdmin) {
      setRatioInfoState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageType, isAdmin]);

  // 배경 ratio 기본 정보 셋팅
  useEffect(() => {
    const { ratio } = bgEditState;

    if (ratio.width === 0 && isAdmin) {
      setRatioInfoState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bgEditState, isAdmin]);

  // resize 시 배경 ratio 기본 정보 셋팅
  useEffect(() => {
    window.addEventListener('resize', setRatioInfoState);
    return () => {
      window.removeEventListener('resize', setRatioInfoState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 배경 변경 적용
  const completeBgChangeHandler = () => {
    const historyBgInfo = get(
      last(
        pageData[seletedPage].stackData.present.filter(
          (item) => item.type === 'background',
        ),
      ),
      'elInfo',
    );

    if (!isEqual(historyBgInfo, presentBgData)) {
      addHistoryStateHandler({
        id: `${moment().format('YYYY-DD-MM-HH-mm-ss')}_${elIdxRef.current}`,
        type: 'background',
        elInfo: {
          color: presentBgData.color,
          ratio: presentBgData.ratio,
          src: presentBgData.src,
        },
        transformInfo: null,
        keepRatio: null,
        isEditable: null,
        canModify: false,
      });
      // 배경 적용
      editBgEnd(presentBgData);
      // index 중가
      elIdxRef.current += 1;
    }
  };

  // == historyClear == //
  const historyClear = useCallback(() => {
    clear();
    initBg();
    initPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ===== 화면 캡쳐 처리 및 이미지 저장 ===== //
  const contentAreaRef = useRef();
  const captureAreaRef = createRef(null);
  const [takeScreenShot, { error }] = useScreenshot();
  const [isLoading, setIsLoading] = useState(false);

  // 템플릿 저장 (관리자 용)
  const [tempeleteLang, setTempleteLang] = useState('ko');
  const [tempeleteCategory, setTempleteCategory] = useState('photoBook');
  const selectData = [
    {
      title: '언어',
      key: 'lang',
      data: ['ko', 'en', 'vi', 'ja', 'ch', 'sp'],
      selectedValue: tempeleteLang,
      onChangeFn: setTempleteLang,
    },
    {
      title: '카테고리',
      key: 'category',
      data: [...categoryData.map(({ name }) => name)],
      selectedValue: tempeleteCategory,
      onChangeFn: setTempleteCategory,
    },
  ];
  const resetTempleteSelectData = () => {
    setTempleteLang('ko');
    setTempleteCategory('photoBook');
  };

  // 템플릿 데이터 저장 (관리자 용)
  const saveTempleteData = () => {
    setIsShowSavePopup(false);
    setIsLoading(true);

    // 1. past, future 빈배열로 셋팅 및 캔버스 사이즈 셋팅
    const pageDataForSave = pageState.pageData.map((data, idx) => {
      data.stackData.past = [];
      data.stackData.future = [];
      data.canvasSize = {
        width: bgEditState.ratio.width,
        height: bgEditState.ratio.height,
      };
      return data;
    });

    const captureEl = captureAreaRef.current;

    // 2. 데이터 설정
    const saveObj = {
      pageData: JSON.stringify({
        stackData: { ...pageDataForSave[0].stackData },
        canvasSize: { ...pageDataForSave[0].canvasSize },
        captureSize: {
          width: captureEl.offsetWidth,
          height: captureEl.offsetHeight,
        },
      }),
      lang: tempeleteLang,
      category: tempeleteCategory,
      imgData: pageDataForSave[0].img,
    };

    // 3. 서버에 저장하기;
    let url = `${URL}/upload`;

    axios
      .post(url, saveObj)
      .then(function (response) {
        if (response.data === 'ok') {
          // 4. 저장완료 페이지로 변경
          wait(3000).then(() => {
            setPageType('save');
            setIsLoading(false);
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    // 셀렉트 데이터 초기화
    resetTempleteSelectData();
  };

  // 저장하기 버튼 클릭
  const onClickSave = () => {
    setIsLoading(true);
    offEditableHandler();

    // 현재 페이지 스크린샷 생성
    takeScreenShot(captureAreaRef.current, 1).then((image) => {
      setPageImg(image);
      setIsLoading(false);
      setIsShowSavePopup(true);
    });
  };

  // 저장 에러 체크
  useEffect(() => {
    if (error) {
      console.log(error);
    }
  }, [error]);

  // ===== 저장 팝업 ===== //
  const [isShowSavePopup, setIsShowSavePopup] = usePopup();
  const closePopupHandler = () => {
    setIsShowSavePopup(false);
    resetTempleteSelectData();
  };

  // ===== [헤더]뒤로가기 버튼 이벤트 ===== //
  const [isShowAlert, setIsShowAlert] = usePopup();
  const onClickBtnBack = () => {
    setIsShowAlert(true);
  };
  const goIntroHandler = useCallback(() => {
    historyClear();
    history.push('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
  const goIntroHandlerEn = useCallback(() => {
    historyClear();
    history.push('/en');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  let introHandler = goIntroHandler;
  if (language === 'en') {
    introHandler = goIntroHandlerEn;
  }

  // ===== [브라우저] 뒤로가기 버튼 이벤트 ===== //
  useEffect(() => {
    // 홈버튼 눌렀을때 && save 페이지 아닐 때 && saveGalleryComplete 페이지 아닐때
    if (
      !isShowAlert &&
      pageType !== 'save' &&
      pageType !== 'saveGalleryComplete'
    ) {
      const unblock = history.block(browsermention);
      return () => {
        unblock();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browsermention, history, isShowAlert, pageType]);

  // 페이지 타입별 header 뒤로가기 숨김
  const headerHideBack =
    pageType === 'save' || pageType === 'saveGalleryComplete';

  return (
    <React.Fragment>
      {isShowAdminPopup && (
        <TextConfirm
          message={'관리자 비밀번호를 입력해주세요'}
          onClickOk={requestAdminCheck}
          defaultText={''}
        />
      )}
      {isAdmin && (
        <PageWrap height={windowH} platform={isIOS}>
          {pageType !== 'edit' && (
            <Header
              hideBack={headerHideBack}
              title={headerTitle[pageType]}
              onClickBtnBack={onClickBtnBack}
            />
          )}

          {pageType === 'edit' && (
            <React.Fragment>
              <SubHeader
                undo={undo}
                redo={redo}
                clear={historyClear}
                canUndo={canUndo}
                canRedo={canRedo}
                isEditOpen={isEditOpen}
                onClickSave={onClickSave}
                isIntro={isIntro}
                language={language}
                onClickBtnHome={onClickBtnBack}
              />
              <Content
                pageType={pageType}
                isLoading={isLoading}
                contentAreaRef={contentAreaRef}
                captureAreaRef={captureAreaRef}
                moveableData={moveableData}
                setEditableHandler={setEditableHandler}
                modifyMoveableDataHandler={modifyMoveableDataHandler}
                deleteHistoryStateHandler={deleteHistoryStateHandler}
                addHistoryStateHandler={addHistoryStateHandler}
                bgEditState={presentBgData}
                photoState={photoState}
                templateState={templateState}
                isIntro={isIntro}
                language={language}
                photoEditTab={photoEditTab}
                changeCropPhoto={changeCropPhoto}
                contentDisabled={contentDisabled}
              />
              <BottomTabBar
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                setIsIntro={setIsIntro}
                completeBgChangeHandler={completeBgChangeHandler}
                language={language}
                setIsEditOpen={setIsEditOpen}
                setIsTempleteSelectOpen={setIsTempleteSelectOpen}
                isTempleteSelectOpen={isTempleteSelectOpen}
                setTempelte={setTempelte}
              />
            </React.Fragment>
          )}
          {(pageType === 'save' || pageType === 'saveGalleryComplete') && (
            <SaveComplete
              pageType={pageType}
              setPageType={setPageType}
              setIsIntro={setIsIntro}
              clear={historyClear}
              language={language}
              introHandler={introHandler}
              isLoading={isLoading}
            />
          )}

          {currentTab !== 'default' && (
            <BottomControlArea
              currentTab={currentTab}
              setCurrentTabDefault={setCurrentTabDefault}
              addHistoryStateHandler={addHistoryStateHandler}
              elIdx={elIdxRef}
              textState={textState}
              changeAlign={changeAlign}
              changeTextColor={changeTextColor}
              changeFontFamily={changeFontFamily}
              changeFontSize={changeFontSize}
              setInitFontInfo={setInitFontInfo}
              bgEditState={presentBgData}
              setBgColor={changeColor}
              setBgRatio={changeRatio}
              setBgPhoto={changeBgPhoto}
              setBgEnd={editBgEnd}
              setBgCancel={editBgCancel}
              completeBgChangeHandler={completeBgChangeHandler}
              language={language}
              pageState={pageState}
              chagePage={onChagePage}
              addPage={onAddPage}
              removePageData={removePageData}
            />
          )}
          {isTempleteSelectOpen && (
            <TempleteSelectArea
              setTempelte={setTempelte}
              setIsTempleteSelectOpen={setIsTempleteSelectOpen}
              drawMoveableObject={drawMoveableObject}
              pageData={pageData[seletedPage].stackData.present}
              setSelectedTempleteData={setSelectedTempleteData}
              bgEditState={bgEditState}
              language={language}
              setIsLoading={setIsLoading}
              changeCapureInner={changeCapureInner}
            />
          )}
          <PhotoEditTabBar
            photoEditMode={photoEditMode}
            photoEditTab={photoEditTab}
            setPhotoEditTab={setPhotoEditTab}
            setIsEditOpen={setIsEditOpen}
            photoState={photoState}
            changeCropPhoto={changeCropPhoto}
            changeReversal={changeReversal}
            changeFilter={changeFilter}
            changeBrightness={changeBrightness}
            changeContrast={changeContrast}
            addHistoryStateHandler={addHistoryStateHandler}
            setPhotoInfo={setPhotoInfo}
            photoEditCancel={photoEditCancel}
            language={language}
            photoEditCancelHandler={photoEditCancelHandler}
          />

          {isShowAlert && (
            <Confirm
              message={browsermention}
              onClose={setIsShowAlert}
              onClickOk={introHandler}
              language={language}
            />
          )}
          {isShowSavePopup && (
            <SelectConfirm
              message={savemention}
              selectData={selectData}
              onClose={closePopupHandler}
              onClickOk={saveTempleteData}
              language={language}
            />
          )}
        </PageWrap>
      )}

      <ScrollNoneGlobalStyle />
    </React.Fragment>
  );
}

const PageWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ platform }) => {
    return platform ? `cursor: pointer;` : '';
  }}
  height: 100%;
`;

export default AdminEdit;
