import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

// constants
import { fontColors, fontFamilys } from '../../../shared/constants/fontData';

// components
import Alert from '../../../shared/components/Alert/Alert';
import ColorLists from './ColorLists';
import { Scrollbars } from 'react-custom-scrollbars';

// hooks
import usePopup from '../../../shared/hooks/usePopup';

const alignBtns = [
  { name: 'left', img: 'icon-align-left' },
  { name: 'center', img: 'icon-align-center' },
  { name: 'right', img: 'icon-align-right' },
];

function WriteArea(props) {
  const {
    setCurrentTabDefault,
    addHistoryStateHandler,
    elIdx,
    textState,
    changeAlign,
    changeTextColor,
    changeFontFamily,
    setInitFontInfo,
  } = props;

  const location = useLocation();
  const { language } = location.state;

  const {
    id,
    transformInfo,
    text,
    textAlign,
    fontColor,
    fontFamily,
    fontSize,
  } = textState;
  const textEditWrapRef = useRef();
  const colorWrapRef = useRef();
  const textEditAreaRef = useRef();
  const [textEditAreaHeight, setTextEditAreaHeight] = useState(0);
  const bottomWrapRef = useRef();
  const prefix = 'text';

  // 페이지 정보 (번역정보)
  const pageInfo = {
    text: {
      noneTextNotification: {
        ko: '텍스트를 입력해주세요.',
        en: 'You Should Write Something.',
        vi: 'Vui lòng nhập văn bản',
        ja: 'テキストを入力してください',
        ch: '请输入文字',
        sp: 'Por favor ingrese el texto'
      },
      defaultText: {
        ko: '가나다',
        en: 'ABC',
        vi: 'ABC',
        ja: 'あいう',
        ch: 'ABC',
        sp: 'ABC'
      },
    },
    btns: {
      btnApply: {
        text: { ko: '완료', en: 'Apply', vi: 'Hoàn thành', ja: '完了', ch: '完成', sp: 'Completo' },
      },
      btnCancel: {
        text: { ko: '취소', en: 'Cancel', vi: 'Hủy bỏ', ja: 'キャンセル', ch: '取消', sp: 'Cancelar' },
      },
      btnWrite: {
        text: { ko: '작성완료/나가기', en: 'Complete/Leave', vi: 'Đã hoàn thành/Thoát', ja: '作成完了／戻る', ch: '填写完毕/退出', sp: 'Completado/Salir' },
      },
    },
  };

  // 팝업 state
  const [isShowPopup, setIsShowPopup] = usePopup();
  const okPopupHandler = () => {
    setIsShowPopup(false);
    textEditAreaRef.current.focus();
  };
  const closePopupHandler = () => {
    setIsShowPopup(false);
  };

  useEffect(() => {
    setTextEditAreaHeight(
      textEditWrapRef.current.clientHeight -
        colorWrapRef.current.clientHeight -
        bottomWrapRef.current.clientHeight,
    );
  }, []);

  // 텍스트 영역 활성화 여부
  const [isInputFocus, setIsInputFocus] = useState(false);

  const onFocusInput = useCallback(() => {
    setIsInputFocus(true);
  }, []);
  const onBlurInput = useCallback(() => {
    setIsInputFocus(false);
  }, []);

  // 텍스트 정렬 선택
  const onClickBtnAlign = useCallback(
    (evt) => {
      changeAlign(evt.currentTarget.dataset.name);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // 컬러 선택
  const onClickColor = (evt) => {
    changeTextColor(evt.target.dataset.color);
  };

  // 폰트 종류 선택
  const onClickFontFamily = (evt) => {
    changeFontFamily(evt.target.dataset.font);
  };

  // 적용취소 버튼 클릭
  const onClickBtnCancel = () => {
    setCurrentTabDefault();
    setInitFontInfo();
  };

  // 적용완료 버튼 클릭
  const onClickBtnComplete = () => {
    const textArea = textEditAreaRef.current;

    if (textArea.innerText.length === 0) {
      setIsShowPopup(true);
      return false;
    }
    const initTransformInfo = {
      translate: [100, 100],
      rotate: 0,
      scale: [1, 1],
    };

    addHistoryStateHandler({
      id:
        id === null
          ? `${moment().format('YYYY-DD-MM-HH-mm-ss')}_${elIdx.current}`
          : id,
      type: prefix,
      elInfo: {
        text: textArea.innerText,
        textAlign,
        fontColor,
        fontFamily,
        fontSize,
      },
      transformInfo: transformInfo || initTransformInfo,
      keepRatio: true,
      isEditable: true,
      canModify: true,
    });

    if (id === null) {
      // index 중가
      elIdx.current += 1;
    }

    // 하단 컨트롤 영역 닫기
    setCurrentTabDefault();

    // fontState 초기화
    setInitFontInfo();
  };

  return (
    <WriteAreaWrap>
      {isShowPopup && (
        <Alert
          message={pageInfo.text.noneTextNotification[language]}
          onOk={okPopupHandler}
          onClose={closePopupHandler}
          language={language}
        />
      )}
      <AlignWrap isInputFocus={isInputFocus}>
        {alignBtns.map((item) => {
          const { name, img } = item;
          const imgUrl =
            name === textAlign ? `./img/${img}_active.svg` : `./img/${img}.svg`;

          return (
            <BtnAlign onClick={onClickBtnAlign} key={name} data-name={name}>
              <BtnIcon src={imgUrl} />
            </BtnAlign>
          );
        })}
      </AlignWrap>
      <TextEditWrap ref={textEditWrapRef} isInputFocus={isInputFocus}>
        <Wrap isInputFocus={isInputFocus}>
          <ColorLists
            colorWrapRef={colorWrapRef}
            colors={fontColors}
            selectedColor={fontColor}
            onClickColor={onClickColor}
          />
        </Wrap>
        <TextWrap height={textEditAreaHeight} isInputFocus={isInputFocus}>
          <TextEditArea
            contentEditable={true}
            textAlign={textAlign}
            textColor={fontColor}
            fontFamily={fontFamily}
            fontSize={fontSize}
            suppressContentEditableWarning={true}
            ref={textEditAreaRef}
            onFocus={onFocusInput}
          >
            {text}
          </TextEditArea>
        </TextWrap>
        <BottomWrap ref={bottomWrapRef} isInputFocus={isInputFocus}>
          <FontFamilyWrap>
            <Scrollbars
              autoHide={true}
              autoHideTimeout={1000}
              renderView={(props) => (
                <div
                  {...props}
                  style={{ ...props.style, overflowY: 'hidden' }}
                />
              )}
            >
              <FontFamilyListItems>
                {fontFamilys.map((item) => {
                  const { title, fontName } = item;

                  return (
                    <FontFamilyItem key={title}>
                      <BtnCircle
                        onClick={onClickFontFamily}
                        isActive={fontName === fontFamily}
                        fontFamily={fontName}
                        data-font={fontName}
                      >
                        {pageInfo.text.defaultText[language]}
                      </BtnCircle>
                      <BtnFontFamilyItemText>
                        {
                          (() => {
                              switch(language) {
                                  case 'ko':
                                      return title;
                                  case 'en':
                                      return '';
                                  case 'vi':
                                      return '';
                              }
                          })()
                        }
                      </BtnFontFamilyItemText>
                    </FontFamilyItem>
                  );
                })}
              </FontFamilyListItems>
            </Scrollbars>
          </FontFamilyWrap>
          <BtnGroup>
            <BtnRound type={'cancel'} onClick={onClickBtnCancel}>
              {pageInfo.btns.btnCancel.text[language]}
            </BtnRound>
            <BtnRound type={'ok'} onClick={onClickBtnComplete}>
              {pageInfo.btns.btnApply.text[language]}
            </BtnRound>
          </BtnGroup>
        </BottomWrap>
        <TextEditBtnGroup isInputFocus={isInputFocus}>
          <BtnHeaderText onClick={onBlurInput}>{pageInfo.btns.btnWrite.text[language]}</BtnHeaderText>
        </TextEditBtnGroup>
      </TextEditWrap>
    </WriteAreaWrap>
  );
}
const Wrap = styled.div`
  display: ${({ isInputFocus }) => (isInputFocus ? 'none' : '')};
  padding: 15px 30px 0px;
`;
const WriteAreaWrap = styled.div`
  position: fixed;
  top: calc(50px + constant(safe-area-inset-top));
  top: calc(50px + env(safe-area-inset-top));
  height: calc(100% - 115px - constant(safe-area-inset-top));
  height: calc(
    100% - 115px - env(safe-area-inset-top) - env(safe-area-inset-bottom)
  );
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
`;
const AlignWrap = styled.div`
  display: ${({ isInputFocus }) => (isInputFocus ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 0 15px;
  background: ${({ theme }) => theme.colors['brownish-grey']};
  > button {
    height: 100%;
  }
`;
const BtnAlign = styled.button`
  margin: 0 16.5px;
`;
const BtnIcon = styled.img``;
const TextEditWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ isInputFocus }) =>
    isInputFocus ? '100%' : 'calc(100% - 150px)'};
`;
const BottomWrap = styled.div`
  display: ${({ isInputFocus }) => (isInputFocus ? 'none' : '')};
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 40px;
`;
const TextWrap = styled.div`
  position: relative;
  z-index: 1;
  max-height: ${({ height, isInputFocus }) =>
    isInputFocus ? 'calc(100% - 60px)' : `${height}px`};
  margin-top: 15px;
  overflow: auto;
`;
const TextEditArea = styled.pre`
  min-height: 106px;
  margin: 0 30px;
  padding: 14px 5px;
  border: 3px solid ${({ theme }) => theme.colors['white']};
  text-align: ${({ textAlign }) => textAlign};
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${({ textColor }) => textColor};
  background-color: #636363;
  caret-color: white;
  white-space: pre-wrap;
`;
const FontFamilyWrap = styled.div`
  height: 85px;
  padding-left: 30px;
`;
const FontFamilyListItems = styled.div`
  display: inline-flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin: 0 -11px;
`;
const FontFamilyItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  @media screen and (min-width: 544px) {
    width: 20%;
  }
  @media screen and (min-width: 768px) {
    width: 16.66%;
  }
  min-width: 70px;
  padding: 0 11px;
  margin-bottom: 15px;
`;
const BtnCircle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 45px;
  padding: 0;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors['pale-salmon'] : theme.colors['white']};
  border-radius: 50%;
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: 12px;
  color: ${({ theme }) => theme.colors['black']};
  :after {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;
const BtnFontFamilyItemText = styled.p`
  margin-top: 5px;
  font-size: 10px;
  color: ${({ theme }) => theme.colors['white']};
  text-align: center;
  word-break: keep-all;
`;
const FontSizeWrap = styled.div`
  padding: 0 30px;
`;
const BtnGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`;
const BtnRound = styled.button`
  padding: 6px 25px;
  margin: 0 5px;
  border-radius: 12px;
  background-color: ${({ type, theme }) =>
    type === 'cancel' ? theme.colors['black'] : theme.colors['pinkish-tan']};
  font-size: 12px;
  color: ${({ theme }) => theme.colors['white']};
`;

const TextEditBtnGroup = styled.div`
  display: ${({ isInputFocus }) => (isInputFocus ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  padding: 20px;
  background: ${({ theme }) => theme.colors['black']};
`;
const BtnHeaderText = styled.button`
  font-size: 15px;
  color: ${({ theme }) => theme.colors['white']};
`;

export default WriteArea;
