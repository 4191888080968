import React, { useState } from 'react';
import styled from 'styled-components';

// components
import Portal from '../Portal/Portal';

function TextConfirm({ message, onClickOk, onClose, language, defaultText }) {
  const [text, setText] = useState(defaultText);

  const onChageInput = (evt) => {
    setText(evt.target.value);
  };

  const onClickOkHandler = () => {
    onClickOk(text);
    onClose && onClose();
  };

  //language 설정
  let mention1 = '취소';
  let mention2 = '완료';
  if (language === 'en') {
    mention1 = 'Cancel';
    mention2 = 'Complete';
  }
  if (language === 'vi') {
    mention1 = 'Huỷ bỏ';
    mention2 = 'hoàn thành';
  }
  if (language === 'ja') {
    mention1 = 'キャンセル';
    mention2 = '完了';
  }
  if (language === 'ch') {
    mention1 = '取消';
    mention2 = '完成';
  }
  if (language === 'sp') {
    mention1 = 'Cancelar';
    mention2 = 'Completo';
  }

  return (
    <Portal elementId="textConfirm-root">
      <ConfirmWrap>
        <BackDrop onClick={onClose} />
        <PopupDialog>
          <Message>
            {message.split('\n').map((line, idx) => {
              return (
                <span key={idx}>
                  {line}
                  <br />
                </span>
              );
            })}
          </Message>
          <TextInput type="text" value={text} onChange={onChageInput} />
          <BtnGroup>
            {onClose && (
              <Btn bgcolor={'#6f6f6f'} onClick={onClose}>
                {mention1}
              </Btn>
            )}

            <Btn bgcolor={'#e89b7f'} onClick={onClickOkHandler}>
              {mention2}
            </Btn>
          </BtnGroup>
        </PopupDialog>
      </ConfirmWrap>
    </Portal>
  );
}

const ConfirmWrap = styled.div`
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 320px;
  z-index: 20000;
  text-align: center;
`;

const PopupDialog = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 277px;
  padding: 27px 5px;
  background-color: ${({ theme }) => theme.colors['black']};
  border-radius: 14px;
`;

const Message = styled.p`
  font-size: 15px;
  font-weight: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.colors['white']};
`;

const TextInput = styled.input`
  width: 177px;
  height: 27px;
  margin-top: 5px;
  padding: 5px 10px;
  border: solid 1px #707070;
  background-color: ${({ theme }) => theme.colors['white']};
  color: ${({ theme }) => theme.colors['very-light-pink2']};
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 14px;
`;

const Btn = styled.button`
  width: 68px;
  margin: 0 5px;
  padding: 6px 0;
  background-color: ${({ bgcolor }) => bgcolor};
  border-radius: 12px;
  text-align: center;
  font-size: 12px;
  color: ${({ theme }) => theme.colors['white']};
`;

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;

export default TextConfirm;
