export const photoFilterData = [
  {
    name: 'none',
    title: { ko: '없음', En: 'None' },
  },
  {
    name: 'dream',
    title: { ko: 'Dream', En: 'Dream' },
  },
  {
    name: 'windy',
    title: { ko: 'Windy', En: 'Windy' },
  },
  {
    name: 'whisper',
    title: { ko: 'Whisper', En: 'Whisper' },
  },
  {
    name: '1970',
    title: { ko: "1970's", En: "1970's" },
  },
  {
    name: 'caffeine',
    title: { ko: 'Caffeine', En: 'Caffeine' },
  },
  {
    name: 'clearday',
    title: { ko: 'Clear day', En: 'Clear day' },
  },
  {
    name: 'pinkmood',
    title: { ko: 'Pink mood', En: 'Pink mood' },
  },
  {
    name: 'clam',
    title: { ko: 'Clam', En: 'Clam' },
  },
  {
    name: 'pillow',
    title: { ko: 'Pillow', En: 'Pillow' },
  },
  {
    name: 'tangerine',
    title: { ko: 'Tangerine', En: 'Tangerine' },
  },
  {
    name: 'yellow',
    title: { ko: 'Yellow', En: 'Yellow' },
  },
  {
    name: 'bright',
    title: { ko: 'Bright', En: 'Bright' },
  },
  {
    name: 'rainbow',
    title: { ko: 'Rainbow', En: 'Rainbow' },
  },
  {
    name: 'sunflower',
    title: { ko: 'Sunflower', En: 'Sunflower' },
  },
  {
    name: 'cooldown',
    title: { ko: 'Cool down', En: 'Cool down' },
  },
  {
    name: 'grape',
    title: { ko: 'Grape', En: 'Grape' },
  },
  {
    name: 'charming',
    title: { ko: 'Charming', En: 'Charming' },
  },
  {
    name: 'ash',
    title: { ko: 'Ash', En: 'Ash' },
  },
  {
    name: 'falling',
    title: { ko: 'Falling', En: 'Falling' },
  },
  {
    name: 'deep',
    title: { ko: 'Deep', En: 'Deep' },
  },
];
