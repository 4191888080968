import React from 'react';

// components
import BrightnessAndContrast from './BrightnessAndContrast';

const None = ({ id, brightness, contrastSlope, contrastIntercept }) => {
  return (
    <filter id={`filter-image-${id}`} colorInterpolationFilters="sRGB">
      <feColorMatrix
        type="matrix"
        values="1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0"
      />
      <BrightnessAndContrast
        brightness={brightness}
        contrastSlope={contrastSlope}
        contrastIntercept={contrastIntercept}
      />
    </filter>
  );
};

export default None;
