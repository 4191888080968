import { useState, useEffect } from 'react';

// Hook
const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getItem(key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedValue]);

  const getItem = async () => {
    let data;
    setIsLoading(true);

    if (window.Capacitor) {
      const item = await window.Capacitor.Plugins.Storage.get({ key: key });
      data = item.value;
    } else {
      data = window.localStorage.getItem(key);
    }

    setStoredValue(data ? JSON.parse(data) : initialValue);
  };

  const setItem = (key, value) => {
    if (window.Capacitor) {
      window.Capacitor.Plugins.Storage.set({
        key: key,
        value: JSON.stringify(value),
      });
    } else {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  };

  const setValue = (value) => {
    try {
      setStoredValue(storedValue.concat(value));
      setItem(key, storedValue.concat(value));
    } catch (error) {
      console.log(error);
    }
  };

  const removeVaule = (id) => {
    const newValue = storedValue.filter((collection) => collection.id !== id);

    setStoredValue(newValue);
    setItem(key, newValue);
  };

  const modifyAllValue = (id, collectionData) => {
    const newValue = storedValue.map((collection) => {
      if (collection.id === id) {
        collection = collectionData;
        return collection;
      } else {
        return collection;
      }
    });

    setStoredValue(newValue);
    setItem(key, newValue);
  };

  const modifyValue = (id, valueKey, value) => {
    const newValue = storedValue.map((collection) => {
      if (collection.id === id) {
        collection[valueKey] = value;
        return collection;
      } else {
        return collection;
      }
    });

    setStoredValue(newValue);
    setItem(key, newValue);
  };
  return [
    storedValue,
    { setValue, removeVaule, modifyValue, modifyAllValue, isLoading },
  ];
};

export default useLocalStorage;
