import React from 'react';

// components
import BrightnessAndContrast from './BrightnessAndContrast';

function PinkMood({ id, brightness, contrastSlope, contrastIntercept }) {
  return (
    <defs>
      {/* screen on filtered image */}
      <rect
        id="1977sc"
        x="0%"
        y="0%"
        width="100%"
        height="100%"
        fillOpacity=".3"
        mixblendmode="screen"
        fill="rgb(248,106,188)"
      />

      <filter
        id={`filter-image-${id}`}
        colorInterpolationFilters="sRGB"
        filterUnits="objectBoundingBox"
        primitiveUnits="objectBoundingBox"
        x="0"
        y="0"
        width="100%"
        height="100%"
      >
        {/* contrast */}
        <feComponentTransfer>
          <feFuncR type="linear" slope="1.1" intercept="-0.05" />
          <feFuncG type="linear" slope="1.1" intercept="-0.05" />
          <feFuncB type="linear" slope="1.1" intercept="-0.05" />
        </feComponentTransfer>
        {/* brightness */}
        <feComponentTransfer>
          <feFuncR type="linear" slope="1.1" />
          <feFuncG type="linear" slope="1.1" />
          <feFuncB type="linear" slope="1.1" />
        </feComponentTransfer>
        {/* saturate */}
        <feColorMatrix in="SourceGraphic" type="saturate" values="1.3" />
        <feFlood floodColor="rgb(248,106,188)" floodOpacity=".3" />
        <feBlend mode="screen" in="SourceGraphic" />
        <BrightnessAndContrast
          brightness={brightness}
          contrastSlope={contrastSlope}
          contrastIntercept={contrastIntercept}
        />
      </filter>
    </defs>
  );
}

export default PinkMood;
