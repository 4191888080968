import React from 'react';
import styles from './Modal.module.css';
// components
import Portal from '../Portal/Portal';

function Alert({ justClose, onClose, comment, number }) {

//   language 설정
//   let mention1 = "확인";
//   if(language == 'en') {
//     mention1 = "OK";
//   }

    function Decide() {
        if(number === 1) {
            onClose();
        }
        else{
            justClose();
        }
    }


  return (
      <Portal elementId="alert-root">
          <div className={styles.Modal}>
            <div className={styles.content}>
                <div className={styles.letter}>{comment}</div>
                <button onClick={Decide}>확인</button>
            </div>
          </div>

      </Portal>
  );
}


export default Alert;
