import React from 'react';

// components
import BrightnessAndContrast from './BrightnessAndContrast';

const Falling = ({ id, brightness, contrastSlope, contrastIntercept }) => {
  return (
    <defs>
      {/* <!-- INSTAGRAM WILLOW FILTER --> */}
      <radialGradient id="gradient_willow" cx="40%">
        <stop offset="55%" stopColor="#d4a9af" stopOpacity="0%" />
        <stop offset="150%" stopColor="black" stopOpacity="0%" />
      </radialGradient>

      {/* <!-- Create a rectangle and apply the gradient as its fill --> */}
      <rect
        id="willowsc"
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill="url(#gradient_willow)"
        fillOpacity="1"
      />

      <filter
        id={`filter-image-${id}`}
        colorInterpolationFilters="sRGB"
        filterUnits="objectBoundingBox"
        primitiveUnits="objectBoundingBox"
        x="0"
        y="0"
        width="100%"
        height="100%"
      >
        {/* <!-- grayscale 0.5 --> */}
        <feColorMatrix
          type="matrix"
          values="0,6063 0.3576 0.0361 0 0
                                   0.1063 0.8576 0.0361 0 0
                                   0.1063 0.3576 0.5361 0 0
                                   0 0 0 1 0"
        />

        {/* <!-- contrast --> */}
        <feComponentTransfer>
          <feFuncR type="linear" slope="0.95" intercept="0.025" />
          <feFuncG type="linear" slope="0.95" intercept="0.025" />
          <feFuncB type="linear" slope="0.95" intercept="0.025" />
        </feComponentTransfer>
        {/* <!-- brightness --> */}
        <feComponentTransfer result="gcb">
          <feFuncR type="linear" slope="0.9" />
          <feFuncG type="linear" slope="0.9" />
          <feFuncB type="linear" slope="0.9" />
        </feComponentTransfer>
        {/* <!-- blend mode --> */}
        <feImage xlinkHref="#willowsc" result="grad" x="0" y="0" />
        <feBlend mode="overlay" in2="gcb" result="gcbblend" />
        <feFlood floodColor="#d8cdcb" floodOpacity="1" />
        <feBlend mode="color" in2="gcbblend" />
        <BrightnessAndContrast
          brightness={brightness}
          contrastSlope={contrastSlope}
          contrastIntercept={contrastIntercept}
        />
      </filter>
    </defs>
  );
};

export default Falling;
