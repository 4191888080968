import { useReducer, useCallback } from 'react';

const initState = {
  seletedPage: 0,
  pageData: [{ img: '', stackData: { past: [], present: [], future: [] } }],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PAGE_DATA': {
      const { stackData } = action;

      return {
        ...state,
        pageData: state.pageData.map((page, idx) =>
          idx === state.seletedPage ? { ...page, stackData } : page,
        ),
      };
    }
    case 'SET_PAGE_IMG': {
      const { imgData } = action;

      return {
        ...state,
        pageData: state.pageData.map((page, idx) =>
          idx === state.seletedPage ? { ...page, img: imgData } : page,
        ),
      };
    }
    case 'CHANGE_PAGE': {
      const { idx } = action;

      return { ...state, seletedPage: idx };
    }
    case 'ADD_PAGE': {
      return {
        seletedPage: state.pageData.length,
        pageData: state.pageData.concat(...initState.pageData),
      };
    }
    case 'REMOVE_PAGE_DATA': {
      return {
        ...state,
        seletedPage: 0,
        pageData: state.pageData.filter(
          (stackArr, arrIdx) => arrIdx !== state.seletedPage,
        ),
      };
    }
    case 'INIT_PAGE': {
      return { ...initState };
    }
    case 'SET_ALL_PAGE': {
      const { pageData } = action;

      return { ...initState, pageData };
    }

    default:
      return console.log('정의되지 않은 타입입니다.');
  }
};

const usePage = () => {
  const [state, dispatch] = useReducer(reducer, initState);

  const setPagedata = useCallback((stackData) => {
    dispatch({ type: 'SET_PAGE_DATA', stackData });
  }, []);

  const setPageImg = useCallback((imgData) => {
    dispatch({ type: 'SET_PAGE_IMG', imgData });
  }, []);

  const chagePage = useCallback((idx) => {
    dispatch({ type: 'CHANGE_PAGE', idx });
  }, []);

  const addPage = useCallback(() => {
    dispatch({ type: 'ADD_PAGE' });
  }, []);

  const removePageData = useCallback(() => {
    dispatch({ type: 'REMOVE_PAGE_DATA' });
  }, []);

  const initPage = useCallback(() => {
    dispatch({ type: 'INIT_PAGE' });
  }, []);

  const setAllPage = useCallback((pageData) => {
    dispatch({ type: 'SET_ALL_PAGE', pageData });
  }, []);

  return [
    state,
    {
      setPagedata,
      setPageImg,
      chagePage,
      addPage,
      removePageData,
      initPage,
      setAllPage,
    },
  ];
};

export default usePage;
