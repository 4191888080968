import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import get from 'lodash/get';
import moment from 'moment';

// constants
import { bgColors, bgRatio } from '../../../shared/constants/bgData';

// components
import ColorLists from './ColorLists';
import RatioLists from './RatioLists';

// utills
import { getFileImgInfo } from '../../../shared/utills/getFileImgInfo';

const BackgroundArea = (props) => {
  const {
    setCurrentTabDefault,
    addHistoryStateHandler,
    elIdx,
    bgEditState,
    setBgColor,
    setBgRatio,
    setBgPhoto,
    setBgEnd,
    setBgCancel,
    language,
  } = props;
  const prefix = 'background';

  const inputFileRef = useRef(null);

  const onClickColor = (evt) => {
    setBgColor(evt.target.dataset.color);
  };

  const onClickRatio = (evt) => {
    setBgRatio(evt.currentTarget.dataset.ratio);
  };

  const onClickPhotoAdd = () => {
    inputFileRef.current.click();
  };

  const onClickPhotoDel = () => {
    setBgPhoto(null);
  };

  const addImageEl = (evt) => {
    getFileImgInfo(evt).then((imgLoad) => {
      imgLoad().then((imgInfo) => {
        const { src } = imgInfo;

        setBgPhoto(src);
      });
    });
  };

  const onClickBtnCancel = () => {
    setBgCancel();
    setCurrentTabDefault();
  };

  const onClickBtnComplete = () => {
    addHistoryStateHandler({
      id: `${moment().format('YYYY-DD-MM-HH-mm-ss')}_${elIdx.current}`,
      type: prefix,
      elInfo: {
        color: bgEditState.color,
        ratio: bgEditState.ratio,
        src: bgEditState.src,
      },
      transformInfo: null,
      keepRatio: null,
      isEditable: null,
      canModify: false,
    });

    // 배경 적용
    setBgEnd(bgEditState);

    // index 중가
    elIdx.current += 1;

    // 하단 컨트롤 영역 닫기
    setCurrentTabDefault();
  };

  // 페이지 정보 (번역정보)
  const pageInfo = {
    titles: {
      bgColor: {
        ko: '배경색상',
        en: 'Background Color',
      },
      bgRatio: {
        ko: '배경 비율',
        en: 'Ratio',
      },
      bgPhoto: {
        ko: '사진/배경',
        en: 'Background Photo',
      },
    },
    btns: {
      btnAlbum: {
        text: { ko: '+ 앨범에서 불러오기', en: '+ Import from Album' },
      },
      btnDelBgPhoto: {
        text: { ko: 'X 배경사진 삭제하기', en: 'X Remove Photo' },
      },
      btnApply: {
        text: { ko: '적용완료', en: 'Apply' },
      },
      btnCancel: {
        text: { ko: '적용취소', en: 'Cancel' },
      },
    },
  };

  return (
    <BackgroundAreaWrap>
      <List>
        <ListTitle>{pageInfo.titles.bgColor[language]}</ListTitle>
        <ColorLists
          colors={bgColors}
          selectedColor={get(bgEditState, 'color') || bgColors[0]}
          onClickColor={onClickColor}
        />
      </List>
      <List row={true}>
        <ListTitle>{pageInfo.titles.bgRatio[language]}</ListTitle>
        <RatioLists
          ratios={bgRatio}
          selectedRatio={get(bgEditState, ['ratio', 'seletedRatio'])}
          onClickRatio={onClickRatio}
          language={language}
        />
      </List>
      <List row={true}>
        <ListTitle>{pageInfo.titles.bgPhoto[language]}</ListTitle>
        <BtnText onClick={onClickPhotoAdd}>
          {pageInfo.btns.btnAlbum.text[language]}
        </BtnText>
        <BtnText onClick={onClickPhotoDel}>
          {pageInfo.btns.btnDelBgPhoto.text[language]}
        </BtnText>
      </List>
      <InputPhoto
        type="file"
        accept="image/*"
        ref={inputFileRef}
        onChange={addImageEl}
      />
      <BtnGroup>
        <BtnRound type={'cancel'} onClick={onClickBtnCancel}>
          {pageInfo.btns.btnCancel.text[language]}
        </BtnRound>
        <BtnRound type={'ok'} onClick={onClickBtnComplete}>
          {pageInfo.btns.btnApply.text[language]}
        </BtnRound>
      </BtnGroup>
    </BackgroundAreaWrap>
  );
};

const BackgroundAreaWrap = styled.div`
  padding: 20px 30px;
`;
const List = styled.div`
  margin-bottom: 20px;
  ${({ row }) => {
    if (row) {
      return css`
        display: flex;
        align-items: flex-start;
      `;
    }
  }}
`;
const ListTitle = styled.h3`
  margin-right: 20px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors['white']};
`;
const BtnGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`;
const BtnRound = styled.button`
  padding: 6px 17px;
  margin: 0 5px;
  border-radius: 12px;
  background-color: ${({ type, theme }) =>
    type === 'cancel'
      ? theme.colors['brownish-grey']
      : theme.colors['pinkish-tan']};
  font-size: 12px;
  color: ${({ theme }) => theme.colors['white']};
`;
const BtnText = styled.button`
  margin-right: 15px;
  padding: 0;
  font-size: 12px;
  color: ${({ theme }) => theme.colors['white']};
`;
const InputPhoto = styled.input`
  display: none;
`;

export default BackgroundArea;
