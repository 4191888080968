import { useState, useEffect } from 'react';

const useWindowSize = () => {
  const isClient =
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement;

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    window.addEventListener('keyboardDidHide', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('keyboardDidHide', handleResize)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowSize;
};

export default useWindowSize;
