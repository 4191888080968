import { useReducer, useCallback } from 'react';

const initState = {
  id: null,
  src: null,
  width: null,
  height: null,
  transformInfo: null,
  reversals: { vertical: false, horizontal: false },
  filter: 'none',
  brightness: 0,
  contrast: 0,
  cropSrc: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PHOTO_INFO':
      const { photoInfo } = action;

      return { ...initState, ...photoInfo };

    case 'CHANGE_CROP_PHOTO':
      const { cropSrc } = action;

      return { ...state, cropSrc };

    case 'CHANGE_REVERSAL':
      const { newReversals } = action;

      return { ...state, reversals: newReversals };
    case 'CHANGE_FILTER':
      const { newFilter } = action;

      return { ...state, filter: newFilter };

    case 'CHANGE_BRIGHTNESS':
      const { newBrightness } = action;

      return { ...state, brightness: newBrightness };

    case 'CHANGE_CONTRAST':
      const { newContrast } = action;

      return { ...state, contrast: newContrast };
    case 'PHOTO_EDIT_CANCEL':
      const { key, data } = action;

      return { ...state, [key]: data };
    case 'SET_INIT_PHOTO_INFO':
      return { ...initState };

    default:
      return console.log('정의되지 않은 타입입니다.');
  }
};

const usePhotoEdit = () => {
  const [state, dispatch] = useReducer(reducer, initState);

  const setPhotoInfo = useCallback((photoInfo) => {
    dispatch({ type: 'SET_PHOTO_INFO', photoInfo });
  }, []);

  const changeCropPhoto = useCallback((cropSrc) => {
    dispatch({ type: 'CHANGE_CROP_PHOTO', cropSrc });
  }, []);

  const changeReversal = useCallback(
    (type) => {
      const newReversals = {
        ...state.reversals,
        [type]: !state.reversals[type],
      };

      dispatch({ type: 'CHANGE_REVERSAL', newReversals });
    },
    [state],
  );

  const changeFilter = useCallback((newFilter) => {
    dispatch({ type: 'CHANGE_FILTER', newFilter });
  }, []);

  const changeBrightness = useCallback((newBrightness) => {
    dispatch({ type: 'CHANGE_BRIGHTNESS', newBrightness });
  }, []);

  const changeContrast = useCallback((newContrast) => {
    dispatch({ type: 'CHANGE_CONTRAST', newContrast });
  }, []);

  const photoEditCancel = useCallback((key, data) => {
    dispatch({ type: 'PHOTO_EDIT_CANCEL', key, data });
  }, []);

  const setInitPhotoInfo = useCallback(() => {
    dispatch({ type: 'SET_INIT_PHOTO_INFO' });
  }, []);

  return [
    state,
    {
      setPhotoInfo,
      changeCropPhoto,
      changeReversal,
      changeFilter,
      changeBrightness,
      changeContrast,
      photoEditCancel,
      setInitPhotoInfo,
    },
  ];
};

export default usePhotoEdit;
