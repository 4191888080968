export const fontColors = [
  '#000000',
  '#ffffff',
  '#ae745f',
  '#e89b7f',
  '#f3c7ac',
  '#f0dfcb',
  '#a9c4bb',
  '#98996d',
  '#c1c2a7',
  '#dddddd',
  '#cb2623',
  '#d97635',
  '#efd678',
  '#6aa048',
  '#3d68a8',
  '#682d7c',
  '#d69dba',
];

export const fontFamilys = [
  { title: '기본고딕', fontName: 'sans-serif' },
  { title: '나눔스퀘어', fontName: 'NanumSquare' },
  { title: '나눔명조', fontName: 'Nanum Myeongjo' },
  { title: '카페24동동', fontName: 'Cafe24Dongdong' },
  { title: '카페24고운밤', fontName: 'Cafe24Oneprettynight' },
  { title: '이롭게바탕체', fontName: 'Iropke Batang' },
  { title: '나눔손글씨붓', fontName: 'Nanum Brush Script' },
  { title: '검은고딕', fontName: 'Black Han Sans' },
  { title: '카페24 아네모네', fontName: 'Cafe24Ohsquare' },
];
