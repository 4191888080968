export const categoryData = [
  {
    name: 'photoBook',
    text: { ko: '포토북', en: 'Photo Book' },
  },
  {
    name: 'congrat',
    text: { ko: '축하카드', en: 'Greeting Card' },
  },
  {
    name: 'invitation',
    text: { ko: '초대장', en: 'Invitation Card' },
  },
  {
    name: 'leaflet',
    text: { ko: '전단지', en: 'Leaflet' },
  },
  {
    name: 'poster',
    text: { ko: '포스터', en: 'Poster' },
  },
];
