import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

const tabItems = [
  { name: 'photo', img: 'iconPic', text: { ko: '사진추가', en: 'Add Photo', vi: 'Thêm ảnh', ja: '写真を追加', ch: '添加照片', sp: 'Agregar una foto' } },
  { name: 'write', img: 'iconWriting', text: { ko: '글쓰기', en: 'Write', vi: 'Viết', ja: 'テキスト', ch: '写作', sp: 'Escritura' } },
  {
    name: 'background',
    img: 'background',
    text: { ko: '배경', en: 'Background', vi: 'Nền', ja: '背景', ch: '背景', sp: 'Un fondo'},
  },
  {
    name: 'sticker',
    img: 'iconSticker',
    text: { ko: '스티커', en: 'Stickers', vi: 'Nhãn dán', ja: 'シール', ch: '贴纸', sp: 'Una pegatina' },
  },
  { name: 'page', img: 'iconPage', text: { ko: '페이지', en: 'Page', vi: 'Trang', ja: 'ページ', ch: '页码', sp: 'Una página' } },
];

const btnTempleteinfo = { text: { ko: '템플릿', en: 'Templete', vi: 'Template', ja: 'テンプレート', ch: '模板', sp: 'Una plantilla' } };

function BottomTabBar(props) {
  const {
    currentTab,
    setCurrentTab,
    setIsIntro,
    completeBgChangeHandler,
    setIsEditOpen,
    setIsTempleteSelectOpen,
    isTempleteSelectOpen,
    setTempelte,
  } = props;
  const location = useLocation();
  const { language } = location.state;

  const onClickBtnTabBar = (evt) => {
    if (currentTab === 'background') {
      completeBgChangeHandler();
    }
    setCurrentTab(evt.currentTarget.dataset.name);
    setIsIntro(false);
    setIsEditOpen(true);
    if (isTempleteSelectOpen) {
      setIsTempleteSelectOpen(false);
      setTempelte();
    }
  };

  const onClickTempleteBtn = () => {
    setCurrentTab('default');
    setIsTempleteSelectOpen(true);
  };

  return (
    <BottomTabBarWrap>
      <BtnTemplate onClick={onClickTempleteBtn}>
        <BtnIcon src={`./img/plus.svg`} alt="" />
        <BtnText>{btnTempleteinfo.text[language]}</BtnText>
      </BtnTemplate>
      <BtnTabWrap>
        {tabItems.map((tabItem) => {
          const { name, img, text } = tabItem;
          const imgUrl =
            currentTab === name
              ? `./img/${img}_active.svg`
              : `./img/${img}.svg`;

          return (
            <BtnTabBar onClick={onClickBtnTabBar} key={name} data-name={name}>
              <BtnIcon src={imgUrl} alt="" />
              <BtnText active={currentTab === name}>{text[language]}</BtnText>
            </BtnTabBar>
          );
        })}
      </BtnTabWrap>
    </BottomTabBarWrap>
  );
}

const BottomTabBarWrap = styled.div`
  position: relative;
  z-index: 15000;
  display: flex;
  height: 65px;
  margin-top: auto;
  padding: 0;
  background: ${({ theme }) => theme.colors['black']};
`;
const BtnTemplate = styled.button`
  width: 54px;
  height: 100%;
  background: ${({ theme }) => theme.colors['pinkish-tan']};
  height: 100%;
  flex-direction: column;
`;
const BtnTabWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  > * {
    height: 100%;
    flex-direction: column;
  }
`;
const BtnTabBar = styled.button``;
const BtnIcon = styled.img``;
const BtnText = styled.p`
  margin-top: 3px;
  font-size: 8px;
  color: ${({ theme, active }) =>
    active ? theme.colors['pale-salmon'] : theme.colors['white']};
`;

export default BottomTabBar;
