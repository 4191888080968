const colors = {
  white: '#ffffff',
  black: '#000000',
  'pinkish-brown': '#ae745f',
  'brownish-grey': '#6f6f6f',
  'pinkish-tan': '#e89b7f',
  'pale-salmon': '#f3c7ac',
  'light-peach': '#f0dfcb',
  'light-teal': '#a9c4bb',
  khaki: '#98996d',
  'very-light-brown': '#c1c2a7',
  'very-light-pink': '#dddddd',
  'very-light-pink2': '#b7b7b7',
};

const theme = {
  colors,
};

export default theme;
