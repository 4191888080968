import { useReducer, useCallback } from 'react';

const initState = {
  captureInnerW: '100%',
  captureInnerH: '100%',
  captureRatio: 1,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_CAPTURE_INNER': {
      const { captureInnerW, captureInnerH, captureRatio } = action;

      return {
        captureInnerW,
        captureInnerH,
        captureRatio,
      };
    }
    case 'INIT_CAPTURE_INNER': {
      return {
        ...initState,
      };
    }
    default:
      return console.log('정의되지 않은 타입입니다.');
  }
};

const useTemplate = () => {
  const [state, dispatch] = useReducer(reducer, initState);

  const changeCapureInner = useCallback(
    (captureInnerW, captureInnerH, captureRatio) => {
      dispatch({
        type: 'CHANGE_CAPTURE_INNER',
        captureInnerW,
        captureInnerH,
        captureRatio,
      });
    },
    [],
  );

  const initCapureInner = useCallback(() => {
    dispatch({ type: 'INIT_CAPTURE_INNER' });
  }, []);

  return [state, { changeCapureInner, initCapureInner }];
};

export default useTemplate;
