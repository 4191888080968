const getRatioPadding = (ratio, width, height) => {
  let paddings = {
    pX: 0,
    pY: 0,
  };

  const sizes = {
    ratio: {
      11: [1, 1],
      34: [3, 4],
      43: [4, 3],
      45: [4, 5],
      1.911: [1.91, 1],
      916: [9, 16],
      169: [16, 9],
      58: [5, 8],
      23: [2, 3],
      32: [3, 2],
    },
    px: {
      '1200x628': [1200, 628],
      '1500x500': [1500, 500],
      '1546x423': [1546, 423],
      '2500x423': [2500, 423],
      // 각 용지별 pixel사이즈
      // https://m.blog.naver.com/buvi79/221757518316
      a4h: [2480, 3508],
      a4w: [3508, 2480],
      a5h: [1748, 2480],
      a5w: [2480, 1748],
      a3h: [3508, 4960],
      a3w: [4960, 3508],
    },
  };

  const caculateByRatio = (w, h) => {
    paddings.pX = (width * h) / w < height ? 0 : (width - (height * w) / h) / 2;
    paddings.pY = (width * h) / w < height ? (height - (width * h) / w) / 2 : 0;
  };

  const caculateByPixel = (w, h) => {
    paddings.pX = (width - w) / 2;
    paddings.pY = (height - h) / 2;
  };

  if (ratio !== 'default') {
    const [w, h] = sizes.ratio[ratio] || sizes.px[ratio];

    if (sizes.ratio[ratio]) {
      caculateByRatio(w, h);
    } else {
      if (width > w && height > h) {
        caculateByPixel(w, h);
      } else {
        caculateByRatio(w, h);
      }
    }
  }

  return paddings;
};

export default getRatioPadding;
