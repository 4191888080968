import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styles from './List.module.css';

import PageLayout from '../../shared/components/PageLayout/PageLayout';

function List(props) {
  let history = useHistory();

  const language = props.location.state.language;
  let title;
  let mention1;
  let mention2;
  let mention3;
  let mention4;
  let mention5;

  if (language === 'ko') {
    title = '예시작품';
    mention1 = '포토북';
    mention2 = '축하카드';
    mention3 = '초대장';
    mention4 = '전단지';
    mention5 = '포스터';
  } else if (language === 'en') {
    title = 'Sample';
    mention1 = 'Photo Book';
    mention2 = 'Greeting Card';
    mention3 = 'Invitation Card';
    mention4 = 'Leaflet';
    mention5 = 'Poster';
  } else if (language === 'vi') {
    title = 'Ví dụ công việc';
    mention1 = 'Photo Book';
    mention2 = 'Thiệp chúc mừng';
    mention3 = 'Thư mời';
    mention4 = 'Tờ rơi';
    mention5 = 'Poster';
  } else if (language === 'ja') {
    title = 'おすすめの作品例';
    mention1 = 'フォトブック';
    mention2 = 'お祝いカード';
    mention3 = '招待状';
    mention4 = 'チラシ';
    mention5 = 'ポスター';
  } else if (language === 'ch') {
    title = '示范作品';
    mention1 = '写真集';
    mention2 = 'お祝いカード';
    mention3 = '招待状';
    mention4 = 'チラシ';
    mention5 = 'ポスター';
  } else if (language === 'sp') {
    title = 'Un ejemplo de trabajo';
    mention1 = 'Un libro de fotos';
    mention2 = 'Una tarjeta de felicitación';
    mention3 = 'Una invitación';
    mention4 = 'Un folleto';
    mention5 = 'Un póster';
  }

  return (
    <PageLayout iosFullScroll={true}>
      <div>
        <div className={styles.title}>
          <img src="./img/home2.png" alt="" onClick={() => history.goBack()} />
          {title}
        </div>
        <div className={styles.listWrap}>
          <Link
            to={{
              pathname: '/sample',
              state: { language: language, genre: 'photoBook' },
            }}
          >
            <div className={styles.list}>{mention1}</div>
          </Link>
          <Link
            to={{
              pathname: '/sample',
              state: { language: language, genre: 'congrat' },
            }}
          >
            <div className={styles.list}>{mention2}</div>
          </Link>
          <Link
            to={{
              pathname: '/sample',
              state: { language: language, genre: 'invitation' },
            }}
          >
            <div className={styles.list}>{mention3}</div>
          </Link>
          <Link
            to={{
              pathname: '/sample',
              state: { language: language, genre: 'leaflet' },
            }}
          >
            <div className={styles.list}>{mention4}</div>
          </Link>
          <Link
            to={{
              pathname: '/sample',
              state: { language: language, genre: 'poster' },
            }}
          >
            <div className={styles.list}>{mention5}</div>
          </Link>
        </div>
      </div>
    </PageLayout>
  );
}

export default List;
