import React from 'react';
import styled from 'styled-components';

// components
import Portal from '../../../shared/components/Portal/Portal';
import { SwishSpinner } from 'react-spinners-kit';

function LoadingOverlay() {
  return (
    <Portal elementId={'loding-root'}>
      <LoadingOverlayWrap>
        <SwishSpinner frontColor="#ddd" backColor="#fff" />
      </LoadingOverlayWrap>
    </Portal>
  );
}

const LoadingOverlayWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 50000;
`;

export default LoadingOverlay;
