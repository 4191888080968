import { useReducer, useCallback } from 'react';

// constants
import { fontColors, fontFamilys } from '../constants/fontData';

const initState = {
  id: null,
  transformInfo: null,
  text: '',
  textAlign: 'left',
  fontColor: fontColors[0],
  fontFamily: fontFamilys[0].fontName,
  fontSize: 20,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_ALIGN':
      const { newAlign } = action;

      return { ...state, textAlign: newAlign };
    case 'CHANGE_COLOR':
      const { newColor } = action;

      return { ...state, fontColor: newColor };
    case 'CHANGE_FONTFAMILY':
      const { newFontFamily } = action;

      return { ...state, fontFamily: newFontFamily };
    case 'CHANGE_FONTSIZE':
      const { newFontSize } = action;

      return { ...state, fontSize: newFontSize };
    case 'SET_FONT_INFO':
      const { fontInfo } = action;

      return { ...fontInfo };
    case 'SET_INIT_FONT_INFO':
      return { ...initState };

    default:
      return console.log('정의되지 않은 타입입니다.');
  }
};

const useTextEdit = () => {
  const [state, dispatch] = useReducer(reducer, initState);

  const changeAlign = useCallback((newAlign) => {
    dispatch({ type: 'CHANGE_ALIGN', newAlign });
  }, []);

  const changeTextColor = useCallback((newColor) => {
    dispatch({ type: 'CHANGE_COLOR', newColor });
  }, []);

  const changeFontFamily = useCallback((newFontFamily) => {
    dispatch({ type: 'CHANGE_FONTFAMILY', newFontFamily });
  }, []);

  const changeFontSize = useCallback((newFontSize) => {
    dispatch({ type: 'CHANGE_FONTSIZE', newFontSize });
  }, []);

  const setFontInfo = useCallback((fontInfo) => {
    dispatch({ type: 'SET_FONT_INFO', fontInfo });
  }, []);

  const setInitFontInfo = useCallback(() => {
    dispatch({ type: 'SET_INIT_FONT_INFO' });
  }, []);

  return [
    state,
    {
      changeAlign,
      changeTextColor,
      changeFontFamily,
      changeFontSize,
      setFontInfo,
      setInitFontInfo,
    },
  ];
};

export default useTextEdit;
