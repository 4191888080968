// utills
import * as loadImage from 'blueimp-load-image';
import imageCompression from 'browser-image-compression';

// 이미지 사이즈 디바이스에 맞춰 적절하게 조정
const getImgZipSize = (imgW, imgH) => {
  const zipRatio = 1.5;
  const screenZipW = window.innerWidth / zipRatio;
  const parentZipH = (window.innerHeight - 150) / zipRatio;
  let size = { imgWidth: 0, imgHeight: 0 };

  if (imgW > imgH) {
    if (screenZipW > imgW) {
      size.imgWidth = imgW;
      size.imgHeight = imgH;
    } else {
      size.imgWidth = screenZipW;
      size.imgHeight = Math.round((screenZipW * imgH) / imgW);
    }
  } else {
    if (parentZipH > imgH) {
      size.imgWidth = imgW;
      size.imgHeight = imgH;
    } else {
      size.imgWidth = Math.round((parentZipH * imgW) / imgH);
      size.imgHeight = parentZipH;
    }
  }

  return size;
};

const compressImg = (file) => {
  const compressOptions = {
    maxSizeMB: 2,
    useWebWorker: true,
  };

  return imageCompression(file, compressOptions);
};

const getFileImgInfo = (evt) => {
  let file = evt.target.files[0];
  let imageType = /image.*/;

  if (typeof FileReader !== 'undefined' && file.type.match(imageType)) {
    return new Promise((resolve, reject) => {
      // 이미지 압축 코드
      // compressImg(file).then((compressedFile) => {
      //   let reader = new FileReader();

      //   reader.readAsDataURL(compressedFile);

      //   reader.onload = () => {
      //     resolve(() => {
      //       return new Promise((resolve) => {
      //         let image = new Image();

      //         loadImage(reader.result, { orientation: true })
      //           .then((data) => {
      //             image.src = data.image.src || data.image.toDataURL();
      //           })
      //           .catch((err) => {
      //             console.log(err);
      //           });
      //         image.onload = () => {
      //           const { imgWidth, imgHeight } = getImgZipSize(
      //             image.width,
      //             image.height,
      //           );
      //           resolve({
      //             src: image.src,
      //             width: imgWidth,
      //             height: imgHeight,
      //           });
      //         };
      //       });
      //     });
      //   };
      // });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(() => {
          return new Promise((resolve) => {
            let image = new Image();

            loadImage(reader.result, { orientation: true })
              .then((data) => {
                image.src = data.image.src || data.image.toDataURL();
              })
              .catch((err) => {
                console.log(err);
              });
            image.onload = () => {
              const { imgWidth, imgHeight } = getImgZipSize(
                image.width,
                image.height,
              );
              resolve({
                src: image.src,
                width: imgWidth,
                height: imgHeight,
              });
            };
          });
        });
      };
    });
  }
};

export { getFileImgInfo };
