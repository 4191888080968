import React from 'react';
import { Link } from 'react-router-dom';
import styles from './IntroEn.module.css';

function IntroEn() {
  return (
    <div className={styles.mainWrap}>
      <div className={styles.logo}>
        <img src="./img/wecume1.png" alt="" />
        <div className={styles.title}>Create Your Own Album</div>
      </div>
      <div className={styles.body}>
        <img src="./img/phone.png" alt="" />
      </div>
      <div className={styles.footer}>
        <Link to={{ pathname: '/edit', state: { language: 'en' } }}>
          <div className={styles.line1}>Edit</div>
        </Link>
        <Link to={{ pathname: '/list', state: { language: 'en' } }}>
          <div className={styles.line2}>Sample</div>
        </Link>
        <Link to={{ pathname: '/collection', state: { language: 'en' } }}>
          <div className={styles.line3}>My Collection</div>
        </Link>
      </div>
    </div>
  );
}

export default IntroEn;
