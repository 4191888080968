import React from 'react';

// components
import BrightnessAndContrast from './BrightnessAndContrast';

const Grape = ({ id, brightness, contrastSlope, contrastIntercept }) => {
  return (
    <defs>
      {/* <!-- CSSgram Instagram Amaro filter --> */}
      <radialGradient id="gradient_amaro" cx="29%" cy="33%">
        <stop
          offset="0%"
          stopColor="rgba(85, 78, 136, 0.28)"
          stopOpacity="100%"
        />
        <stop offset="50%" stopColor="rgb(72, 17, 76)" stopOpacity="100%" />
        <stop offset="97%" stopColor="#540d80" stopOpacity="100%" />
      </radialGradient>
      {/* <!-- screen on filtered image --> */}
      <rect
        id="amarosc"
        x="0"
        y="0"
        width="100%"
        height="100%"
        fillOpacity=".4"
        fill="url(#gradient_amaro)"
      />
      <filter
        id={`filter-image-${id}`}
        colorInterpolationFilters="sRGB"
        filterUnits="objectBoundingBox"
        primitiveUnits="objectBoundingBox"
        x="0"
        y="0"
        width="100%"
        height="100%"
      >
        {/* <!--hue-rotate --> */}
        <feColorMatrix type="hueRotate" values="-2" />
        {/* <!-- brightness --> */}
        <feComponentTransfer>
          <feFuncR type="linear" slope="0.9" />
          <feFuncG type="linear" slope="0.9" />
          <feFuncB type="linear" slope="0.9" />
        </feComponentTransfer>
        {/* <!-- saturate --> */}
        <feColorMatrix result="hcbs" type="saturate" values="1.1" />
        <feImage xlinkHref="#amarosc" result="grad" x="0" y="0" />
        <feBlend mode="screen" in="hcbs" />
        <BrightnessAndContrast
          brightness={brightness}
          contrastSlope={contrastSlope}
          contrastIntercept={contrastIntercept}
        />
      </filter>
    </defs>
  );
};

export default Grape;
