export const bgColors = [
  '#ffffff',
  '#000000',
  '#ae745f',
  '#e89b7f',
  '#f3c7ac',
  '#f0dfcb',
  '#a9c4bb',
  '#98996d',
  '#c1c2a7',
  '#dddddd',
  '#cb2623',
  '#d97635',
  '#efd678',
  '#6aa048',
  '#3d68a8',
  '#682d7c',
  '#d69dba',
];

export const bgRatio = [
  { name: 'default', img: { ko: 'ratio-default', en: 'ratio-default-en' } },
  { name: '11', img: { ko: 'ratio-1-1', en: 'ratio-1-1' } },
  { name: '34', img: { ko: 'ratio-3-4', en: 'ratio-3-4' } },
  { name: '43', img: { ko: 'ratio-4-3', en: 'ratio-4-3' } },
  { name: '45', img: { ko: 'ratio-4-5', en: 'ratio-4-5' } },
  { name: '1.911', img: { ko: 'ratio-1.91-1', en: 'ratio-1.91-1' } },
  { name: '916', img: { ko: 'ratio-9-16', en: 'ratio-9-16' } },
  { name: '169', img: { ko: 'ratio-16-9', en: 'ratio-16-9' } },
  { name: '58', img: { ko: 'ratio-5-8', en: 'ratio-5-8' } },
  { name: '23', img: { ko: 'ratio-2-3', en: 'ratio-2-3' } },
  { name: '32', img: { ko: 'ratio-3-2', en: 'ratio-3-2' } },
  { name: '1200x628', img: { ko: 'ratio-1200x628', en: 'ratio-1200x628' } },
  { name: '1500x500', img: { ko: 'ratio-1500x500', en: 'ratio-1500x500' } },
  { name: '1546x423', img: { ko: 'ratio-1546x423', en: 'ratio-1546x423' } },
  { name: '2500x423', img: { ko: 'ratio-2560x423', en: 'ratio-2560x423' } },
  { name: 'a4h', img: { ko: 'ratio-a4-h', en: 'ratio-a4-h-en' } },
  { name: 'a4w', img: { ko: 'ratio-a4-w', en: 'ratio-a4-w-en' } },
  { name: 'a5h', img: { ko: 'ratio-a5-h', en: 'ratio-a5-h-en' } },
  { name: 'a5w', img: { ko: 'ratio-a5-w', en: 'ratio-a5-w-en' } },
  { name: 'a3h', img: { ko: 'ratio-a3-h', en: 'ratio-a3-h-en' } },
  { name: 'a3w', img: { ko: 'ratio-a3-w', en: 'ratio-a3-w-en' } },
];

export const bgInitState = {
  editedColor: bgColors[0],
  editedRatio: {
    seletedRatio: 'default',
    width: 0,
    height: 0,
    default: { pX: 0, pY: 0 },
  },
  editedSrc: null,
  color: bgColors[0],
  ratio: {
    seletedRatio: 'default',
    width: 0,
    height: 0,
    default: { pX: 0, pY: 0 },
  },
  src: null,
};
