import React from 'react';

// components
import BrightnessAndContrast from './BrightnessAndContrast';

const Rainbow = ({ id, brightness, contrastSlope, contrastIntercept }) => {
  return (
    <defs>
      {/* <!-- INSTAGRAM XPRO2 FILTER --> */}
      <radialGradient id="gradient_xpro">
        <stop offset="40%" stopColor="#e6e7e0" stopOpacity="100%" />
        <stop offset="110%" stopColor="rgb(43,42,161)" stopOpacity="60%" />
      </radialGradient>

      {/* <!-- Create a rectangle and apply the gradient as its fill --> */}
      <rect
        id="xprosc"
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill="url(#gradient_xpro)"
        fillOpacity="0.3"
      />

      <filter id={`filter-image-${id}`} colorInterpolationFilters="sRGB">
        {/* <!-- sepia 0.3 --> */}
        <feColorMatrix
          result="sepia"
          type="matrix"
          values="
                0.8179 0.2307 0.0567 0 0
                0.1047 0.9058 0.0504 0 0
                0.0816 0.1602 0.7393 0 0
                0 0 0 1 0"
        />
        {/* <!-- blend mode --> */}
        <feImage xlinkHref="#xprosc" result="grad" x="0" y="0" />
        <feBlend mode="color-burn" in="sepia" />
        <BrightnessAndContrast
          brightness={brightness}
          contrastSlope={contrastSlope}
          contrastIntercept={contrastIntercept}
        />
      </filter>
    </defs>
  );
};

export default Rainbow;
