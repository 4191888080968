import { useState } from 'react';
import html2canvas from 'html2canvas';
import * as loadImage from 'blueimp-load-image';

const useScreenshot = () => {
  const [error, setError] = useState(null);

  const takeScreenShot = (node, scale) => {
    if (!node) {
      throw new Error('You should provide correct html node.');
    }
    return html2canvas(node, {
      logging: true,
      scrollX: 0,
      scrollY: -window.scrollY,
      useCORS: true,
      allowTaint: true,
      scale: scale || window.devicePixelRatio,
      imageTimeout: 30000,
      ignoreElements: (node) => {
        const ignore =
          node.nodeName === 'IFRAME' ||
          node.classList.contains('moveable-control-box');

        return ignore;
      },
    })
      .then((canvas) => {
        const croppedCanvas = document.createElement('canvas');
        const croppedCanvasContext = croppedCanvas.getContext('2d');
        // init data
        const cropPositionTop = 0;
        const cropPositionLeft = 0;
        const cropWidth = canvas.width;
        const cropHeight = canvas.height;

        croppedCanvas.width = cropWidth;
        croppedCanvas.height = cropHeight;

        croppedCanvasContext.drawImage(
          canvas,
          cropPositionLeft,
          cropPositionTop,
        );

        return loadImage(croppedCanvas.toDataURL(), { orientation: true })
          .then((data) => {
            const img = data.image.src || data.image.toDataURL();

            // 오류체크용 코드
            // console.log(data.image.src);
            // croppedCanvas.toBlob(function (blob) {
            //   let url = URL.createObjectURL(blob);
            //   console.log(url);
            // });

            return img;
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  };

  return [
    takeScreenShot,
    {
      error,
    },
  ];
};

const createFileName = (extension = '', ...names) => {
  if (!extension) {
    return '';
  }

  return `${names.join('')}.${extension}`;
};

export { useScreenshot, createFileName };
