import React from 'react';
import styled from 'styled-components';

import LoadingOverlay from './LoadingOverlay';

function SaveComplete(props) {
  const {
    pageType,
    setPageType,
    clear,
    language,
    introHandler,
    isLoading,
  } = props;

  const onClickNew = () => {
    clear();
    setPageType('edit');
  };

  const onClickHome = () => {
    introHandler();
  };

  // page 타입별 정보
  const pageInfo = {
    save: {
      completeTitle: {
        ko: '템플릿이 저장완료 되었습니다',
        en: 'Save Completed',
      },
      completeText: {
        ko: '새로운 편집을 진행하고 싶으면 아래 버튼을 눌러주세요',
        en: 'Save Completed',
      },
      btns: [
        {
          btnType: 'default',
          btnText: {
            ko: '새로운 편집하기',
            en: 'Edit New',
          },
          btnColor: 'black',
          btnAction: onClickNew,
        },
        {
          btnType: 'round',
          btnText: {
            ko: '홈으로 돌아가기',
            en: 'Return to Home',
          },
          btnAction: onClickHome,
        },
      ],
    },
  };

  return (
    <SaveCompleteWrap>
      {isLoading && <LoadingOverlay />}
      <SaveCompleteWrapInner>
        <TextGrounp>
          <CompleteTitle>
            {pageInfo[pageType].completeTitle[language]}
          </CompleteTitle>
          <CompleteText>
            {pageInfo[pageType].completeText[language]}
          </CompleteText>
        </TextGrounp>
        <BtnGroup>
          {pageInfo[pageType].btns.map((btn, idx) => {
            const { btnType, btnText, btnColor, btnAction } = btn;

            if (btnType === 'round') {
              return (
                <BtnRound key={idx} onClick={btnAction}>
                  {btnText[language]}
                </BtnRound>
              );
            }

            if (btnType === 'default') {
              return (
                <Btn key={idx} color={btnColor} onClick={btnAction}>
                  {btnText[language]}
                </Btn>
              );
            }
          })}
        </BtnGroup>
      </SaveCompleteWrapInner>
    </SaveCompleteWrap>
  );
}
const SaveCompleteWrap = styled.div`
  position: fixed;
  top: 50px;
  height: calc(100vh - 50px);
  width: 100%;
  padding: 75px 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
`;
const SaveCompleteWrapInner = styled.div`
  width: 100%;
  max-width: 544px;
`;
const TextGrounp = styled.div`
  text-align: left;
`;
const CompleteTitle = styled.p`
  font-size: 15px;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors['black']};
`;
const CompleteText = styled.p`
  line-height: 1.54;
  margin-top: 11px;
  font-size: 13px;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors['very-light-pink2']};
`;
const BtnGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;
const Btn = styled.button`
  width: 100%;
  height: 50px;
  margin: 7px 0;
  padding: 15px 5px;
  background-color: ${({ theme, color }) => theme.colors[color]};
  font-size: 15px;
  font-weight: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.colors['white']};
`;
const BtnRound = styled.button`
  width: 55%;
  height: 50px;
  margin: 7px 0;
  padding: 17px 5px;
  border-radius: 26px;
  border: solid 1px ${({ theme }) => theme.colors['brownish-grey']};
  background-color: ${({ theme }) => theme.colors['white']};
  font-size: 15px;
  font-weight: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.colors['black']};
`;

export default SaveComplete;
