import { useMemo } from 'react';
import { createPortal } from 'react-dom';

function Portal(props) {
  const { children, elementId } = props;

  const rootElement = useMemo(() => document.getElementById(elementId), [
    elementId,
  ]);

  return createPortal(children, rootElement);
}

export default Portal;
