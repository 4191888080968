import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import LoadingOverlay from './LoadingOverlay';

function SaveComplete(props) {
  const history = useHistory();

  const {
    pageType,
    setPageType,
    clear,
    language,
    introHandler,
    downloadImgHandler,
    isLoading,
  } = props;

  const onClickGoCollection = () => {
    history.push({ pathname: '/collection', state: { language } });
  };

  const onClickSaveGallery = () => {
    downloadImgHandler();
  };

  const onClickNew = () => {
    clear();
    setPageType('edit');
  };

  const onClickHome = () => {
    introHandler();
  };

  // page 타입별 정보
  const pageInfo = {
    save: {
      completeTitle: {
        ko: '컬렉션에 저장완료 되었습니다',
        en: 'Saved to Collections',
        vi: 'Đã lưu vào bộ sưu tập hoàn tất',
        ja: 'コレクションに保存しました',
        ch: '完成收藏保存',
        sp: 'La colección se ha guardado'
      },
      completeText: {
        ko:
          '앨범에 저장하거나 새로운 편집을 진행하고 싶다면 아래 버튼을 눌러주세요',
        en: 'Select options below',
        vi: 'Nếu bạn muốn lưu vào album hoặc tiến hành chỉnh sửa mới, vui lòng nhấp vào nút bên dưới',
        ja: 'コレクションに保存したり、新しいものを編集したい場合は、下のボタンを押してください',
        ch: '想要保存到专辑或进行新的编辑时，请点击以下按钮',
        sp: 'Si desea guardar en un álbum o continuar con una nueva edición, haga clic en el botón de abajo'
      },
      btns: [
        {
          btnType: 'default',
          btnText: {
            ko: '컬렉션 보러가기',
            en: 'Go To Collections',
            vi: 'Đi đến bộ sưu tập',
            ja: 'コレクションを見にいく',
            ch: '去看收藏品',
            sp: 'Ir a la colección'
          },
          btnColor: 'pinkish-tan',
          btnAction: onClickGoCollection,
        },
        {
          btnType: 'default',
          btnText: {
            ko: '모바일에 저장하기',
            en: 'Save to Device',
            vi: 'Lưu vào điện thoại di động',
            ja: '携帯に保存する',
            sp: 'Guardar en el móvil'
          },
          btnColor: 'brownish-grey',
          btnAction: onClickSaveGallery,
        },
        {
          btnType: 'default',
          btnText: {
            ko: '새로운 편집하기',
            en: 'Edit New',
            vi: 'Chỉnh sửa mới',
            ja: '新しいものを編集する',
            ch: '保存到手机',
            sp: 'Nueva edición'
          },
          btnColor: 'black',
          btnAction: onClickNew,
        },
        {
          btnType: 'round',
          btnText: {
            ko: '홈으로 돌아가기',
            en: 'Return to Home',
            vi: 'Trở về trang chủ',
            ja: 'ホームに戻る',
            ch: '返回主页',
            sp: 'De vuelta a casa'
          },
          btnAction: onClickHome,
        },
      ],
    },
    saveGalleryComplete: {
      completeTitle: {
        ko: '앨범에 저장완료 되었습니다',
        en: 'It has been saved to the album.',
        vi: 'Quá trình lưu vào album đã hoàn tất',
        ja: 'アルバムに保存しました',
        ch: '已保存到专辑 ',
        sp: 'El álbum se ha guardado'
      },
      completeText: {
        ko: '보유하신 기기 앨범에 성공적으로 저장되었습니다',
        en: 'Your device has been successfully saved to your device.',
        vi: 'Nó đã được lưu thành công vào album thiết bị của bạn',
        ja: 'お持ちの携帯のアルバムに成功的に保存しました',
        ch: '成功储存到机器相册里',
        sp: 'Se ha guardado correctamente en el álbum de su dispositivo'
      },
      btns: [
        {
          btnType: 'round',
          btnText: {
            ko: '홈으로 돌아가기',
            en: 'Return to Home',
            vi: 'Trở về trang chủ',
            ja: 'ホームに戻る',
            ch: '返回主页',
            sp: 'De vuelta a casa'
          },
          btnAction: onClickHome,
        },
      ],
    },
  };

  return (
    <SaveCompleteWrap>
      {isLoading && <LoadingOverlay />}
      <SaveCompleteWrapInner>
        <TextGrounp>
          <CompleteTitle>
            {pageInfo[pageType].completeTitle[language]}
          </CompleteTitle>
          <CompleteText>
            {pageInfo[pageType].completeText[language]}
          </CompleteText>
        </TextGrounp>
        <BtnGroup>
          {pageInfo[pageType].btns.map((btn, idx) => {
            const { btnType, btnText, btnColor, btnAction } = btn;

            if (btnType === 'round') {
              return (
                <BtnRound key={idx} onClick={btnAction}>
                  {btnText[language]}
                </BtnRound>
              );
            }

            if (btnType === 'default') {
              return (
                <Btn key={idx} color={btnColor} onClick={btnAction}>
                  {btnText[language]}
                </Btn>
              );
            }
          })}
        </BtnGroup>
      </SaveCompleteWrapInner>
    </SaveCompleteWrap>
  );
}
const SaveCompleteWrap = styled.div`
  position: fixed;
  top: 50px;
  height: calc(100vh - 50px);
  width: 100%;
  padding: 75px 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
`;
const SaveCompleteWrapInner = styled.div`
  width: 100%;
  max-width: 544px;
`;
const TextGrounp = styled.div`
  text-align: left;
`;
const CompleteTitle = styled.p`
  font-size: 15px;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors['black']};
`;
const CompleteText = styled.p`
  line-height: 1.54;
  margin-top: 11px;
  font-size: 13px;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors['very-light-pink2']};
`;
const BtnGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;
const Btn = styled.button`
  width: 100%;
  height: 50px;
  margin: 7px 0;
  padding: 15px 5px;
  background-color: ${({ theme, color }) => theme.colors[color]};
  font-size: 15px;
  font-weight: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.colors['white']};
`;
const BtnRound = styled.button`
  width: 55%;
  height: 50px;
  margin: 7px 0;
  padding: 17px 5px;
  border-radius: 26px;
  border: solid 1px ${({ theme }) => theme.colors['brownish-grey']};
  background-color: ${({ theme }) => theme.colors['white']};
  font-size: 15px;
  font-weight: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.colors['black']};
`;

export default SaveComplete;
