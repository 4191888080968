import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Alert from '../../../shared/components/Alert/Alert';
import Confirm from '../../../shared/components/Confirm/Confirm';

const PageArea = (props) => {
  const { pageState, chagePage, addPage, removePageData, language } = props;

  const [isShowAlert, setIsShowAlert] = useState(false);
  const [isShowPopup, setIsShowPopup] = useState(false);

  const onClickDel = () => {
    if (pageState.pageData.length <= 1) {
      setIsShowAlert(true);
    } else {
      setIsShowPopup(true);
    }
  };

  const closeAlertHandler = () => {
    setIsShowAlert(false);
  };

  const closePopupHandler = () => {
    setIsShowPopup(false);
  };

  // 페이지 정보 (번역정보)
  const pageInfo = {
    text: {
      delMention: {
        ko: '이 페이지를 삭제하시겠습니까?',
        en: 'Are you sure you want to delete this page?',
      },
      delImposibbleMention: {
        ko: '최소 하나의 페이지는 필요합니다.',
        en: 'At least one page is required.',
      },
    },
    btns: {
      btnAddPage: {
        text: { ko: '페이지 추가하기', en: 'Add Page' },
      },
      btnDelPage: {
        text: { ko: '선택 페이지 삭제', en: 'Delete Page' },
      },
    },
  };

  return (
    <PageAreaWrap>
      {pageState.pageData.map((page, idx) => {
        const { img } = page;

        return (
          <PageImgWrap
            key={idx}
            active={idx === pageState.seletedPage}
            onClick={() => {
              chagePage(idx);
            }}
          >
            <PageImg src={img} />
          </PageImgWrap>
        );
      })}
      <BtnGroup>
        <BtnAdd onClick={addPage}>
          {pageInfo.btns.btnAddPage.text[language]}
        </BtnAdd>
        <BtnDel onClick={onClickDel}>
          {pageInfo.btns.btnDelPage.text[language]}
        </BtnDel>
      </BtnGroup>
      {isShowAlert && (
        <Alert
          message={pageInfo.text.delImposibbleMention[language]}
          onOk={closeAlertHandler}
          onClose={closeAlertHandler}
        />
      )}
      {isShowPopup && (
        <Confirm
          message={pageInfo.text.delMention[language]}
          onClose={closePopupHandler}
          onClickOk={removePageData}
        />
      )}
    </PageAreaWrap>
  );
};

const PageAreaWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 20px;
  color: #fff;
`;

const PageImgWrap = styled.div`
  position: relative;
  margin: 10px;
  :after {
    display: ${({ active }) => (active ? '' : 'none')};
    content: '';
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 3px solid #dd9e84;
  }
`;
const PageImg = styled.img`
  width: 40px;
  height: auto;
  vertical-align: top;
  padding: 3px;
`;
const BtnGroup = styled.div`
  position: absolute;
  left: 50%;
  bottom: 20px;
  display: flex;
  justify-content: center;
  transform: translateX(-50%);
`;
const BtnStyle = css`
  min-width: 110px;
  height: 24px;
  margin: 0 4px;
  padding: 5px 0;
  border-radius: 12px;
  font-size: 13px;
  color: #fff;
`;
const BtnAdd = styled.button`
  ${BtnStyle}
  background-color: ${({ theme }) => theme.colors['pinkish-tan']};
`;
const BtnDel = styled.button`
  ${BtnStyle}
  background-color: ${({ theme }) => theme.colors['brownish-grey']};
`;
export default PageArea;
