import React, { useState } from 'react';
import styled from 'styled-components';

//components
import {
  None,
  Dream,
  Windy,
  Whisper,
  NinteenSeventy,
  Caffeine,
  ClearDay,
  PinkMood,
  Clam,
  Pillow,
  Tangerine,
  Yellow,
  Bright,
  Rainbow,
  Sunflower,
  CoolDown,
  Grape,
  Charming,
  Ash,
  Falling,
  Deep,
} from './components';

function omit(object, keysToOmit) {
  const result = {};

  Object.keys(object).forEach((key) => {
    if (keysToOmit.indexOf(key) === -1) {
      result[key] = object[key];
    }
  });

  return result;
}
function ImageFilter(props) {
  const {
    filter,
    image,
    preserveAspectRatio,
    className,
    svgProps,
    contrast,
    brightness,
    width,
    height,
  } = props;

  const [id, setId] = useState(
    `${new Date().getTime()}${Math.random()}`.replace('.', ''),
  );

  const aspectRatio = {
    none: 'none',
    cover: 'xMidYMid slice',
    contain: 'xMidYMid meet',
  };
  // const renderImage = preserveAspectRatio === 'none';

  const otherProps = omit(props, [
    'image',
    'filter',
    'preserveAspectRatio',
    'className',
    'style',
    'svgStyle',
    'svgProps',
  ]);

  const contrastSlope =
    Math.sign(contrast) < 0
      ? 1 - Math.abs(contrast * 0.01)
      : Math.abs(contrast * 0.008) + 1;
  const contrastIntercept = contrast * 0.008 * -1;

  return (
    <Wrapper {...otherProps} className={`ImageFilter ${className}`}>
      <Img
        alt=""
        aria-hidden={true}
        src={image}
        className="ImageFilter-image"
      />
      <Svg
        {...svgProps}
        className="ImageFilter-svg"
        colorInterpolationFilters="sRGB"
        width={width}
        height={height}
      >
        {filter === 'none' && (
          <None
            id={id}
            brightness={brightness}
            contrastSlope={contrastSlope}
            contrastIntercept={contrastIntercept}
          />
        )}
        {filter === 'dream' && (
          <Dream
            id={id}
            brightness={brightness}
            contrastSlope={contrastSlope}
            contrastIntercept={contrastIntercept}
          />
        )}
        {filter === 'windy' && (
          <Windy
            id={id}
            brightness={brightness}
            contrastSlope={contrastSlope}
            contrastIntercept={contrastIntercept}
          />
        )}
        {filter === 'whisper' && (
          <Whisper
            id={id}
            brightness={brightness}
            contrastSlope={contrastSlope}
            contrastIntercept={contrastIntercept}
          />
        )}
        {filter === '1970' && (
          <NinteenSeventy
            id={id}
            brightness={brightness}
            contrastSlope={contrastSlope}
            contrastIntercept={contrastIntercept}
          />
        )}
        {filter === 'caffeine' && (
          <Caffeine
            id={id}
            brightness={brightness}
            contrastSlope={contrastSlope}
            contrastIntercept={contrastIntercept}
          />
        )}
        {filter === 'clearday' && (
          <ClearDay
            id={id}
            brightness={brightness}
            contrastSlope={contrastSlope}
            contrastIntercept={contrastIntercept}
          />
        )}
        {filter === 'pinkmood' && (
          <PinkMood
            id={id}
            brightness={brightness}
            contrastSlope={contrastSlope}
            contrastIntercept={contrastIntercept}
          />
        )}
        {filter === 'clam' && (
          <Clam
            id={id}
            brightness={brightness}
            contrastSlope={contrastSlope}
            contrastIntercept={contrastIntercept}
          />
        )}
        {filter === 'pillow' && (
          <Pillow
            id={id}
            brightness={brightness}
            contrastSlope={contrastSlope}
            contrastIntercept={contrastIntercept}
          />
        )}
        {filter === 'tangerine' && (
          <Tangerine
            id={id}
            brightness={brightness}
            contrastSlope={contrastSlope}
            contrastIntercept={contrastIntercept}
          />
        )}
        {filter === 'yellow' && (
          <Yellow
            id={id}
            brightness={brightness}
            contrastSlope={contrastSlope}
            contrastIntercept={contrastIntercept}
          />
        )}
        {filter === 'bright' && (
          <Bright
            id={id}
            brightness={brightness}
            contrastSlope={contrastSlope}
            contrastIntercept={contrastIntercept}
          />
        )}
        {filter === 'rainbow' && (
          <Rainbow
            id={id}
            brightness={brightness}
            contrastSlope={contrastSlope}
            contrastIntercept={contrastIntercept}
          />
        )}
        {filter === 'sunflower' && (
          <Sunflower
            id={id}
            brightness={brightness}
            contrastSlope={contrastSlope}
            contrastIntercept={contrastIntercept}
          />
        )}
        {filter === 'cooldown' && (
          <CoolDown
            id={id}
            brightness={brightness}
            contrastSlope={contrastSlope}
            contrastIntercept={contrastIntercept}
          />
        )}
        {filter === 'grape' && (
          <Grape
            id={id}
            brightness={brightness}
            contrastSlope={contrastSlope}
            contrastIntercept={contrastIntercept}
          />
        )}
        {filter === 'charming' && (
          <Charming
            id={id}
            brightness={brightness}
            contrastSlope={contrastSlope}
            contrastIntercept={contrastIntercept}
          />
        )}
        {filter === 'ash' && (
          <Ash
            id={id}
            brightness={brightness}
            contrastSlope={contrastSlope}
            contrastIntercept={contrastIntercept}
          />
        )}
        {filter === 'falling' && (
          <Falling
            id={id}
            brightness={brightness}
            contrastSlope={contrastSlope}
            contrastIntercept={contrastIntercept}
          />
        )}
        {filter === 'deep' && (
          <Deep
            id={id}
            brightness={brightness}
            contrastSlope={contrastSlope}
            contrastIntercept={contrastIntercept}
          />
        )}
        <image
          filter={`url(#filter-image-${id})`}
          preserveAspectRatio={aspectRatio[preserveAspectRatio]}
          xlinkHref={image}
          x="0"
          y="0"
          width="100%"
          height="100%"
          className={'editmode-off-none'}
        />
      </Svg>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Img = styled.img`
  display: block;
  visibility: hidden;
  width: 100%;
`;

const Svg = styled.svg`
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export default ImageFilter;

ImageFilter.defaultProps = {
  filter: 'none',
  preserveAspectRatio: 'none',
  className: '',
  style: {},
  svgStyle: {},
  svgProps: {},
  contrast: 0,
  brightness: 0,
};
