// 이미지 생성 및 다운로드
const downloadImg = async (
  image,
  createFileName,
  { name = 'img', extension = 'png' } = {},
  isIOS,
) => {
  if (window.Capacitor) {
    const { Capacitor } = window;
    const { Plugins } = Capacitor;
    const a = await Plugins.Filesystem.writeFile({
      data: image,
      path: name + '.' + extension,
      directory: 'CACHE',
    });
    const data = { path: a.uri, album: 'wecume' };

    if (Capacitor.getPlatform() === 'ios') {
      delete data.album;
    }

    await Plugins.MediaPlugin.savePhoto(data);
  } else {
    const a = document.createElement('a');
    a.href = image;

    if (!isIOS) {
      a.download = createFileName(extension, name);
    }

    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
  }
};

export default downloadImg;
