import React from 'react';
import styled from 'styled-components';

// components
import Portal from '../Portal/Portal';

function SelectConfirm({ message, selectData, onClickOk, onClose, language }) {
  return (
    <Portal elementId="selectConfirm-root">
      <ConfirmWrap>
        <BackDrop onClick={onClose} />
        <PopupDialog>
          <Message>
            {message.split('\n').map((line, idx) => {
              return (
                <span key={idx}>
                  {line}
                  <br />
                </span>
              );
            })}
          </Message>
          {selectData.map((select) => {
            const { title, key, data, selectedValue, onChangeFn } = select;
            return (
              <FormGroup key={key}>
                <FormHeader>{title}</FormHeader>
                <FormBody>
                  <SelectBox
                    name={key}
                    select={selectedValue}
                    onChange={(evt) => {
                      onChangeFn(evt.target.value);
                    }}
                  >
                    {data.map((val) => (
                      <option value={val} key={val}>
                        {val}
                      </option>
                    ))}
                  </SelectBox>
                </FormBody>
              </FormGroup>
            );
          })}

          <BtnGroup>
            <Btn bgcolor={'#6f6f6f'} onClick={onClose}>
              취소
            </Btn>
            <Btn bgcolor={'#e89b7f'} onClick={onClickOk}>
              완료
            </Btn>
          </BtnGroup>
        </PopupDialog>
      </ConfirmWrap>
    </Portal>
  );
}

const ConfirmWrap = styled.div`
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 320px;
  z-index: 20000;
  text-align: center;
`;

const PopupDialog = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 27px 20px;
  background-color: ${({ theme }) => theme.colors['black']};
  border-radius: 14px;
`;

const Message = styled.p`
  font-size: 15px;
  font-weight: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.colors['white']};
`;

const FormGroup = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  margin-top: 15px;
`;
const FormHeader = styled.h3`
  width: 65px;
  font-size: 14px;
  color: #fff;
  text-align: left;
`;
const FormBody = styled.div`
  flex: 1 0 auto;
  height: 100%;
`;
const SelectBox = styled.select`
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  font-size: 14px;
  color: #333;
`;
const BtnGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`;

const Btn = styled.button`
  width: 68px;
  margin: 0 5px;
  padding: 6px 0;
  background-color: ${({ bgcolor }) => bgcolor};
  border-radius: 12px;
  text-align: center;
  font-size: 12px;
  color: ${({ theme }) => theme.colors['white']};
`;

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;

export default SelectConfirm;
