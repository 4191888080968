import React from 'react';

// components
import BrightnessAndContrast from './BrightnessAndContrast';

const Deep = ({ id, brightness, contrastSlope, contrastIntercept }) => {
  return (
    <defs>
      {/* <!-- INSTAGRAM INKWELL FILTER --> */}
      <filter id={`filter-image-${id}`} colorInterpolationFilters="sRGB">
        {/* <!-- sepia 0.3 --> */}
        <feColorMatrix
          type="matrix"
          values="
                        0.8179 0.2307 0.0567 0 0
                        0.1047 0.9058 0.0504 0 0
                        0.0816 0.1602 0.7393 0 0
                        0 0 0 1 0"
        />
        {/* <!-- contrast --> */}
        <feComponentTransfer>
          <feFuncR type="linear" slope="1.1" intercept="-0.05" />
          <feFuncG type="linear" slope="1.1" intercept="-0.05" />
          <feFuncB type="linear" slope="1.1" intercept="-0.05" />
        </feComponentTransfer>
        {/* <!-- brightness --> */}
        <feComponentTransfer>
          <feFuncR type="linear" slope="1.1" />
          <feFuncG type="linear" slope="1.1" />
          <feFuncB type="linear" slope="1.1" />
        </feComponentTransfer>
        {/* <!-- grayscale 100% --> */}
        <feColorMatrix type="saturate" values="0" />
        <BrightnessAndContrast
          brightness={brightness}
          contrastSlope={contrastSlope}
          contrastIntercept={contrastIntercept}
        />
      </filter>
    </defs>
  );
};

export default Deep;
