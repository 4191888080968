import React from 'react';

// components
import BrightnessAndContrast from './BrightnessAndContrast';

const Clam = ({ id, brightness, contrastSlope, contrastIntercept }) => {
  return (
    <defs>
      {/* <!-- INSTAGRAM SLUMBER FILTER --> */}
      <filter
        id={`filter-image-${id}`}
        filterUnits="objectBoundingBox"
        primitiveUnits="objectBoundingBox"
        x="0"
        y="0"
        width="100%"
        height="100%"
      >
        {/* <!-- saturate --> */}
        <feColorMatrix in="SourceGraphic" type="saturate" values="0.66" />
        {/* <!-- brightness --> */}
        <feComponentTransfer result="sb">
          <feFuncR type="linear" slope="1.05" />
          <feFuncG type="linear" slope="1.05" />
          <feFuncB type="linear" slope="1.05" />
        </feComponentTransfer>
        <feFlood floodColor="rgba(69, 41, 12, 0.4)" floodOpacity="1" />
        <feBlend mode="lighten" in="sb" result="sbl" />
        <feFlood floodColor="rgba(125, 105, 24, 0.5)" floodOpacity="1" />
        <feBlend mode="soft-light" in2="sbl" />
        <BrightnessAndContrast
          brightness={brightness}
          contrastSlope={contrastSlope}
          contrastIntercept={contrastIntercept}
        />
      </filter>
    </defs>
  );
};

export default Clam;
