import React, { useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';

// utills
import { getFileImgInfo } from '../../../shared/utills/getFileImgInfo';

const PhotoArea = (props) => {
  const { setCurrentTabDefault, addHistoryStateHandler, elIdx } = props;
  const inputFileRef = useRef(null);
  const prefix = 'photo';

  //language 설정
  let mention = '사진 추가하기';
  if (props.language === 'en') {
    mention = 'Add Photo';
  }
  if (props.language === 'vi') {
    mention = 'Thêm ảnh';
  }
  if (props.language === 'ja') {
    mention = '写真を追加';
  }
  if (props.language === 'ch') {
    mention = '添加照片';
  }
  if (props.language === 'sp') {
    mention = 'Agregar una foto';
  }

  const onClickAddPhoto = () => {
    inputFileRef.current.click();
  };

  const addImageEl = (evt) => {
    getFileImgInfo(evt).then((imgLoad) => {
      imgLoad().then((imgInfo) => {
        const { src, width, height } = imgInfo;

        // 이미지 요소 추가
        addHistoryStateHandler({
          id: `${moment().format('YYYY-DD-MM-HH-mm-ss')}_${elIdx.current}`,
          type: prefix,
          elInfo: {
            src,
            width,
            height,
            reversals: { vertical: false, horizontal: false },
            filter: 'none',
            brightness: 0,
            contrast: 0,
          },
          transformInfo: {
            translate: [0, 0],
            rotate: 0,
            scale: [1, 1],
          },
          keepRatio: true,
          isEditable: true,
          canModify: true,
        });

        // index 중가
        elIdx.current += 1;

        // 하단 컨트롤 영역 닫기
        setCurrentTabDefault();
      });
    });
  };

  return (
    <PhotoAreaWrap>
      <BtnAddPhoto onClick={onClickAddPhoto}>
        <BtnIcon src="./img/icon-picplus.svg" />
        <InputPhoto
          type="file"
          accept="image/*"
          ref={inputFileRef}
          onChange={addImageEl}
        />
        <BtnText>{mention}</BtnText>
      </BtnAddPhoto>
    </PhotoAreaWrap>
  );
};

const PhotoAreaWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
const BtnAddPhoto = styled.button`
  flex-direction: column;
`;
const InputPhoto = styled.input`
  display: none;
`;
const BtnIcon = styled.img``;
const BtnText = styled.p`
  font-size: 15px;
  color: ${({ theme }) => theme.colors['white']};
`;

export default PhotoArea;
