import React, { useEffect, useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import get from 'lodash/get';
import find from 'lodash/find';

// components
import Moveable from 'react-moveable';
import ImgFilter from '../../Edit/components/ImgFilter/ImageFilter';

// constants
import { photoFilterData } from '../../../shared/constants/photoFilterData';

const customAble = {
  name: 'customBtns',
  render(moveable) {
    const { renderPoses } = moveable.state;
    const {
      id,
      canModify,
      onClickBtnModify,
      onClickBtnDelete,
      type,
    } = moveable.props;

    const modifyHandler = (evt) => {
      onClickBtnModify(id, type);
    };

    const deleteItemHandler = () => {
      moveable.destroy();
      onClickBtnDelete(id);
    };

    return (
      <React.Fragment key={id}>
        {canModify && (
          <BtnTargetModify
            renderPoses={renderPoses}
            onTouchStart={modifyHandler}
            onClick={modifyHandler}
          >
            <BtnIcon src="./img/icon-edit-circle.svg" className="btn-edit" />
          </BtnTargetModify>
        )}
        <BtnTargetRemove
          renderPoses={renderPoses}
          onTouchStart={deleteItemHandler}
          onClick={deleteItemHandler}
        >
          <BtnIcon src="./img/icon-delete-circle.svg" />
        </BtnTargetRemove>
      </React.Fragment>
    );
  },
};

const MoveableEl = (props) => {
  const {
    item,
    setEditableHandler,
    modifyMoveableDataHandler,
    deleteMoveableDataHandler,
    addHistoryStateHandler,
    photoState,
    photoEditTab,
    changeCropPhoto,
  } = props;
  const {
    id,
    type,
    elInfo: {
      text,
      textAlign,
      fontColor,
      fontFamily,
      fontSize,
      src,
      width,
      height,
      reversals,
      filter,
      brightness,
      contrast,
    },
    transformInfo: { translate, rotate, scale },
    isEditable: isEdit,
    canModify,
  } = item;

  const {
    id: photoId,
    reversals: reversalsEditState,
    filter: filterEditState,
    brightness: brightnessEditState,
    contrast: contrastEditState,
  } = photoState;

  // 이미지 설정 데이터
  const reversalsInfo = reversalsEditState || reversals;
  const filterInfo = filterEditState || filter;
  const brightnessInfo = brightnessEditState || brightness;
  const contrastInfo = contrastEditState || contrast;
  const filterData = find(photoFilterData, ['name', filterInfo]);

  // crop 데이터 및 함수
  const initCropState = { unit: '%', width: 30, height: 30 };
  const [crop, setCrop] = useState(initCropState);
  const onPhotoCrop = (cropData) => {
    setCrop(cropData);
  };
  const photoRef = useRef(null);
  const onImgLoad = useCallback((img) => {
    photoRef.current = img;
  }, []);
  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement('canvas');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = 4;

    canvas.width = Math.ceil(crop.width * pixelRatio);
    canvas.height = Math.ceil(crop.height * pixelRatio);

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return {
      width: crop.width,
      height: crop.height,
      src: canvas.toDataURL('image/png', 1),
    };
  };
  const onCropComplete = (cropData) => {
    changeCropPhoto(getCroppedImg(photoRef.current, cropData, 'test'));
  };
  useEffect(() => {
    setCrop(initCropState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  // moveAble 데이터
  const moveAbleRef = useRef();
  const [frame, setFrame] = useState({
    translate: [0, 0],
    scale: [1, 1],
    rotate: 0,
  });

  const [keepRatio, setKeepRatio] = useState(false);

  // 클릭 시 편집 활성화
  const onClickTarget = useCallback(() => {
    setEditableHandler(item.id);
    moveAbleRef.current.updateRect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.id]);

  // moveable target 셋팅
  const [target, setTarget] = useState();
  const targetClass = `target_${id}`;
  useEffect(() => {
    setTarget(document.querySelector(`.target_${id}`));
  }, [id]);

  // 초기 위치 셋팅
  const setInitTransform = () => {
    frame.translate = translate;
    frame.rotate = rotate;
    frame.scale = scale;

    moveAbleRef.current.props.target.style.transform =
      `translate(${translate[0]}px, ${translate[1]}px)` +
      `rotate(${rotate}deg)` +
      `scale(${scale[0]}, ${scale[1]})`;

    moveAbleRef.current.updateRect();
  };

  useEffect(() => {
    if (target) {
      setInitTransform();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, rotate, scale, translate, text, src]);

  // moveable요소 변경사항 history에 반영
  const changeTransform = (evt) => {
    const newItem = { ...item, transformInfo: { ...frame } };

    if (!evt.inputEvent.target.classList.contains('btn-edit')) {
      addHistoryStateHandler(newItem);
    }
  };

  return (
    <React.Fragment>
      <Target className={targetClass} onClick={onClickTarget}>
        {type === 'photo' && (
          <ImgElWrap filterData={filterData} width={width} height={height}>
            {photoEditTab === 'crop' && id === photoId && (
              <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={onImgLoad}
                onChange={onPhotoCrop}
                onComplete={onCropComplete}
              />
            )}
            {(photoEditTab !== 'crop' || id !== photoId) && (
              <ImgWrap reversalsInfo={reversalsInfo}>
                <ImgFilter
                  image={src}
                  filter={filterData.name}
                  contrast={contrastInfo}
                  brightness={brightnessInfo}
                  preserveAspectRatio={'contain'}
                  svgProps={{ viewBox: `0 0 ${width} ${height}` }}
                  width={width}
                  height={height}
                />
              </ImgWrap>
            )}
          </ImgElWrap>
        )}
        {type === 'text' && (
          <TextEl
            textAlign={textAlign}
            fontColor={fontColor}
            fontFamily={fontFamily}
            fontSize={fontSize}
            className={'editmode-off-none'}
          >
            {text}
          </TextEl>
        )}
        {type === 'sticker' && (
          <ImgEl
            src={src}
            alt=""
            width={width}
            height={height}
            className={'editmode-off-none'}
          />
        )}
      </Target>
      <MoveableWrap
        className={'editmode-off-none'}
        ref={moveAbleRef}
        isEdit={isEdit}
        canModify={canModify}
        target={target}
        draggable={isEdit}
        throttleDrag={2}
        rotatable={isEdit}
        throttleRotate={5}
        rotationPosition="top"
        scalable={isEdit}
        throttleScale={0}
        keepRatio={keepRatio}
        snappable={isEdit}
        snapThreshold={10}
        verticalGuidelines={[100, 200, 300]}
        horizontalGuidelines={[0, 100, 200]}
        ables={[customAble]}
        customBtns={isEdit}
        id={id}
        onClickBtnDelete={deleteMoveableDataHandler}
        onClickBtnModify={modifyMoveableDataHandler}
        type={type}
        onDragStart={({ set }) => {
          set(frame.scale);
          set(frame.translate);
        }}
        onRotateStart={({ set, dragStart }) => {
          set(frame.rotate);
          dragStart && dragStart.set(frame.translate);
        }}
        onScaleStart={({ set, dragStart, direction }) => {
          direction.includes(0) ? setKeepRatio(false) : setKeepRatio(true);
          set(frame.scale);
          dragStart && dragStart.set(frame.translate);
        }}
        onDrag={({ target, beforeTranslate }) => {
          frame.translate = beforeTranslate;

          target.style.transform =
            `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)` +
            `rotate(${frame.rotate}deg)` +
            `scale(${frame.scale[0]}, ${frame.scale[1]})`;
        }}
        onRotate={({ target, beforeRotate, drag }) => {
          frame.rotate = beforeRotate;
          frame.translate = drag.beforeTranslate;

          target.style.transform =
            `translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px)` +
            `rotate(${beforeRotate}deg)` +
            `scale(${frame.scale[0]}, ${frame.scale[1]})`;
        }}
        onScale={({ target, scale, drag }) => {
          frame.scale = scale;
          frame.translate = drag.beforeTranslate;

          target.style.transform =
            `translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px)` +
            `rotate(${frame.rotate}deg)` +
            `scale(${scale[0]}, ${scale[1]})`;
        }}
        onDragEnd={changeTransform}
        onRotateEnd={changeTransform}
        onScaleEnd={changeTransform}
      />
    </React.Fragment>
  );
};

const Target = styled.div`
  position: absolute;
  top: 0;
`;

const MoveableWrap = styled(Moveable)`
  display: ${({ isEdit }) => (isEdit ? 'block' : 'none!important')};
`;

const ImgElWrap = styled.div`
  position: relative;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  :after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
  }
`;

const ImgWrap = styled.div`
  width: 100%;
  height: 100%;
  transform: ${({ reversalsInfo }) => {
    let transformData = '';

    if (get(reversalsInfo, 'vertical')) {
      transformData = 'scaleX(-1)';
    }
    if (get(reversalsInfo, 'horizontal')) {
      transformData = transformData + 'scaleY(-1)';
    }

    return transformData;
  }};
`;

const ImgEl = styled.img`
  max-width: 100%;
  width: 100%;
  height: 100%;
  vertical-align: top;
`;

const TextEl = styled.pre`
  color: ${({ fontColor }) => fontColor};
  text-align: ${({ textAlign }) => textAlign};
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: 1.5;
  word-break: break-all;
`;

const BtnTargetRemove = styled.button`
  position: absolute;
  width: 24px;
  height: 24px;
  padding: 0;
  z-index: 100;
  transform: translate(-50%, -50%)
    ${({ renderPoses }) =>
      `translate(${renderPoses[1][0]}px, ${renderPoses[1][1]}px)`};
`;
const BtnTargetModify = styled.button`
  position: absolute;
  width: 24px;
  height: 24px;
  padding: 0;
  z-index: 100;
  transform: translate(-50%, -50%)
    ${({ renderPoses }) =>
      `translate(${renderPoses[0][0]}px, ${renderPoses[0][1]}px)`};
`;
const BtnIcon = styled.img``;

export default MoveableEl;
