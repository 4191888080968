import { useReducer, useCallback } from 'react';

// constants
import { bgInitState, bgRatio } from '../constants/bgData';

// utills
import getRatioPadding from '../utills/getRatioPadding';

const initState = bgInitState;

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_BG_INFO': {
      const { bgObj } = action;
      const { color, ratio, src } = bgObj;

      return {
        editedColor: color,
        editedRatio: ratio,
        editedSrc: src,
        color,
        ratio,
        src,
      };
    }

    case 'CHANGE_COLOR':
      const { newColor } = action;

      return { ...state, color: newColor };

    case 'SET_RATIO_INFO':
      const { width, height } = action;
      const getRatioObj = (width, height) => {
        const obj = {};

        bgRatio.forEach(({ name }) => {
          obj[name] = getRatioPadding(name, width, height);
        });

        return obj;
      };
      const ratioInfo = {
        width,
        height,
        ...getRatioObj(width, height),
      };

      return { ...state, ratio: { ...state.ratio, ...ratioInfo } };
    case 'CHANGE_BG_RATIO':
      const { newRatio } = action;

      return { ...state, ratio: { ...state.ratio, seletedRatio: newRatio } };
    case 'CHANGE_BG_PHOTO':
      const { newSrc } = action;

      return { ...state, src: newSrc };
    case 'EDIT_END':
      const { color, ratio, src } = action;

      return {
        color,
        ratio,
        src,
        editedColor: color,
        editedRatio: ratio,
        editedSrc: src,
      };
    case 'EDIT_CANCEL':
      return {
        ...state,
        color: state.editedColor,
        ratio: state.editedRatio,
        src: state.editedSrc,
      };
    case 'INIT_BG':
      return {
        ...initState,
      };
    default:
      return console.log('정의되지 않은 타입입니다.');
  }
};

const useBackground = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const setBgInfo = useCallback((bgObj) => {
    dispatch({ type: 'SET_BG_INFO', bgObj });
  }, []);

  const changeColor = useCallback((newColor) => {
    dispatch({ type: 'CHANGE_COLOR', newColor });
  }, []);

  const setRatioInfo = useCallback((width, height) => {
    dispatch({ type: 'SET_RATIO_INFO', width, height });
  }, []);

  const changeRatio = useCallback((newRatio) => {
    dispatch({ type: 'CHANGE_BG_RATIO', newRatio });
  }, []);

  const changeBgPhoto = useCallback((newSrc) => {
    dispatch({ type: 'CHANGE_BG_PHOTO', newSrc });
  }, []);

  const editBgEnd = useCallback(({ color, ratio, src }) => {
    dispatch({ type: 'EDIT_END', color, ratio, src });
  }, []);

  const editBgCancel = useCallback(() => {
    dispatch({ type: 'EDIT_CANCEL' });
  }, []);

  const initBg = useCallback(() => {
    dispatch({ type: 'INIT_BG' });
  }, []);

  return [
    state,
    {
      setBgInfo,
      changeColor,
      setRatioInfo,
      changeRatio,
      changeBgPhoto,
      editBgEnd,
      editBgCancel,
      initBg,
    },
  ];
};

export default useBackground;
