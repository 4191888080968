import React from 'react';

// components
import BrightnessAndContrast from './BrightnessAndContrast';

const Caffeine = ({ id, brightness, contrastSlope, contrastIntercept }) => {
  return (
    <defs>
      {/* <!-- INSTAGRAM TOASTER FILTER --> */}
      <radialGradient id="gradient_toaster">
        <stop offset="100%" stopColor="#804e0f" stopOpacity="100%" />
        <stop offset="0%" stopColor="#3b003b" stopOpacity="100%" />
      </radialGradient>

      {/* <!-- Create a rectangle and apply the gradient as its fill --> */}
      <rect
        id="toastersc"
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill="url(#gradient_toaster)"
        fillOpacity=".3"
      />

      <radialGradient id="gradient_toaster">
        <stop offset="100%" stopColor="#804e0f" stopOpacity="100%" />
        <stop offset="100%" stopColor="#3b003b" stopOpacity="100%" />
      </radialGradient>
      {/* <!-- Create a rectangle and apply the gradient as its fill --> */}
      <rect
        id="toastersc"
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill="url(#gradient_toaster)"
        fillOpacity=".3"
      />
      <filter id={`filter-image-${id}`} colorInterpolationFilters="sRGB">
        {/* <!-- contrast --> */}
        <feComponentTransfer>
          <feFuncR type="linear" slope="1.5" intercept="-0.25" />
          <feFuncG type="linear" slope="1.5" intercept="-0.25" />
          <feFuncB type="linear" slope="1.5" intercept="-0.25" />
        </feComponentTransfer>
        {/* <!-- brightness --> */}
        <feComponentTransfer result="cb">
          <feFuncR type="linear" slope=".9" />
          <feFuncG type="linear" slope=".9" />
          <feFuncB type="linear" slope=".9" />
        </feComponentTransfer>
        {/* <!-- blend mode --> */}
        <feImage xlinkHref="#toastersc" result="grad" x="0" y="0" />
        <feBlend mode="screen" in="cb" />
        <BrightnessAndContrast
          brightness={brightness}
          contrastSlope={contrastSlope}
          contrastIntercept={contrastIntercept}
        />
      </filter>
    </defs>
  );
};

export default Caffeine;
