import React from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

// components
import MoveableEl from './MoveableEl';
import EditIntro from './EditIntro';
import LoadingOverlay from './LoadingOverlay';

function Content(props) {
  const {
    pageType,
    isLoading,
    contentAreaRef,
    captureAreaRef,
    moveableData,
    setEditableHandler,
    modifyMoveableDataHandler,
    deleteHistoryStateHandler,
    addHistoryStateHandler,
    bgEditState,
    photoState,
    templateState,
    isIntro,
    language,
    photoEditTab,
    changeCropPhoto,
    contentDisabled,
  } = props;

  const seletedRatio = get(bgEditState, ['ratio', 'seletedRatio']);
  const paddings = get(bgEditState, ['ratio', seletedRatio]);
  const { captureInnerW, captureInnerH, captureRatio } = templateState;

  return (
    <ContentWrap
      ref={contentAreaRef}
      pY={get(paddings, 'pY')}
      pX={get(paddings, 'pX')}
      disabled={contentDisabled}
      className="content-wrap"
    >
      <CaptureWrap
        ref={captureAreaRef}
        bgColor={get(bgEditState, 'color')}
        bgImage={get(bgEditState, 'src')}
        className="capture-wrap"
      >
        <CaptureWrapInner
          width={captureInnerW}
          height={captureInnerH}
          ratio={captureRatio}
        >
          {isLoading && <LoadingOverlay />}
          {pageType === 'edit' && (
            <React.Fragment>
              {isIntro && <EditIntro language={language} />}
              {!isEmpty(moveableData) &&
                moveableData.map((item) => {
                  const { id } = item;
                  const photoEditInfo = photoState.id === id ? photoState : {};

                  return (
                    <MoveableEl
                      key={id}
                      item={item}
                      setEditableHandler={setEditableHandler}
                      modifyMoveableDataHandler={modifyMoveableDataHandler}
                      deleteMoveableDataHandler={deleteHistoryStateHandler}
                      addHistoryStateHandler={addHistoryStateHandler}
                      photoState={photoEditInfo}
                      photoEditTab={photoEditTab}
                      changeCropPhoto={changeCropPhoto}
                    />
                  );
                })}
            </React.Fragment>
          )}
        </CaptureWrapInner>
      </CaptureWrap>
    </ContentWrap>
  );
}

const ContentWrap = styled.div`
  position: fixed;
  top: calc(50px + constant(safe-area-inset-top));
  top: calc(50px + env(safe-area-inset-top));
  height: calc(100% - 115px - constant(safe-area-inset-top));
  height: calc(
    100% - 115px - env(safe-area-inset-top) - env(safe-area-inset-bottom)
  );
  width: 100%;
  padding: ${({ pY, pX }) => `${pY}px ${pX}px`};
  background-color: rgba(106, 106, 106, 0.43);
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
`;

const CaptureWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${({ bgColor }) => bgColor};
  background-image: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : '')};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
`;

const CaptureWrapInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  transform: ${({ ratio }) =>
    `translate(calc(-50% * ${ratio}), calc(-50% * ${ratio})) scale(${ratio})`};
  transform-origin: 0 0;
`;

export default Content;
