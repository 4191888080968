import React from 'react';
import styled, { css } from 'styled-components';

// omponents
import Confirm from '../../../shared/components/Confirm/Confirm';

// hooks
import usePopup from '../../../shared/hooks/usePopup';

function SubHeader(props) {
  const {
    undo,
    redo,
    clear,
    canUndo,
    canRedo,
    isEditOpen,
    onClickSave,
    isIntro,
    onClickBtnHome,
  } = props;

  //language 설정
  let mention0 = '저장하지 않고 \n 다시 새로 시작하시겠습니까?';
  if (props.language === 'en') {
    mention0 = 'Do you want to start over \n without saving?';
  }

  // 팝업 state
  const [isShowPopup, setIsShowPopup] = usePopup();

  const btnResetClickHandler = () => {
    setIsShowPopup(true);
  };
  const closePopupHandler = () => {
    setIsShowPopup(false);
  };

  return (
    <SubHeaderWrap className="subHeaderWrap">
      {isShowPopup && (
        <Confirm
          message={mention0}
          onClose={closePopupHandler}
          onClickOk={clear}
          language={props.language}
        />
      )}
      <BtnHome type="button" disabled={isEditOpen} onClick={onClickBtnHome}>
        <BtnIcon
          src={
            isEditOpen
              ? './img/home-24-px_disabled.svg'
              : './img/home-24-px.svg'
          }
        />
      </BtnHome>
      <BtnWrapCenter>
        <BtnUndo type="button" disabled={isEditOpen || !canUndo} onClick={undo}>
          <BtnIcon
            src={
              isEditOpen || !canUndo
                ? './img/icon-undo_disabled.svg'
                : './img/icon-undo.svg'
            }
          />
        </BtnUndo>
        <BtnRedo type="button" disabled={isEditOpen || !canRedo} onClick={redo}>
          <BtnIcon
            src={
              isEditOpen || !canRedo
                ? './img/icon-do_disabled.svg'
                : './img/icon-do.svg'
            }
          />
        </BtnRedo>
      </BtnWrapCenter>
      <BtnWrap>
        <BtnReset
          type="button"
          onClick={btnResetClickHandler}
          disabled={isEditOpen || isIntro}
        >
          <BtnIcon
            src={
              isEditOpen || isIntro
                ? './img/iconX_disabled.svg'
                : './img/iconX.svg'
            }
          />
        </BtnReset>
        <BtnSave type="button" disabled={isEditOpen} onClick={onClickSave}>
          <BtnIcon
            src={
              isEditOpen || isIntro
                ? './img/icon-download_disabled.svg'
                : './img/icon-download.svg'
            }
          />
        </BtnSave>
      </BtnWrap>
    </SubHeaderWrap>
  );
}

const SubHeaderWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 15px;
  background: ${({ theme, isEditOpen }) =>
    isEditOpen ? '#eee' : theme.colors['black']};
  > * {
    height: 100%;
  }
  z-index: 15000;
`;
const BtnWrapCenter = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  > button {
    height: 100%;
  }
`;
const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  > button {
    height: 100%;
  }
`;
const BtnStyle = css`
  padding: 0;
`;
const BtnIcon = styled.img``;
const BtnHome = styled.button`
  ${BtnStyle}
`;
const BtnUndo = styled.button`
  ${BtnStyle}
`;
const BtnRedo = styled.button`
  ${BtnStyle}
`;
const BtnReset = styled.button``;
const BtnSave = styled.button`
  ${BtnStyle}
  flex-direction: column;
`;

export default SubHeader;
