import React from 'react';

// components
import BrightnessAndContrast from './BrightnessAndContrast';

const Bright = ({ id, brightness, contrastSlope, contrastIntercept }) => {
  return (
    <defs>
      {/* <!-- CSSgram Instagram Skyline filter --> */}
      <filter
        id={`filter-image-${id}`}
        colorInterpolationFilters="sRGB"
        filterUnits="objectBoundingBox"
        primitiveUnits="objectBoundingBox"
        x="0"
        y="0"
        width="100%"
        height="100%"
      >
        {/* <!-- contrast --> */}
        <feComponentTransfer>
          <feFuncR type="linear" slope="1.1" intercept="-0.05" />
          <feFuncG type="linear" slope="1.1" intercept="-0.05" />
          <feFuncB type="linear" slope="1.1" intercept="-0.05" />
        </feComponentTransfer>
        {/* <!-- brightness --> */}
        <feComponentTransfer result="cb">
          <feFuncR type="linear" slope="1.2" />
          <feFuncG type="linear" slope="1.2" />
          <feFuncB type="linear" slope="1.2" />
        </feComponentTransfer>
        <feFlood floodColor="rgba(93, 101, 186, 0.2)" floodOpacity="1" />
        <feBlend mode="overlay" in2="cb" />
        <BrightnessAndContrast
          brightness={brightness}
          contrastSlope={contrastSlope}
          contrastIntercept={contrastIntercept}
        />
      </filter>
    </defs>
  );
};

export default Bright;
