import React, { useEffect, useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styles from './Sample.module.css';
import axios from 'axios';
import { URL } from '../../shared/constants/apiInfo';

import PageLayout from '../../shared/components/PageLayout/PageLayout';

function Sample(props) {
  const [templeteData, setTempleteData] = useState([]);

  let history = useHistory();

  const language = props.location.state.language;
  const genre = props.location.state.genre;

  let mention1;
  if (language === 'ko') {
    if (genre === 'photoBook') {
      mention1 = '포토북';
    } else if (genre === 'congrat') {
      mention1 = '축하카드';
    } else if (genre === 'invitation') {
      mention1 = '초대장';
    } else if (genre === 'leaflet') {
      mention1 = '전단지';
    } else if (genre === 'poster') {
      mention1 = '포스터';
    }
  } else if (language === 'en') {
    if (genre === 'photoBook') {
      mention1 = 'Photo Book';
    } else if (genre === 'congrat') {
      mention1 = 'Greeting Card';
    } else if (genre === 'invitation') {
      mention1 = 'Invitation Card';
    } else if (genre === 'leaflet') {
      mention1 = 'Leaflet';
    } else if (genre === 'poster') {
      mention1 = 'Poster';
    }
  } else if (language === 'vi') {
    if (genre === 'photoBook') {
      mention1 = 'Photo Book';
    } else if (genre === 'congrat') {
      mention1 = 'Thiệp chúc mừng';
    } else if (genre === 'invitation') {
      mention1 = 'Thư mời';
    } else if (genre === 'leaflet') {
      mention1 = 'Tờ rơi';
    } else if (genre === 'poster') {
      mention1 = 'Poster';
    }
  } else if (language === 'ja') {
    if (genre === 'photoBook') {
      mention1 = 'フォトブック';
    } else if (genre === 'congrat') {
      mention1 = 'お祝いカード';
    } else if (genre === 'invitation') {
      mention1 = '招待状';
    } else if (genre === 'leaflet') {
      mention1 = 'チラシ';
    } else if (genre === 'poster') {
      mention1 = 'ポスター';
    }
  } else if (language === 'ch') {
    if (genre === 'photoBook') {
      mention1 = '写真集';
    } else if (genre === 'congrat') {
      mention1 = '贺卡';
    } else if (genre === 'invitation') {
      mention1 = '邀请';
    } else if (genre === 'leaflet') {
      mention1 = '传单';
    } else if (genre === 'poster') {
      mention1 = '海报';
    }
  } else if (language === 'sp') {
    if (genre === 'photoBook') {
      mention1 = 'Un libro de fotos';
    } else if (genre === 'congrat') {
      mention1 = 'Una tarjeta de felicitación';
    } else if (genre === 'invitation') {
      mention1 = 'Una invitación';
    } else if (genre === 'leaflet') {
      mention1 = 'Un folleto';
    } else if (genre === 'poster') {
      mention1 = 'Un póster';
    }
  }

  function GoHome() {
    if (language === 'ko') {
      history.push('/ko');
    } else if (language === 'en') {
      history.push('/en');
    } else if (language === 'vi') {
      history.push('/vi');
    } else if (language === 'ja') {
      history.push('/ja');
    } else if (language === 'ch') {
      history.push('/ch');
    } else if (language === 'sp') {
      history.push('/sp');
    }
  }

  const fetchList = useCallback(
    (category) => {
      const url = `${URL}/sendFile`;

      axios
        .get(url, { params: { category, lang: language } })
        .then(function (response) {
          let data = [];

          response.data.forEach(({ number, imgData }) => {
            data.push({ number, imgData });
          });
          setTempleteData(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    [language],
  );

  useEffect(() => {
    fetchList(genre);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout iosFullScroll={true}>
      <div>
        <div className={styles.title}>
          <img
            src="./img/back.png"
            alt=""
            className={styles.back}
            onClick={() => history.goBack()}
          />
          <img
            src="./img/home2.png"
            alt=""
            className={styles.home}
            onClick={GoHome}
          />
          {mention1}
        </div>
        <div className={styles.horizontal_slider}>
          <div className={styles.slider_container}>
            {templeteData.map((template, idx) => {
              const { number, imgData } = template;

              return (
                <Link
                  to={{
                    pathname: '/example_detail',
                    state: {
                      number,
                      img: imgData,
                      language: language,
                    },
                  }}
                  key={idx}
                >
                  <img src={imgData} alt="" className={styles.item} />
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default Sample;
