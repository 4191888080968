const caculatStackData = (
  stackData,
  stackCanvasInfo,
  captureSize,
  bgEditState,
  changeCapureInner,
) => {
  const {
    width: templeteCanvasWidth,
    height: templeteCanvasHeight,
  } = stackCanvasInfo;
  const {
    width: currentCanvasWidth,
    height: currentCanvasHeight,
  } = bgEditState.ratio;
  const elRatio = {
    width: currentCanvasWidth / templeteCanvasWidth,
    height: currentCanvasHeight / templeteCanvasHeight,
  };

  let canvasRatio =
    elRatio.width > elRatio.height ? elRatio.height : elRatio.width;
  canvasRatio = canvasRatio > 1 ? 1 : canvasRatio;

  const { width: captureWidth, height: captureHeight } = captureSize;

  // 그리기전에 캔버스 사이즈에 맞게 데이터 변경
  const caculatedStackData = stackData.map((stack) => {
    // 1. 배경
    if (stack.type === 'background') {
      stack.elInfo.ratio = {
        ...bgEditState.ratio,
        seletedRatio: stack.elInfo.ratio.seletedRatio,
      };
    }

    return stack;
  });

  changeCapureInner(`${captureWidth}px`, `${captureHeight}px`, canvasRatio);

  return { caculatedStackData };
};

export default caculatStackData;
