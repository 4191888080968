import React from 'react';
import { Reset } from 'styled-reset';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import Edit from './components/Edit/Edit';
import AdminEdit from './components/Admin/AdminEdit';
import List from './components/Example/List';
import Sample from './components/Example/Sample';
import ExampleDetail from './components/Example/ExampleDetail';
import IntroEn from './components/Intro/IntroEn';
import IntroJa from './components/Intro/IntroJa';
import IntroVi from './components/Intro/IntroVi';
import IntroCh from './components/Intro/IntroCh';
import IntroSp from './components/Intro/IntroSp';
import Collection from './components/Collection/Collection';
import CollectionDetail from './components/Collection/CollectionDetail';

// theme
import theme from './shared/styles/theme';
import IntroKo from './components/Intro/IntroKo';
import Signup from './components/Start/Signup';

function App() {
  const lang =
    navigator.appName === 'Netscape'
      ? navigator.language
      : navigator.userLanguage;
  if (lang.substring(0, 2) !== 'ko' && window.location.pathname === '/') {
    window.location = '/en';
  }

  return (
    <RootWrap>
      <Reset />
      <ThemeProvider theme={theme}>
        <Router>
          <Route path="/" exact component={IntroKo} />
          <Route path="/en" exact component={IntroEn} />
          <Route path="/ko" exact component={IntroKo} />
          <Route path="/ja" exact component={IntroJa} />
          <Route path="/vi" exact component={IntroVi} />
          <Route path="/ch" exact component={IntroCh} />
          <Route path="/sp" exact component={IntroSp} />
          <Route path="/signup" exact component={Signup} />
          <Route path="/edit" exact component={Edit} />
          <Route path="/list" exact component={List} />
          <Route path="/sample" exact component={Sample} />
          <Route path="/example_detail" exact component={ExampleDetail} />
          <Route path="/collection" exact component={Collection} />
          <Route
            path="/collection_detail/:name"
            exact
            component={CollectionDetail}
          />
          <Route path="/admin" exact component={AdminEdit} />
        </Router>
      </ThemeProvider>
    </RootWrap>
  );
}

const RootWrap = styled.div`
  margin-top: constant(safe-area-inset-top); // for ios 11.1
  margin-top: env(safe-area-inset-top); // for ios 11.2 and onwards
  /* margin-bottom: env(safe-area-inset-bottom); // for ios 11.2 and onwards */
  height: 100%;
`;

export default App;
