import React from 'react';

// components
import BrightnessAndContrast from './BrightnessAndContrast';

const Dream = ({ id, brightness, contrastSlope, contrastIntercept }) => {
  return (
    <defs>
      {/* <!-- INSTAGRAM Lark FILTER --> */}
      <filter id={`filter-image-${id}`}>
        {/* <!-- contrast --> */}
        <feComponentTransfer result="contrast">
          <feFuncR type="linear" slope="0.9" intercept="0.05" />
          <feFuncG type="linear" slope="0.9" intercept="0.05" />
          <feFuncB type="linear" slope="0.9" intercept="0.05" />
        </feComponentTransfer>
        <feFlood floodColor="rgba(242, 242, 242, 0.8)" floodOpacity="1" />
        <feBlend mode="darken" in="contrast" />
        <feFlood floodColor="#22253f" floodOpacity="1" />
        <feBlend mode="color-dodge" in2="SourceGraphic" />
        <BrightnessAndContrast
          brightness={brightness}
          contrastSlope={contrastSlope}
          contrastIntercept={contrastIntercept}
        />
      </filter>
    </defs>
  );
};

export default Dream;
