import React from 'react';
import styled from 'styled-components';

function PageLayout({ children, iosFullScroll }) {
  return <PageWrap iosFullScroll={iosFullScroll}>{children}</PageWrap>;
}

const PageWrap = styled.div`
  height: ${({ iosFullScroll }) =>
    iosFullScroll ? 'calc(100% + constant(safe-area-inset-top))' : '100%'};
  height: ${({ iosFullScroll }) =>
    iosFullScroll ? 'calc(100% + env(safe-area-inset-top))' : '100%'};
`;

export default PageLayout;
