import React from 'react';

// components
import BrightnessAndContrast from './BrightnessAndContrast';

const Tangerine = ({ id, brightness, contrastSlope, contrastIntercept }) => {
  return (
    <defs>
      {/* <!-- INSTAGRAM HEFE FILTER --> */}
      <filter
        id={`filter-image-${id}`}
        colorInterpolationFilters="sRGB"
        filterUnits="objectBoundingBox"
        primitiveUnits="objectBoundingBox"
        x="0"
        y="0"
        width="100%"
        height="100%"
      >
        {/* <!-- contrast --> */}
        <feComponentTransfer>
          <feFuncR type="linear" slope="1.3" intercept="-0.15" />
          <feFuncG type="linear" slope="1.3" intercept="-0.15" />
          <feFuncB type="linear" slope="1.3" intercept="-0.15" />
        </feComponentTransfer>
        {/* <!-- sepia 0.3 --> */}
        <feColorMatrix
          type="matrix"
          values="
                0.8179 0.2307 0.0567 0 0
                0.1047 0.9058 0.0504 0 0
                0.0816 0.1602 0.7393 0 0
                0 0 0 1 0"
        />
        {/* <!-- saturate --> */}
        <feColorMatrix type="saturate" values="1.3" />
        {/* <!--hue-rotate --> */}
        <feColorMatrix type="hueRotate" values="-10" />
        {/* <!-- brightness --> */}
        <feComponentTransfer result="csshb">
          <feFuncR type="linear" slope="0.95" />
          <feFuncG type="linear" slope="0.95" />
          <feFuncB type="linear" slope="0.95" />
        </feComponentTransfer>
        <feFlood floodColor="rgba(243, 106, 188, 0)" floodOpacity="1" />
        <feBlend mode="normal" in2="csshb" />
        <BrightnessAndContrast
          brightness={brightness}
          contrastSlope={contrastSlope}
          contrastIntercept={contrastIntercept}
        />
      </filter>
    </defs>
  );
};

export default Tangerine;
