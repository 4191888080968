import React from 'react';
import styled from 'styled-components';

// components
import Portal from '../Portal/Portal';

function Alert({ message, onOk, onClose, language }) {
  //language 설정
  let mention1 = '확인';
  if (language === 'en') {
    mention1 = 'OK';
  }
  if (language === 'vi') {
    mention1 = 'đồng ý';
  }
  if (language === 'ja') {
    mention1 = 'OK';
  }
  if (language === 'ch') {
    mention1 = 'OK';
  }
  if (language === 'sp') {
    mention1 = 'OK';
  }
  return (
    <Portal elementId="alert-root">
      <AlertWrap>
        <BackDrop onClick={onClose} />
        <PopupDialog>
          <Message>
            {message.split('\n').map((line, idx) => {
              return (
                <span key={idx}>
                  {line}
                  <br />
                </span>
              );
            })}
          </Message>
          <BtnGroup>
            <Btn bgcolor={'#6f6f6f'} onClick={onOk}>
              {mention1}
            </Btn>
          </BtnGroup>
        </PopupDialog>
      </AlertWrap>
    </Portal>
  );
}

const AlertWrap = styled.div`
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 320px;
  z-index: 20000;
`;

const PopupDialog = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 277px;
  padding: 41px 5px;
  background-color: ${({ theme }) => theme.colors['black']};
  border-radius: 14px;
`;

const Message = styled.p`
  font-size: 15px;
  font-weight: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.colors['white']};
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const Btn = styled.button`
  width: 68px;
  margin: 0 5px;
  padding: 6px 0;
  background-color: ${({ bgcolor }) => bgcolor};
  border-radius: 12px;
  text-align: center;
  font-size: 12px;
  color: ${({ theme }) => theme.colors['white']};
`;

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;

export default Alert;
