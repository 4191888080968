import { createGlobalStyle } from 'styled-components';

const ScrollNoneGlobalStyle = createGlobalStyle`
  html,
  body {
    position: fixed;
  }
  body {
    overflow: hidden;
  }
`;

export default ScrollNoneGlobalStyle;
