import React from 'react';

// components
import BrightnessAndContrast from './BrightnessAndContrast';

const Sunflower = ({ id, brightness, contrastSlope, contrastIntercept }) => {
  return (
    <defs>
      {/* <!-- pixelfed Instagram Ashby filter --> */}
      <filter
        id={`filter-image-${id}`}
        colorInterpolationFilters="sRGB"
        filterUnits="objectBoundingBox"
        primitiveUnits="objectBoundingBox"
        x="0"
        y="0"
        width="100%"
        height="100%"
      >
        {/* <!-- sepia 0.5 --> */}
        <feColorMatrix
          type="matrix"
          values="
                0.6965 0.3845 0.0945 0 0
                0.1745 0.843 0.084 0 0
                0.136 0.267 0.5655 0 0
                0 0 0 1 0"
        />
        {/* <!-- contrast 1.2 --> */}
        <feComponentTransfer>
          <feFuncR type="linear" slope="1.2" intercept="-0.1" />
          <feFuncG type="linear" slope="1.2" intercept="-0.1" />
          <feFuncB type="linear" slope="1.2" intercept="-0.1" />
        </feComponentTransfer>
        {/* <!-- saturate 1.8 --> */}
        <feColorMatrix type="saturate" values="1.8" result="scs" />
        <feFlood floodColor="rgba(125,105,24,1)" floodOpacity=".35" />
        <feBlend mode="lighten" in="scs" />
        <BrightnessAndContrast
          brightness={brightness}
          contrastSlope={contrastSlope}
          contrastIntercept={contrastIntercept}
        />
      </filter>
    </defs>
  );
};

export default Sunflower;
