import React from 'react';
import styled from 'styled-components';

// components
import { Scrollbars } from 'react-custom-scrollbars';

function RatioLists(props) {
  const { ratios, selectedRatio, onClickRatio, language } = props;

  return (
    <FilterWrap>
      <Scrollbars
        autoHide={true}
        autoHideTimeout={1000}
        renderView={(props) => (
          <div {...props} style={{ ...props.style, overflowY: 'hidden' }} />
        )}
        renderThumbHorizontal={(props) => (
          <div
            {...props}
            style={{
              ...props.style,
              backgroundColor: 'rgba(255, 255, 255, .2)',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          />
        )}
      >
        <ListItems>
          {ratios.map((ratio) => {
            const { name, img } = ratio;
            const imgName = img[language] ? img[language] : img['en'];

            return (
              <BtnItem key={name} onClick={onClickRatio} data-ratio={name}>
                <BtnImg
                  src={
                    selectedRatio === name
                      ? `img/${imgName}_active.svg`
                      : `img/${imgName}.svg`
                  }
                />
              </BtnItem>
            );
          })}
        </ListItems>
      </Scrollbars>
    </FilterWrap>
  );
}

const FilterWrap = styled.div`
  position: relative;
  flex: 1 1 0;
  z-index: 1;
  height: 85px;
  margin-right: -30px;
`;
const ListItems = styled.div`
  display: inline-flex;
  align-items: flex-end;
  cursor: pointer;
  margin-right: 17px;
`;
const BtnItem = styled.button`
  display: block;
  padding: 0;
  margin-right: 25px;
`;
const BtnImg = styled.img``;

export default RatioLists;
